import React, { useEffect, useState } from "react";
import Map, { Marker, FullscreenControl, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pin from "./Pin";
import moment from "moment";
import httpRequest from "../../HttpService";
import endIcon from "./images/pin_purple_end.png";
import startIcon from "./images/pin_start.png";
import driverIcon from "./images/pin_driver.png";
import signIcon from "./images/pin_signature_lean-left.png";
import cameraIcon from "./images/pin_photo_lean-right_100X80.png";

const MapboxPanel = (props) => {
  // console.log("props.orderID is" + props.orderID);

  const [shPosition, setShPosition] = useState(null);
  const [cPosition, setCPosition] = useState(null);
  const [drPosition, setDrPosition] = useState(null);
  const [zoom, setZoom] = useState(14);
  const [photoList, setPhotoList] = useState([]);
  const [signPinInfo, setSignPinInfo] = useState("");

 

  useEffect(() => {
    if (props.orderID) {
      //console.log("GPSs-" + JSON.stringify(props.gpsData));
      if (Object.keys(props.gpsData).length > 0) {
        //console.log("Get GPS-" + JSON.stringify(response.data));
        setShPosition({ lat: props.gpsData.slat, lng: props.gpsData.slong });
        setCPosition({ lat: props.gpsData.clat, lng: props.gpsData.clong });
        var dist = distance(
          props.gpsData.slat,
          props.gpsData.slong,
          props.gpsData.clat,
          props.gpsData.clong
        );
        console.log("distance-" + dist);
        if (dist < 1) setZoom(15);
        else if (dist < 2) setZoom(14);
        else if (dist < 10) setZoom(11);
        else if (dist < 50) setZoom(10);
        else setZoom(9);
        if (props.gpsData.DriverLat !== null) {
          setDrPosition({
            lat: props.gpsData.DriverLat,
            lng: props.gpsData.DriverLong,
          });
        } else setDrPosition(null);       
      }
      var signTimeStr = props.dtDelivered
        ? props.dtDelivered.substring(0, 19).replace("T", " ")
        : "";
      var displayMessage =
        "Signature lat: " +
        props.delLat +
        ", lon: " +
        props.delLong +
        "; DateTime: " +
        signTimeStr;
      setSignPinInfo(displayMessage);
    }
  }, [props.gpsData]);

  useEffect(() => {
    let photos = [];
    console.log("props orderstatus-" + props.orderStatus);
    if(props.delLat !== null && props.delLong !== null){
      console.log("props lat-" + props.delLat);
      console.log("props long-" + props.delLong);
    }
    console.log("photos-" + JSON.stringify(props.images));
    props.images.forEach((img) => {
      if (img.imgType === 2) {
        //var photoTime = new Date(moment(img.savedTimeUTC).toDate() - new Date().getTimezoneOffset() * 60000);  //UTC -- Locatime display
        //var photoTime = new Date(moment(img.savedTimeUTC).toDate());  //UTC -- Locatime display
        //var photoTimeStr =  photoTime.getFullYear() + "-" + (photoTime.getMonth()+1) + "-" +  photoTime.getDate() + " " + photoTime.getHours() + ":" +photoTime.getMinutes();
        var photoTimeStr = moment(img.savedTimeUTC).format("YY-MM-DD HH:mm");
        var displayMessage = "Photo ";
        if(props.orderStatus > 8 && props.delLat !== null && props.delLong!== null && img.Lat === props.delLat.toString() && img.Long=== props.delLong.toString())
          displayMessage = "Signature and Photo ";
        displayMessage = displayMessage + " lat:" +
          img.Lat +
          ", lon: " +
          img.Long +
          "; DateTime: " +
          photoTimeStr +
          "; " +
          img.comment;
        photos.push({
          ...img,
          displayText: displayMessage,
        });
      }
    });
    setPhotoList(photos);
  }, [props.images]);

  function distance(lat1, lon1, lat2, lon2) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;

      dist = dist * 1.609344;
      return dist;
    }
  }

  return cPosition !== null ? (
    <Map
      initialViewState={{
        longitude: cPosition.lng,
        latitude: cPosition.lat,
        pitch: 85,
        zoom: 10,
      }}
      style={{ width: "100%", height: 560 }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      //mapStyle ="mapbox://styles/mapbox/satellite-streets-v11"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
    >
      <Marker longitude={shPosition.lng} latitude={shPosition.lat}>
        <Pin pin_icon={startIcon} displayText="" variant="start" />
      </Marker>

      <Marker longitude={cPosition.lng} latitude={cPosition.lat}>
        <Pin pin_icon={endIcon} displayText="" variant="end" />
      </Marker>

      {drPosition != null && (
        <Marker longitude={drPosition.lng} latitude={drPosition.lat}>
          <Pin pin_icon={driverIcon} displayText="" variant="driver" />
        </Marker>
      )}

      {props.orderStatus > 8 && props.delLat && props.delLong && (
        <Marker longitude={props.delLong} latitude={props.delLat}>
          <Pin pin_icon={signIcon} displayText={signPinInfo} variant="sign" />
        </Marker>
      )}

      {photoList.map(
        (item) =>
          item.imgType === 2 &&
          item.Long &&
          item.Lat && (
            <Marker
              longitude={parseFloat(item.Long)}
              latitude={parseFloat(item.Lat)}
            >
              <Pin
                pin_icon={cameraIcon}
                displayText={item.displayText}
                variant="camera"
              />
            </Marker>
          )
      )}
      <FullscreenControl />
    </Map>
  ) : (
    <></>
  );
};
export default MapboxPanel;

import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';


const AddressTab = (props) => {

    useEffect(() => {
        console.log('AddressTab',props.data.data);

    },[])


    const renderFromAddress=() =>{
        let suite = "";
        if(props.data.data.sSuite){
            suite = props.data.data.sSuite+", ";
        }
        return suite + props.data.data.sAddress + " " + props.data.data.sQuad
                + ", " + props.data.data.sCity+", " + props.data.data.sProv + ", " + props.data.data.sPostal
                +", "+ props.data.data.sCountry;
    }

    const renderToAddress =() =>{
        let suite = "";
        if(props.data.data.cSuite){
            suite = props.data.data.cSuite+", ";
        }
        return suite+ props.data.data.cAddress + " " + props.data.data.cQuad
            + ", " + props.data.data.cCity + ", " + props.data.data.cProv+ ", " + props.data.data.cPostal
            + ", " + props.data.data.cCountry;
    }


    return (
        <Grid container>
            <Grid item md={6}>
                <Typography style={{ fontWeight: 500 }}> Shipper </Typography> 
                <Typography style={{ fontWeight: 420 }}>{props.data.data.sCompanyName}</Typography>  
                <Typography>{renderFromAddress()}</Typography>                                                                      
            </Grid>
            <Grid item md={6}>
                <Typography style={{ fontWeight: 500 }}> Consignee </Typography> 
                <Typography style={{ fontWeight: 420 }}>{props.data.data.cCompanyName}</Typography> 
                <Typography>{renderToAddress()}</Typography>
            </Grid>
        </Grid>
    );
};

export default AddressTab;
import React, { useState, useEffect } from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Button, Tooltip, Box } from "@material-ui/core";
// import LoginIcon from '@material-ui/icons/AccountCircle';
import LoginIcon from "@mui/icons-material/Login";
import TextInput from "../components/inputFields/TextInput";
import "./Login.css";
import httpRequest from "../HttpService";
import { withRouter } from "react-router";
import "animate.css/animate.css";
import { Alert, AlertTitle } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Copyright from "../Copyright";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNumber: "",
      password: "",
      accessToken: "",
      openDialog: false,
      inputCode: "",
      loginFailed: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin = (event) => {
    console.log("handleLogin -" + JSON.stringify(this.state));
    let user = {loginName: this.state.accountNumber, password: this.state.password,};
    httpRequest.in_login(user).then((body) => {
        try {
          console.log("in_login return-" + JSON.stringify(body));
          if (body.data.ResultCode === 0) {
            this.setState({ openDialog: true });
            this.setState({ loginFailed: false });
          } else if (body.data.ResultCode === 1) {
            let loginUser = body.data.user;
            loginUser.loginName = this.state.accountNumbe;
            loginUser.account = "9910";
            localStorage.setItem("loginUser", JSON.stringify(loginUser));
            localStorage.setItem("access_token", loginUser.accessToken);
            ///const loginUser = JSON.parse(localStorage.getItem('loginUser'));
            //$rootScope.$broadcast('user', data);
            this.getDefaultWebToken(loginUser.accessToken);
            this.setState({ loginFailed: false });

            httpRequest.getUserSettings(loginUser.accessToken, "000").then(async function (body) {
                try {
                  console.log("retrieved UserSettings is " + JSON.stringify(body.data[0].settingsJson));
                  localStorage.setItem("searchParameterFromServer",JSON.stringify(body.data[0].settingsJson));
                } catch (e) {
                  console.log("retrieved UserSettings exception: " + e);
                }
              });
          } else if (body.data.ResultCode === -1) {
            // $rootScope.alertMessage = "Invaild Login! Please check your password!";
            this.setState({ loginFailed: true });
          }
        } catch (e) {
          console.log("in_login exception:" + e);
        }
      })
      .catch((body) => {
        console.log("error", body);
        this.setState({ loginFailed: true });
      });
  };

  handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleConfirm = (event) => {
    this.setState({ openDialog: false });
    let user = {
      loginName: this.state.accountNumber,
      password: this.state.password,
      verifyCode: this.state.inputCode,
    };

    httpRequest.in_login(user).then((body) => {
      console.log("in_login return-" + JSON.stringify(body));
      try {
        if (body.data.ResultCode === 0) {
          this.setState({ openDialog: true });
        } else {
          let loginUser = body.data.user;
          loginUser.loginName = this.state.accountNumbe;
          loginUser.account = "9910";
          localStorage.setItem("loginUser", JSON.stringify(loginUser));
          localStorage.setItem("access_token", loginUser.accessToken);
          this.getDefaultWebToken(loginUser.accessToken);

          httpRequest.getUserSettings(loginUser.accessToken, "000").then(async function (body) {
              try {
                console.log("retrieved UserSettings is " + JSON.stringify(body.data[0].settingsJson));
                localStorage.setItem("searchParameterFromServer",JSON.stringify(body.data[0].settingsJson));
              } catch (e) {
                console.log("retrieved UserSettings exception: " + e);
              }
            });
        }
      } catch (e) {
        console.log("getAccountWebToken exception:" + e);
      }
    });
  };

  getDefaultWebToken = (accessToken) => {
    localStorage.setItem("companyID", "1");
    httpRequest .getAccountWebToken(accessToken, "9910").then(async function (body) {
        try {
          console.log("getAccountWebToken-" + JSON.stringify(body.data));
          localStorage.setItem("currentAccount", "9910");

          let webURL = body.data[0].webAddress;
          webURL = webURL.replace("ship", "webx");
          localStorage.setItem("web5URL", webURL);
          window.location.href = "/web5";
        } catch (e) {
          console.log("getAccountWebToken exception:" + e);
        }
      });
  };

  render() {
    return (
      <Container class="Login">
        <div class={"login-wrapper"}>
          <div className={"login-wrapper-inner"}>
            <Grid  container  direction="column" justifyContent="center"  alignItems="center"   spacing={2}>
              <Grid item md={12}>
                <h1 className="loginMainTitle">InX</h1>
                <h2 className="loginSubTitle">Client Login</h2>
              </Grid>
              <Grid item md={12} style={{ width: "100%" }}>
                <TextInput  class={"labeledField"}  label="Client Account"  type="text"   inputType="text"  name="accountNumber"  labelSize={5} value={this.state.accountNumber} onChange={this.handleChange}/>
              </Grid>
              <Grid item md={12} style={{ width: "100%" }}>
                <TextInput  class={"labeledField"}  label="Password"  type="password"  inputType="password"  name="password" labelSize={5}  value={this.state.password} onChange={this.handleChange}/>
              </Grid>
              <Grid  item md={12} style={{ width: "100%", textAlign: "center" }}>
                <Button  style={{width: "100%", display: "flex", justifyContent: "space-between",}}  variant="contained"  color="primary"
                  endIcon={
                    <LoginIcon
                      style={{ verticalAlign: "middle", transform: "scale(1)" }}
                    />
                  }
                  onClick={this.handleLogin}
                >
                  Login
                </Button>
              </Grid>
              {this.state.loginFailed && (
              <Grid item md={12} style={{ width: "100%" }}>
                  <Alert severity="warning">
                    <AlertTitle>Invalid Login</AlertTitle>
                    Please check your account name and password.
                  </Alert>
              </Grid>
              )}
            </Grid>
          </div>
        </div>
        <Dialog
          className="darkDialog" 
          open={this.state.openDialog}
          onClose={this.handleChangehandleClose}
        >
          <DialogTitle>Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please Input Verification Code:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="code"
              label="Verification Code"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(input) => {
                this.setState({ inputCode: input.target.value });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button style={{width:'100%'}} onClick={this.handleConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
        {/* Footer */}
        <footer style={{ float: "center", marginTop: "20px" }}>
          <Copyright />
        </footer>
        {/* End footer */}
      </Container>
    );
  }
}

//export default Login;
export default withRouter(Login);

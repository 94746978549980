import { PropaneSharp } from "@mui/icons-material";
import * as React from "react";
//import Popover from "@mui/material/Popover";
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@material-ui/core/IconButton";
import { palette } from "../../palette";

function Pin({ pin_icon, displayText, variant }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    //if(anchorEl != null) setAnchorEl(null);
    if (displayText !== "") {
      setAnchorEl(event.currentTarget);      
    }
  };

  const handlePopoverClose = (e) => {
    if (displayText !== "") setAnchorEl(null);
    e.preventDefault();
  };

  const open = Boolean(anchorEl);

  return (
    // <div style={{display: "flex", justifyContent: "center"}}  onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
    //<div  style={{ display: "flex", justifyContent: "center" }} onMouseEnter={handlePopoverOpen}>
    <div  style={{ display: "flex", justifyContent: "center" }} >
      {/* <img src={pin_icon} onClick={handlePopoverOpen} style={{ width: "60px", height: "auto", marginRight:variant === "sign"?"5px":"0", marginLeft:variant === "camera"?"10px":"0" , transform: variant === "sign"? "rotateZ(-30deg)" : variant === "camera"? "rotateZ(30deg)" : "rotateZ(0deg)"}} />  */}
      <img src={pin_icon} style={{ width: "60px", height: "auto" }} onClick={handlePopoverOpen}/>
      {displayText !== "" && (
        <Popover
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}      
          style={{ position: "absolute" }}
        >     
          <div
              style={{
                width: "340px",
                height: "60px",
              }}
          >             
            <IconButton onClick={() =>{setAnchorEl(null)}} style={{ color: palette.primary.dark,float:'right'}}> <CloseIcon/></IconButton>    
            <p>{displayText}</p>
          </div>          
        </Popover>
      )}
    </div>
  );
}

export default React.memo(Pin);

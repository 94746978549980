import React, { useEffect, useState }from 'react'

import Map, { Marker, FullscreenControl,Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ExceptionPin from './ExceptionPin';
import DriverPin from './DriverPin';
import TripPin from './TripPin';
import NoticeEmitter from "../../NoticeUtil";

/*
TODO: 
1. Hover over driven icon displays driver contact info at top
2. Double click driver icon displays driver messages
3. if router's url contains driver id, then display only the driver
*/


const AllMapPanel = props => {
    const [mapPoints, setMapPoints] = useState(props.mapPoints);
    const [selectedDriver, setSelectedDriver] = useState("000");
    const [exceptionMapPoints, setExceptionMapPoints] = useState([]);
    const [driverMapPoints, setDriverMapPoints] = useState([]);
    const [tripMapPoints, setTripMapPoints] = useState([]);
    const [pickTripMapPoints, setPickTripMapPoints] = useState([]);
    const [deliveryTripMapPoints, setDeliveryTripMapPoints] = useState([]);
    const [tripsList, setTripsList] = useState(props.tripList);

    const [showRelatedTripPIN, setShowRelatedTripPIN] = useState(false);
    const [relatedTripPIN, setRelatedTripPIN] = useState(null);
    const [relatedOrgTripPIN, setRelatedOrgTripPIN] = useState(null);
    const [showOrgRelatedPIN, setShowOrgRelatedTripPIN] = useState(false);
    const [relatedTripPINColor, setRelatedTripPINColor] = useState('');

    const mapRef = React.useRef();

    const onMapLoad = React.useCallback(() => {
        // mapRef.addSource("mapbox-dem", {
        //     type: "raster-dem",
        //     url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        //     tileSize: 512,
        //     maxZoom: 16,
        //   })
        //   mapRef.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 })
        //   mapRef.addLayer({
        //     id: "sky",
        //     type: "sky",
        //     paint: {
        //       "sky-type": "atmosphere",
        //       "sky-atmosphere-sun": [0.0, 90.0],
        //       "sky-atmosphere-sun-intensity": 15,
        //     },
        //   });     
    }, []);


    useEffect(() => {

        //console.log("useEffect**********AllMapPanel mapPoints length is " + props.mapPoints.length);
        const drvPointsList=[];
        const tripPointsList=[];
        const exceptionPointsList=[];
        const deliveryTripPointsList=[];
        const pickTripPointsList=[];

        setMapPoints(props.mapPoints);

        props.mapPoints.forEach((value) => {
            if(value.mapImage==="Car"){
                if(value.exception!== null && value.exception === true)
                    exceptionPointsList.push(value);
                else
                    drvPointsList.push(value);
            }
            else{
                if(props.tripList.length>0){
                    var trip = props.tripList.find(function( obj ) { return obj.jobID === value.jobID; });
                    if(trip !== undefined){
                        tripPointsList.push(value);
                        if(value.mapLabel.toUpperCase() === "D" || value.mapLabel.toUpperCase() === "DD" || value.mapLabel.toUpperCase() === "SD" || value.mapLabel.toUpperCase() === "RD" || value.mapLabel.toUpperCase() === "DN")
                           deliveryTripPointsList.push(value);
                        else
                           pickTripPointsList.push(value);
                    }
                }
            }
        });   
        //console.log("AllMapPanel pickTripPointsList length is " + pickTripPointsList.length);
        //console.log("AllMapPanel deliveryTripPointsList length is " + deliveryTripPointsList.length);

        setDriverMapPoints(drvPointsList);
        setTripMapPoints(tripPointsList);
        setExceptionMapPoints(exceptionPointsList);
        setPickTripMapPoints(pickTripPointsList);
        setDeliveryTripMapPoints(deliveryTripPointsList);

        // var relatedPINStr = localStorage.getItem('relatedPIN');
        // if(relatedPINStr!== ''){            
        //     var pin = JSON.parse(relatedPINStr);
        //     setRelatedTripPIN(pin);
        // }

    },[props.mapPoints,props.tripList,relatedTripPIN,showRelatedTripPIN,showOrgRelatedPIN]);


    function handleIconClick(drNum, json){
        setSelectedDriver(drNum);
        json = JSON.parse(json);
        var msg = "";
        if(drNum !== null){
            msg = "Driver #: " + drNum + " | Phone: " + json[0].ContactPhone
            + " | Email: " + json[0].contactEmail + " | GeoMins: " + json[0].GeoMinutes
            + " | Refused: " + json[0].refused + " | Burns: " + json[0].burns
            + " | Trip Count: " + json[0].tripCount + " | Trips: " + json[0].trips;
        }
       //console.log(msg);
        props.handlerDriverPinClick(drNum);
    }

    function handleTripClick(drNum, jobID, json){
        console.log("handleTripClick" + jobID);
        //window.location.href='/jobDetail/' + jobID; 
        let inxURL ="https://inx.directx.ca/jobdetail/"+jobID;
        window.open(inxURL, "_blank");  
    }

    
    
    function handleMouseOver(pin){
        //console.log("handleMouseOver" + JSON.stringify(pin));

        if(relatedTripPIN !== null) return;
        var target;
        switch(pin.mapLabel.toUpperCase()) {
                case "D":
                    target = "P";
                    break;
                case "DD":
                    target = "PP";
                    break;
                case "P":
                    target = "D";
                    break;
                case "PP":
                default:
                    target = "DD";
                    break;
        }
        mapPoints.map(item => {
            if(item.jobID===pin.jobID && item.mapLabel.toUpperCase() === target){

               setRelatedTripPINColor(item.mapColor);
               setRelatedOrgTripPIN(item);

               item.mapColor = 'light green';
               setRelatedTripPIN(item);
               localStorage.setItem('relatedPIN', JSON.stringify(item));

               setShowRelatedTripPIN(true);
               setShowOrgRelatedTripPIN(false);

              
               let cnt =0;
               let timerId = setInterval(() => {                  
                    cnt++;
                    if(cnt == 1){       
                       //console.log("=====World!=======11111111111");                
                       setShowOrgRelatedTripPIN(false);
                       setShowRelatedTripPIN(false);
                       NoticeEmitter.emit('freshAllDriverMap', ''); 
                    }
                    if(cnt == 2){
                        //console.log("=====World!=======222222222222--" + JSON.stringify(item));
                        //console.log("=====World!=======222222222222--" + JSON.stringify(relatedTripPIN));
                        //setRelatedTripPIN(item);
                        setShowRelatedTripPIN(true);
                        setShowOrgRelatedTripPIN(false);   
                     }
                    if(cnt == 3){
                        //console.log("=====World!=======3333333333");
                        clearInterval(timerId);
                        setRelatedTripPIN(null);
                        setRelatedOrgTripPIN(null);
                        setShowOrgRelatedTripPIN(false);
                        setShowRelatedTripPIN(false);
                        //localStorage.setItem('relatedPIN', '');                       
                        NoticeEmitter.emit('freshAllDriverMap', ''); 
                     }                   
                }, 600);

               /*
                //setTimeout(() => {  console.log("World!"); }, 2000);
               setTimeout(()=>{ 
                    console.log("World!" + relatedTripPINColor);
                    console.log("setTimeout realtedTripPin is " + JSON.stringify(item));
                    var temp = item;
                    temp.mpaColor = relatedTripPINColor;
                    //setRelatedTripPIN(temp);
                    setRelatedTripPIN(null);
                    setShowRelatedTripPIN(false);
                    setTimeout(() => {  setRelatedTripPIN(null); }, 2000);
               }, 2000);
               */               
            }
        });
        
    }

    function renderRelatedTripPin(){
        return(
                relatedTripPIN? <Marker 
                    longitude={relatedTripPIN.long}
                    latitude={relatedTripPIN.lat}
                 >
                    <TripPin onClick={() => handleTripClick(relatedTripPIN.drNum,relatedTripPIN.jobID,relatedTripPIN.json)} pin={relatedTripPIN} 
                         json={relatedTripPIN.json} onMouseOver={() => {}}/>
                </Marker> :<></> 
                
        )}

        function renderRelatedOrgTripPin(){
            return(
                     <Marker 
                        longitude={relatedOrgTripPIN.long}
                        latitude={relatedOrgTripPIN.lat}
                     >
                        <TripPin onClick={() => handleTripClick(relatedOrgTripPIN.drNum,relatedOrgTripPIN.jobID,relatedOrgTripPIN.json)} pin={relatedOrgTripPIN} 
                             json={relatedOrgTripPIN.json} onMouseOver={() => handleMouseOver(relatedOrgTripPIN)}/>
                    </Marker>   
                    
        )}   
    
   
    
    return (mapPoints[mapPoints.length-1] !== undefined) ? (
        <Map
            ref={mapRef} onLoad={onMapLoad}
            initialViewState={{
                longitude: mapPoints[0].long,
                latitude: mapPoints[0].lat,
                pitch:85,
                zoom: 10
            }}
            style={{width: "100%", height: "80vh"}}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            //mapStyle= "mapbox://styles/mapbox/satellite-streets-v11"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        >
            {props.IsShowDrivers && driverMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <DriverPin onHover={() => props.hoverHandler(value.drNum, value.json)} onClick={() => handleIconClick(value.drNum, value.json)} drNum={value.drNum} pin={value}/>
                </Marker>          
            )}  
     
            {props.IsShowDrivers && exceptionMapPoints.map((value) => 
                <Marker 
                    longitude={value.long}
                    latitude={value.lat}                   
                  >
                    <DriverPin onHover={() => props.hoverHandler(value.drNum, value.json)} onClick={() => handleIconClick(value.drNum, value.json)} drNum={value.drNum} pin={value}/>
                    <ExceptionPin/>
                </Marker>          
            )}  

            {props.IsShowPickup && pickTripMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}
                 >
                    <TripPin onClick={() => handleTripClick(value.drNum,value.jobID,value.json)} pin={value} 
                         json={value.json} onMouseOver={() => handleMouseOver(value)}/>
                </Marker>            
            )} 

            {props.IsShowDelivery && deliveryTripMapPoints.map((value) => 
                 <Marker 
                    longitude={value.long}
                    latitude={value.lat}

                 >
                    <TripPin onClick={() => handleTripClick(value.drNum,value.jobID,value.json)} pin={value} 
                       json={value.json} onMouseOver={() => handleMouseOver(value)}/>

                </Marker>            
            )}

            {/* {showRelatedTripPIN &&
                renderRelatedTripPin()
            }

            {showOrgRelatedPIN &&
                renderRelatedOrgTripPin()
            } */}

            {showRelatedTripPIN?
                renderRelatedTripPin():relatedOrgTripPIN!== null ? renderRelatedOrgTripPin():<></>
            }



           <FullscreenControl/> 
        </Map>
    ) : <></>;

};
export default AllMapPanel;



//https://www.tabnine.com/code/javascript/classes/react-map-gl/Marker

import * as React from 'react';
import Popover from '@mui/material/Popover';
import moment from "moment";

import pin_blue from './imgs/driver_pin/pin_blue.png';
import pin_blue_border from './imgs/driver_pin/pin_blue_border.png';
import pin_blue_thick from './imgs/driver_pin/pin_blue_thick.png';
import pin_black from './imgs/driver_pin/pin_black.png';
import pin_black_border from './imgs/driver_pin/pin_black_border.png';
import pin_black_thick from './imgs/driver_pin/pin_black_thick.png';

import pin_red from './imgs/driver_pin/pin_red.png';
import pin_red_border from './imgs/driver_pin/pin_red_border.png';
import pin_red_thick from './imgs/driver_pin/pin_red_thick.png';
import pin_gray from './imgs/driver_pin/pin_gray.png';
import pin_gray_border from './imgs/driver_pin/pin_gray_border.png';
import pin_gray_thick from './imgs/driver_pin/pin_gray_thick.png';

import pin_brown from './imgs/driver_pin/pin_brown.png';
import pin_brown_border from './imgs/driver_pin/pin_brown_border.png';
import pin_brown_thick from './imgs/driver_pin/pin_brown_thick.png';
import pin_maroon from './imgs/driver_pin/pin_purple.png';
import pin_maroon_border from './imgs/driver_pin/pin_purple_border.png';
import pin_maroon_thick from './imgs/driver_pin/pin_purple_thick.png';

import pin_orange from './imgs/driver_pin/pin_orange.png';
import pin_orange_border from './imgs/driver_pin/pin_orange_border.png';
import pin_orange_thick from './imgs/driver_pin/pin_orange_thick.png';

import breadcrumb_blue from './imgs/blue_48.png';
import breadcrumb_black from './imgs/black_dot_40.png'

function BreadCrumbPin({size = 40, onHover, pin}) {
    //console.log("BreadCrumbPin-" + pin.drNum);

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    var pin_src = breadcrumb_black;
    var text_color = 'white';
    
    if(!pin.type.includes('Location')){
        pin_src = breadcrumb_blue;
        text_color = 'white';
    } 

    function processJson(pin){

        // var gps_time = new Date(moment(pin.gpsTime).toDate() - new Date().getTimezoneOffset() * 60000); 
        // gps_time = moment(gps_time).format("YY-MM-DD HH:mm");

        var gps_time = moment.utc(pin.gpsTime).toDate();
        gps_time = moment(gps_time).local().format('YYYY-MM-DD HH:mm');

        if(!pin.type.includes('Location')){
            if(json === null ) return;
            var json = JSON.parse(pin.json);              
            return(
                <div style={{display:'flex', maxWidth:'400px', flexWrap:'wrap', fontSize:'13px'}}>
                    <div style={{padding:'3px 6px'}}>           
                        <b className='colorPrimary'>Company:</b> {json[0].Suite}  {json[0].Address}
                    </div>
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>JobID</b> {pin.jobID}</div>
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Svc</b> {json[0].service}</div> 
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Package</b> {json[0].desc}</div> 
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Pcs/Wgt</b> {json[0].pieces}ps/{json[0].weight}lbs</div>
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Call in</b> {json[0]["Called In"]}</div>
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>DCT</b> {json[0]["DCT Time"]} </div>
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Delivered Time</b> {json[0]["Delivered Time"]} </div>
                    <div style={{padding:'3px 6px'}}>
                        {(json[0].del_inst !== undefined  && json[0].del_inst !== "" && json[0].del_inst !== null) && <b className='colorPrimary'>Instructions </b>} 
                        {(json[0].del_inst !== undefined  && json[0].del_inst !== "" && json[0].del_inst !== null) && <span> {json[0].del_inst}</span>} 
                    </div>
                    {/* <div style={{padding:'3px 6px'}}><b className='colorPrimary'>GPS Time</b> {pin.gpsTime} </div> */}
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>GPS Time</b> {gps_time} </div>
                </div>
            );         
        }
        else{
            return(
                <div style={{display:'flex', maxWidth:'400px', flexWrap:'wrap', fontSize:'13px'}}>
                    {/* <div style={{padding:'3px 6px'}}><b className='colorPrimary'>GPS Time</b> {pin.gpsTime} </div> */}
                    <div style={{padding:'3px 6px'}}><b className='colorPrimary'>GPS Time</b> {gps_time} </div>
                </div>
            );     
        }

    }

    // const handlePopoverOpen = (event) => {
    //     setAnchorEl(event.currentTarget);       
    // };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };   

    const onClick = (event) =>{
        setAnchorEl(event.currentTarget);  
    }

    const open = Boolean(anchorEl);

    return (
    <div style={{display: "flex", justifyContent: "center"}} onMouseEnter={onHover} onMouseLeave={handlePopoverClose} onClick={onClick}>
      <div style={{position: "absolute", fontWeight:'400', color: text_color, bottom:14, fontSize: size/3}}>{pin.mapLabel}</div>
      <img
        src={pin_src}
        onClick={onClick}
        style={{width:pin.type.includes('Location')?'40px' :'40px', height:'auto'}}
      />
     

        <Popover
            className='popoverDark'
            sx={{
             pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
              <div 
                style={{  
                  padding:'10px'
                }}
              >
                 {processJson(pin)}
              </div>         
        </Popover>    

    </div>
  );
}

export default React.memo(BreadCrumbPin);
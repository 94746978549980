import React, { useState,useEffect} from 'react';
import './PanelItem.css';
import { Grid } from '@material-ui/core';
import ContactForm from './ContactForm';
import CustomIconButton from '../inputFields/CustomIconButton';
import AddressForm from '../addressForm';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CustomDialog from '../dialog/Dialog';

const PanelItem = props => {
   
    
    const [showEditAddressForm, setShowEditAddressForm] = useState(false);

    // useEffect(() => {
    //     console.log('PanelItem-' + JSON.stringify(props.data));
        
    // }, []);


    const handleClick = (event) => {
        console.log(event); 
        setShowEditAddressForm(!showEditAddressForm);    
    };

    //Format Last Delivery date
    let dateFormatted = "";
    if (props.data.LastDate) {
        const date = new Date(props.data.LastDate.split("T")[0]);
        const dateString = date.toDateString();
        dateFormatted = dateString.split(" ")[1] + " " + dateString.split(" ")[2] + ", " + dateString.split(" ")[3];
    }

    return (
        <Grid container className={props.selected ? "panel-item-box" : "panel-item-box-unselected"} spacing={0} key='panel'> 
            <Grid item lg={9} sm={8} xs={12}>
                <div className="panel-item-title">
                    <h3>{props.data.companyName}</h3>
                </div>
                <div className="panel-item-info">
                    {(props.data.suite !== '') ? 
                        (<p>Suite {props.data.suite},  {props.data.address}, {props.data.quad}</p>)
                        : (<p>{props.data.address}, {props.data.quad}</p>)
                    }
                    <p>{props.data.city}, {props.data.prov}, {props.data.country}, {props.data.postal} &nbsp;
                        <Tooltip title={props.data.addressID} placement="top" arrow>
                            <LocationOnIcon style={{fontSize: "14px"}}/>
                        </Tooltip> 
                    </p>
                    <p>
                        <strong>Last delivery completed:&nbsp;</strong> 
                        {dateFormatted} &nbsp;
                        <Tooltip title={`${props.data.lat}, ${props.data.long}`} placement="top" arrow>
                            <GpsFixedIcon style={{fontSize: "14px", display: "inline"}}/>
                        </Tooltip>
                    </p>
                </div>
            </Grid>         
            {showEditAddressForm && (
                <AddressForm 
                    title="Edit Address" 
                    cancelButtonLabel="Cancel" 
                    saveButtonLabel="Save Address" 
                    variant="edit"
                    cancelButtonOnClick={handleClick}
                    data={props.data}
                />
            )}

        </Grid>
    );
};

export default PanelItem;
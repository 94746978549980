import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputField from "./inputField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ReferenceFields = (props) => {
  // const [references, setReferences] = useState(props.referenceList);
  const [editReferences, setEditReferences] = useState([]);
  const [checkboxReferences, setCheckboxReferences] = useState([]);
  const [dropdownReferences, setDropdownReferences] = useState([]);

  useEffect(() => {
    let editList = [];
    let checkboxList = [];
    let dropdownList = [];
    props.referenceList.forEach((item) => {
      switch (item.typeDesc) {
        case "CheckBox":
          //var IsChecked = row.defaultValue === '1';
          checkboxList.push(item);
          break;
        case "DropDown":
          dropdownList.push(item);
          break;
        case "Edit":
          editList.push(item);
          break;
        default:
          break;
      }
    });
    setEditReferences(editList);
    setCheckboxReferences(checkboxList);
    setDropdownReferences(dropdownList);
  }, [props.referenceList]);

  const handleRefValueChange = (event) => {
    console.log(
      event.target.name - "-handleRefValueChange-" + event.target.value
    );
    props.setUpdatedRefValue(event.target.name, event.target.value);
  };

  const renderEditField = (editField) => {
    return (
      <Grid item sm={12}>
        <Typography
          variant="button"
          color="primary"
          style={{ fontSize: 12, fontWeight: 500 }}
        >
          {editField.label}
        </Typography>
        <InputField
          inputType="text"
          name={editField.refID}
          inputLength={50}
          defaultValue={editField.currentValue}
          onBlur={handleRefValueChange}
        />
      </Grid>
    );
  };

  const renderDropdownField = (dropdownField) => {
    return (
      <Grid item sm={12}>
        <Typography
          variant="button"
          color="primary"
          style={{ fontSize: 12, fontWeight: 500 }}
        >
          {dropdownField.label}
        </Typography>
        <TextField
          name={dropdownField.refID}
          variant="outlined"
          select
          size="small"
          style={{ backgroundColor: "#13ACB1", marginLeft: 10, width: 200 }}
          defaultValue={dropdownField.defaultValue}
          onChange={handleRefValueChange}
        >
          {dropdownField.ddValues.map((item) => (
            <MenuItem key={item.ddvalue} value={item.ddvalue}>
              {item.ddvalue}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  };

  const renderCheckboxField = (checkboxField) => {
    return (
      <Grid item sm={12}>
        <FormControlLabel
          control={
            <Checkbox
              name={checkboxField.refID}
              color="primary"
              onChange={(event) => {
                props.setUpdatedRefValue(
                  event.target.name,
                  event.target.checked
                );
              }}
            />
          }
          label={
            <Typography
              style={{ fontSize: 12, fontWeight: 500 }}
              variant="button"
              color="primary"
            >
              {" "}
              {checkboxField.label}
            </Typography>
          }
        />
      </Grid>
    );
  };

  return (
    <Grid item sm={6}>
      <Grid container direction="row">
        {editReferences.map((item) => renderEditField(item))}
        {checkboxReferences.map((item) => renderCheckboxField(item))}
        {dropdownReferences.map((item) => renderDropdownField(item))}
      </Grid>
    </Grid>
  );
};
export default ReferenceFields;

import React, { useState, useEffect } from "react";
import Copyright from "../Copyright";
import Map, { Marker, FullscreenControl, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { withRouter } from "react-router";
import TripPin from "./tripPin";

export default function OrdersMap(props) {
  const [orderList, setOrderList] = useState([]);
  const mapRef = React.useRef();

  useEffect(() => {
    var retrievedOrderList = localStorage.getItem("orderListInMap");
    if (retrievedOrderList !== null && retrievedOrderList !== "") {
      var orders = JSON.parse(retrievedOrderList);
      console.log("retrieved ordersList is " + JSON.stringify(orders));
      setOrderList(orders);
    }
  }, []);

  const onHover = () => {};

  const onClick = () => {};

  return (
    <>
      <div style={{ width: "100%" }}>
        <Map
          ref={mapRef}
          initialViewState={{
            longitude: -114.0719,
            latitude: 51.0447,
            pitch: 85,
            zoom: 10,
          }}
          style={{ width: "100%", height: 1100 }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        >
          {orderList.map((value) => (
            <Marker longitude={value.cLong} latitude={value.cLat}>
              <TripPin
                onClick={() => {}}
                order={value}
                onMouseOver={() => {}}
                className="marker co-marker"
                variant="Cons"
              />
            </Marker>
          ))}

          {orderList.map((value) => 
                    <Marker 
                        longitude={value.sLong}
                        latitude={value.sLat}
                    >
                       <TripPin onClick={() => {}} order={value} onMouseOver={() => {}} className="marker sh-marker" variant='shipper'/>  
                    </Marker> 
                )}
          <FullscreenControl />
        </Map>
      </div>
      <footer>
        <Copyright />
      </footer>
    </>
  );
}

// import { pink, red, orange, blue, green, grey } from "@material-ui/core/colors";

export const palette = {
  primary: {
    contrastText: "#FFF",
    light: "#B9F1F2",
    main: "#13ACB1",
    dark: "#007E82",
    muted: "#56D9DD",
  },
  secondary: {
    contrastText: "#000",
    light: "#D4E0E6",
    main: "#D4E0E6",
    dark: "#485054",
  },
  error: {
    contrastText: "#FFF",
    light: "#FF80A2",
    main: "#DE3562",
    dark: "#820023",
  },
  warning: {
    contrastText: "#FFF",
    light: "#FFAC75",
    main: "#F78133",
    dark: "#C24D00",
    muted: "#485054",
  },
  info: {
    contrastText: "#000",
    light: "#9585EC",
    main: "#715DDC",
    dark: "#4331A4",
  },
  success: {
    contrastText: "#FFF",
    light: "#B9F1F2",
    main: "#13ACB1",
    dark: "#007E82",
    muted: "#56D9DD",
  },
  dark: {
    contrastText: "#FFF",
    light: "#D4E0E6",
    main: "#0D191E",
    dark: "#010C11",
  },
  type: "dark",
  // mode: "dark", 
  contrastThreshold: 3,
  tonalOffset: 0.2,
  greys: {
    white: "#ffffff",
    light: "#E5E5E5",
    muted: "#6c757d",
    medium: "#485054",
    dark: "#1f1f1f",
    black: "#000000",
  },
  // The color used to divide different elements.
  divider: "rgba(0, 0, 0, 0.12)",
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    paper: "#0D191E",
    default: "#0D191E",
  },
};

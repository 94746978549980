import React, { useState, useEffect } from "react";
import "./counterUpTimer.css";

const CounterUpTimer = (props) => {
  const [displayText, setDisplayText] = useState("");
  const [timeDiff, setTimeDiff] = useState(0);
  const FiveMins = 1000 * 60 * 5;
  const TenMins = 1000 * 60 * 10;

  useEffect(() => {
    try {
      //console.log("CounterUpTimer-" + props.dateTimeStamp);

      var startDate = new Date(props.dateTimeStamp);
      var endDate = new Date();
      var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
      //console.log("Time Diff- " +timeDiff );

      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      //hh = ('0' + hh).slice(-2)
      //hh = (hh).slice(-2)

      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);

      setTimeDiff(timeDiff);
      setDisplayText(`${hh}:${mm}`);
    } catch (e) {
      setDisplayText("0:00");
    }
  }, [props.dateTimeStamp]);

  return (
    <div class="base-timer">
      <svg
        class="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="base-timer__circle">
          <circle
            class={
              timeDiff < FiveMins
                ? "base-timer__path-elapsed green"
                : timeDiff > FiveMins && timeDiff < TenMins
                ? "base-timer__path-elapsed orange"
                : "base-timer__path-elapsed red"
            }
            cx="50"
            cy="50"
            r="45"
          />
        </g>
      </svg>
      <span id="base-timer-label" class="base-timer__label">
        {" "}
        {displayText}{" "}
      </span>
    </div>
  );
};

export default CounterUpTimer;

//https://css-tricks.com/how-to-create-an-animated-countdown-timer-with-html-css-and-javascript/
//https://stackoverflow.com/questions/19583557/time-difference-and-convert-into-hours-and-minutes-in-javascript

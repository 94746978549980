import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@material-ui/core';
import httpRequest from '../../HttpService';
import NoticeEmitter from '../../NoticeUtil';

// import { DataGrid } from '@material-ui/data-grid';
import { DataGridPro } from "@mui/x-data-grid-pro";
import Moment from 'moment';

 //import DataGrid, {Column,Selection, FilterRow, Paging,Export,ColumnChooser,Pager,SearchPanel} from 'devextreme-react/data-grid';

const TripRatingList = props => {    

    const [tripRatingList, setTripRatingList] = useState([]);
    const [tableHeight, setTableHieght] = useState(200);
    const [selectedRate,setSelectedRate] = useState();

    
    useEffect(() => {  
        getTripRate();       
    },[]);

    NoticeEmitter.addListener('refreshJobDetail', (data) => {
        console.log('====TripRatingList======NoticeEmitter refreshJobDetail===============' + data);
        setTimeout(function(){ getTripRate(); }, 500);       
    });

    NoticeEmitter.addListener('refreshRating', (data) => {
        console.log('====TripRatingList======NoticeEmitter refreshRating===============' + data);
        setTimeout(function(){ getTripRate(); }, 500);          
    });

    const getTripRate =() =>{
        var ratingList =[];
        httpRequest.getTripRating(props.accessToken,props.jobId).then(body =>{
            console.log("getTripRate-" + JSON.stringify(body.data))
            if(body.data.length !== 0){                
                body.data.forEach((value) => {
                    value.datestamp = value.datestamp.substring(0,19).replace("T"," ");
                    value.id = ratingList.length;
                    value.value = value.value?.toFixed(2);
                    value.CalcAmount = value.CalcAmount?.toFixed(2);
                    value.CalcPayOn = value.CalcPayOn?.toFixed(2);
                    ratingList.push(value);
                });     
                setTripRatingList(ratingList); 
                setTableHieght(180 + 40* ratingList.length);
                setSelectedRate(ratingList[0]);     
            }
        });
    }


    // const handleExemptChange =(event) =>{
    //     selectedRate.exempt =  event.target.checked;
    //     httpRequest.updateZrateExempt(props.accessToken,props.jobID,selectedRate).then(body =>{           
    //         if(body.data) {
    //           console.log("updateZrateExempt- " + JSON.stringify(body.data));
    //         }
    //         getTripRate();
    //     });       
    // }
/*
    const renderExempt = params => {
        return(
            (params.data['mzrid'])? (<Checkbox 
                onClick={(event)=>{
                    console.log("renderExempt- " + params.data['id']);
                    var rate = tripRatingList.filter((item) => {
                        return item.id == params.data['id'];
                    });
                    console.log(event.target.checked + "-------before updateZrateExempt- " + JSON.stringify(rate));
                    //rate.exempt =  event.target.checked?1:0;
                    rate.exempt =  0;
                    console.log("updateZrateExempt- " + JSON.stringify(rate));
                    httpRequest.updateZrateExempt(props.accessToken,props.jobId,rate).then(body =>{  
                        console.log("updateZrateExempt- " + JSON.stringify(body));         
                        if(body.data) {
                           console.log("updateZrateExempt- " + JSON.stringify(body.data));
                        }
                        ////getTripRate();
                        setTimeout(function(){ getTripRate(); }, 1000);
                    });       
                }}
                defaultChecked = {params.value}
            // checked = {params.row.exempt} 
            // onChange={handleExemptChange}        
            />) :(<Typography style={{ marginLeft:"15px" }}> {params.value} </Typography>) 

        )
    }  
  */  
    
    const renderExempt = params => {
        return(
            (params.row.mzrid)? (<Checkbox 
                onClick={(event)=>{
                    let rate = params.row;
                    rate.exempt =  event.target.checked?1:0;
                    console.log("updateZrateExempt- " + JSON.stringify(rate));
                    httpRequest.updateZrateExempt(props.accessToken,props.jobId,rate).then(body =>{  
                        console.log("updateZrateExempt- " + JSON.stringify(body));         
                        if(body.data) {
                           console.log("updateZrateExempt- " + JSON.stringify(body.data));
                        }
                        ////getTripRate();
                        setTimeout(function(){ getTripRate(); }, 1000);
                    });       
                }}
                defaultChecked = {params.row.exempt}
            // checked = {params.row.exempt} 
            // onChange={handleExemptChange}        
            />) :(<Typography style={{ marginLeft:"15px" }}> {params.value} </Typography>)
        )
    }  

    
    const renderDrNum = params => {
        return(
             <a style={{color: "#FFFFFF"}} >{params.value} </a>           
        )
    }


    const onSelectionChanged = ({selectedRowsData}) =>{
        const data = selectedRowsData[0];
        console.log("onSelectionChanged --" + JSON.stringify(selectedRowsData));
        setSelectedRate(selectedRowsData);
      }

    const columns = 
      [
        { field: 'CalcAmount', headerName: 'CalcAmount', width: 120,headerAlign: 'left', align:'left'},
        { field: 'CalcPayOn', headerName: 'CalcPayOn', width: 120},
        { field: 'exempt', headerName: 'exempt', width: 100,  renderCell:renderExempt},
        { field: 'Description', headerName: 'Description', width: 200},
        { field: 'Notes', headerName: 'Notes', width: 260},
        { field: 'datestamp', headerName: 'date/time', width: 200},    
        { field: 'amount', headerName: 'amount', width: 120,headerAlign: 'center', align:'center'},
        { field: 'value', headerName: 'value', width: 120,headerAlign: 'center', align:'center'},
        { field: 'perUnit', headerName: 'perUnit', width: 120,headerAlign: 'center', align:'center'},   
        { field: 'freeUnits', headerName: 'freeUnits', width: 120,headerAlign: 'center', align:'center'}, 
        { field: 'untilUnits', headerName: 'untilUnits', width: 150,headerAlign: 'center', align:'center'}, 
        { field: 'drNum', headerName: 'Driver#', width: 120, renderCell: renderDrNum,headerAlign: 'center', align:'center'},
        { field: 'review', headerName: 'review', width: 150}    
      ]  
 

    return (
        <div className='darkDataGrid' style={{ height: tableHeight, width: '100%' }}>  
            <DataGridPro 
                rows = {tripRatingList}
                columns = {columns}
                checkboxSelection  = {false}
                disableColumnSelector = {true}
                disableColumnMenu = {true}
                hideFooterPagination = {true}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = tripRatingList.filter((row) =>
                        selectedIDs.has(row.id)
                    );
                    setSelectedRate(selectedRowData[0]);                   
                }}
            />
            {/* <DataGrid
                    dataSource={tripRatingList}
                    showBorders={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    keyExpr="id"
                    rowAlternationEnabled={true}  
                    onSelectionChanged={onSelectionChanged}         
                >                    
                    <Paging 
                       defaultPageSize={10}       
                     />
                    <Pager
                       visible={true}
                       showInfo={true}
                       infoText="Page #{0}. Total: {1} ({2} items)"  
                       displayMode="full"
                     />

                     <Selection
                       mode="single"
                     />

                    <Column dataField="CalcAmount" caption="CalcAmount"  width={120}/>
                    <Column dataField="CalcPayOn" caption="CalcPayOn" width={120}/>                     
                    <Column dataField="exempt" caption="exempt" width={100} cellRender={renderExempt}/>
                    <Column dataField="Description" caption="Description"  width={200}/>
                    <Column dataField="Notes" caption="Notes" width={200}/>  
                    <Column dataField="datestamp" caption="Date/Time"  width={200}/>
                    <Column dataField="amount" caption="amount" width={120}/>  
                    <Column dataField="value" caption="value"  width={120}/>
                    <Column dataField="perUnit" caption="perUnit" width={120}/>  
                    <Column dataField="freeUnits" caption="freeUnits"  width={120}/>
                    <Column dataField="untilUnits" caption="untilUnits" width={150}/>  
                    <Column dataField="drNum" caption="Driver #" width={100} cellRender={renderDrNum}/>
                    <Column dataField="review" caption="review" width={150}/>  
                </DataGrid> */}
        </div>
        
    );
}

export default TripRatingList;



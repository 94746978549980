import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Container} from '@material-ui/core';
import Copyright from './Copyright';

class WDWebview extends  React.Component {
    constructor(props) {
        super(props); 
        let web5_url =  localStorage.getItem('web5URL','https://ship.directx.ca');   
          
        // let web5_url =  localStorage.getItem('web5URL','https://webx.directx.ca');    
        // web5_url = web5_url.replace('ship','webx');  
        console.log("=====web5 -- " + web5_url);  
        this.state = {
            url: web5_url
        }   
        
        // this.state = {
        //     url:""
        // }   
        localStorage.setItem('searchParameter', "");       
        localStorage.setItem('seviceSearchParameter', "");                    
    }    
    
    componentDidMount = () =>  {  

        let token =  localStorage.getItem('access_token');
        if(token === undefined || token === null)
           window.location.href='/';  

        // let web5_url =  localStorage.getItem('web5URL','https://webx.directx.ca');    
        // web5_url = web5_url.replace('ship','webx');  
        // console.log("=====web5 -- " + web5_url);  
        // this.setState({url:web5_url});    
    }

    render() {
       return (
           <div style={{ width: '100%' }}>
                {/* {this.state.url!=='' && <iframe
                    width="100%"
                    height="1150px"
                    src={ this.state.url}
                />} */}
               <iframe
                    width="100%"
                    height="1200px"
                    src={ this.state.url}
                />


                <footer>
                    <Copyright/>
                </footer>
            </div>           
        );
    }
}

export default withRouter(WDWebview);
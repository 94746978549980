import React, { useState, useEffect } from 'react';

import httpRequest from '../../HttpService';
import Moment from 'moment';

import { DataGrid } from '@mui/x-data-grid';
import { DataGridPro } from "@mui/x-data-grid-pro";

const DriverMessageList = props => {   

    // const [curDriver, setCurDriver] = useState(props.currentDriver);    
    const [msgFromDriver, setMsgFromDriver] = useState([]);  

    useEffect(() => {    
        const messageList=[];   
        httpRequest.getServiceMessages(props.accessToken).then(async function(body){
            await body.data.forEach((value) => {
                //console.log("value is " + JSON.stringify(value));
                value.datestampUTC = Moment(value.datestampUTC).format('YY-MM-DD,hh:mm');
                value.msgLine = value.messageText;
                messageList.push(value);
            });
        });
        //console.log(messageList);
        
        getMessagesFromDriver();
        const interval = setInterval(() => {            
            getMessagesFromDriver();
        }, 60000);
        return () => clearInterval(interval);
    },[props.currentDriver])


    const getMessagesFromDriver = () =>{
        var driverMsgList=[];
        httpRequest.getMessagesFromDriver(props.accessToken, props.companyID, props.currentDriver).then(async function(body){
               //console.log("item is " + JSON.stringify(body.data));
                //var cnt =0;
                //await body.data.forEach((value) => {
                   //console.log("item is " + JSON.stringify(value));
                //    var msg = {
                //         id: cnt,
                //         text: value.msgLine,
                //         jobID: value.jobID,
                //        // direction:item.msgLine.includes("->")? "outgoing":"incoming"
                //     };
                    //item.id = cnt;
                    //item.text = item.msgLine;
                    //item.direction = item.msgLine.includes("->")? "outgoing":"incoming";
                    //driverMsgList.push(value);                   
                   //});   
                   driverMsgList = body.data;    
                   const listWithID = [];
                   var currentID = 0;
                   driverMsgList.map(item => {
                       listWithID.push({
                          ...item,
                       id: currentID
                       });
                   currentID = currentID + 1;
                   });
                   setMsgFromDriver(listWithID);         
        });

        //messageList.sort((a, b) => a.datestampUTC > b.datestampUTC ? -1 : 1);
        //setMsgFromDriver(driverMsgList);     
    }


    const renderJobID = params => {
        return(
             <a className="jobIDLink">{params.value} </a>           
        )
    }
   
    const oneDriverColumns = 
    [
        { field: 'msgLine', headerName: 'Message', width: 460},
        { field: 'jobID', headerName: 'Job ID', width: 120, renderCell: renderJobID}, 
    ]  


    const handleOnCellClick = (params) => {
        console.log(params.field);
        if(params.field === "jobID"){
            window.location.href='/jobDetail/' + params.value;  
        }
     };
 

    return (
        <div className='darkDataGrid' style={{ width: '100%', height:'75vh' }}>
            <DataGridPro
                // autoHeight = {true}
                rows = {msgFromDriver}
                columns = {oneDriverColumns}
                checkboxSelection  = {false}
                disableColumnSelector = {true}
                disableColumnMenu = {true}
                onCellClick={handleOnCellClick}
            />         
        </div> 
    );
}

export default DriverMessageList;


import * as React from 'react';
import pin_img from './imgs/driver_pin/driver_blue.png';
import pin_red from './imgs/red.png';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  cursor: 'pointer',
  fill: '#600',
  stroke: 'none',
  justifyContent: "center"
};

// function Pin({size = 40, onClick, drNum}) {
//   return (
//     <div style={{display: "flex", justifyContent: "center"}}>
//       <p style={{position: "absolute", color: "white", bottom: 0, fontSize: size/3}}  >{drNum}</p>
//       <svg height={size} viewBox="0 0 24 24" style={pinStyle} onClick={onClick}>
//         <path d={ICON} />
//       </svg>
//     </div>
//   );
// }

function Pin({size = 40, onClick, drNum}) {
  return (
    <div style={{display: "flex", justifyContent: "center"}}>
      <p style={{position: "absolute", color: "white", bottom: 10, fontSize: size/3}}>{drNum}</p>
      <img
        src={pin_img}
      />
    </div>
  );
}



export default React.memo(Pin);
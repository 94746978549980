import React, { useRef, useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
// import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.dark.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  Export,
  ColumnChooser,
  Pager,
  SearchPanel,
  GroupPanel,
  Grouping,
  GroupItem,
  SortByGroupSummaryInfo,
  Summary,
  MasterDetail,
  StateStoring,
  ColumnFixing,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
} from "devextreme-react/data-grid";

import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from "devextreme/excel_exporter";
import NoticeEmitter from "../NoticeUtil.js";


import "./search.css";
import MasterDetailView from "./MaterDetailView";

export default function DEDataGrid(props) {
  const [trips, setTrips] = useState([]);
  const orderDataTable = useRef(null);

  const useStyles = makeStyles({
    underline: {
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
    }
  });

  const classes = useStyles();

  NoticeEmitter.addListener("resetTable", (data) => {
    console.log("==========NoticeEmitter resetTable===============");
    try {
      if(orderDataTable !== null && orderDataTable.current!== null && orderDataTable.current.instance !== undefined && orderDataTable.current.instance !== null){
         //orderDataTable.current.instance.deselectAll();
         orderDataTable.current.instance.clearSelection();        
      }
    } catch (e) {
      console.log(e);
    }
  });

  const allowedPageSizes = [30, 50, 100];

  useEffect(() => {
      console.log("stateReset-" + props.stateReset);   
      orderDataTable.current.instance.state(null);   
      props.setStateReset(false); 
  }, [props.stateReset]);

  useEffect(() => {
    console.log("search trip length-" + props.trips.length);
    console.log("search trip -" + JSON.stringify(props.trips[0]));
    const listWithID = [];
    var currentID = 0;
    props.trips.map((trip) => {
      listWithID.push({
        ...trip,
        id: currentID,
        // Status: statusToString.get(trip.Status),
        // location: locationToString.get(trip.location),
        SER_TIME: trip.SER_TIME?.toString(),
        RATE: trip.RATE?.toString(),
        dtDCT_UTC: !trip.dtDCT_UTC
          ? null
          : format(parseISO(trip.dtDCT_UTC), dateFormat),
        dtCallIn_UTC: !trip.dtCallIn_UTC
          ? null
          : format(parseISO(trip.dtCallIn_UTC), dateFormat),
        dtDelivered_UTC: !trip.dtDelivered_UTC
          ? null
          : format(parseISO(trip.dtDelivered_UTC), dateFormat),
      });
      currentID = currentID + 1;
    });
    setTrips(listWithID);
  }, [props.trips]);

  // const dateFormat = 'yy-MM-dd HH:mm';
  const dateFormat = "MM/dd/yyyy HH:mm";

  const renderJobID = (params) => {   
    return (
      <a
        style={{ color: "#13ACB1" }}
        onClick={() => {
          //console.log(params.data.isLattice);
          let inxURL = params.data.isLattice? `/latticeJobDetail/${params.value}`:`/jobdetail/${params.value}`;
          window.open(inxURL, "_blank");
        }}
      >
        {params.value}{" "}
      </a>
    );
  };

  const renderInstructions = (params) => {
    return (
      <TextField
        fullWidth      
        multiline placeholder="" style={{ width: '100%' }} defaultValue={params.value} 
        InputProps={{ classes, readOnly: true }}/>
      // <TextField
      //   fullWidth      
      //   placeholder="" style={{ width: '100%' }} defaultValue={params.value} 
      //   InputProps={{ classes, readOnly: true }}/>   
    );
  };

  //readOnly: true
  //    const handleOnCellClick = (params) => {
  //      console.log(params.field + '---' + params.row.jobID);
  //      if(params.field === "jobID"){
  //           //window.location.href='/jobDetail/' + params.value;
  //           let inxURL ="/jobdetail/"+params.value;
  //           window.open(inxURL, "_blank");
  //      }
  //   };

  const onSelectionChanged = ({ selectedRowsData }) => {
    //const data = selectedRowsData[0];
    console.log("============onSelectionChanged --" + JSON.stringify(selectedRowsData));
    props.setSelection(selectedRowsData);
  };

   const onExporting = (e) => {
    console.log("onExporting-" + e.format);

    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save("inx-orders.pdf");
      });
    } else if (e.format === "csv") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        // https://github.com/exceljs/exceljs#writing-csv
        // https://github.com/exceljs/exceljs#reading-csv
        workbook.csv.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "inx-orders.csv"
          );
        });
      });
    }
  };

  const exportFormats = ["pdf", "xlsx", "csv"];

  const filterBuilderPopupPosition = {
    of: window,
    at: "top",
    my: "top",
    offset: { y: 10 },
  };

  return (
    <DataGrid
      ref={orderDataTable}
      dataSource={trips}
      showBorders={true} 
      allowColumnReordering={true}
      allowColumnResizing={true}    
      columnResizingMode="widget"
      keyExpr="id" 
      onSelectionChanged={onSelectionChanged}
      onExporting={onExporting}
      //rowAlternationEnabled={true}
      onRowPrepared={(e) => {
        //console.log("onRowPrepared --" + JSON.stringify(e.rowIndex));
        if (e.data &&(e.data.Status === "Verified" || e.data.Status === "Invoiced" || e.data.Status === "Delivered"))
          e.rowElement.style.backgroundColor = "#0D191E";
         else if (e.data && e.rowIndex % 2 === 0)
          e.rowElement.style.backgroundColor = "#0D191E";
        else if (e.data && e.rowIndex % 2 !== 0)
          e.rowElement.style.backgroundColor = "#010C11"; 
      }}
     
      // filterValue={props.filterQuery}
    >
      {/* <RemoteOperations
          filtering={true}
          paging={true}
          sorting={true}
          summary={true}
          grouping={true}
          groupPaging={true}
      /> */}
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={filterBuilderPopupPosition} />
      <HeaderFilter visible={true} />

      <GroupPanel visible={true} />
      <Grouping autoExpandAll={false} />
      <SearchPanel visible={true} width={240} placeholder="Search..." />

      {/* <Scrolling mode="infinite" />   */}

      {/* selectAllMode="page" selectAllMode="allPages"*/}
      <Selection
        mode="multiple"
        selectAllMode="page"
        showCheckBoxesMode="always"
      />

      <FilterRow visible={true} />
    
      {/* <Paging enabled={false} /> */}

      <Paging defaultPageSize={50} />
      <Pager
        visible={true}
        //allowedPageSizes="auto"
        showInfo={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        infoText="Page {0} of {1} ({2} items)"
        displayMode="adaptive"        
      />

      {/* <StateStoring enabled={true}  type="custom" /> */}
      <StateStoring enabled={true} type="localStorage" storageKey="storage" />

      {/* <ColumnFixing enabled={true} /> */}
      <Column  dataField="jobID" caption="JobID" width={100} cellRender={renderJobID} />
      {/* <Column  dataField="jobID" caption="JobID" fixed={true} fixedPosition="left" width={100} cellRender={renderJobID} /> */}
      <Column  dataField="ACCOUNT" caption="Account" width={90} />     
      {/* <Column dataField="Status" caption="Status" width={140} />
      <Column dataField="location" caption="Location" width={90} /> */}
      <Column dataField="statusString" caption="Status" width={150} />
      <Column dataField="locationString" caption="Location" width={100} />
      <Column dataField="SER_AREA" caption="Ser. Area" width={80} />
      <Column dataField="SER_TIME" caption="Ser. Time" width={80}/>     
      <Column dataField="RATE" caption="Rate" width={70} />  
      <Column  dataField="CALLER" caption="Caller" width={100} />       
      <Column dataField="CUST_REF" caption="Reference" width={130}/> 
          
      <Column dataField="PROOF_DEL" caption="Deli.Proof" width={120}  visible={!props.defaultChecked}/>
      <Column dataField="dtDelivered_UTC"
        caption="Deli. By" dataType="datetime" width={150}  visible={!props.defaultChecked}/>   
      <Column dataField="pickDriver" caption="1st Drv." width={80}  //alignment="left"
      />
      <Column dataField="last_driv" caption="Last Drv." width={80} //alignment="left"
      />  
      <Column  dataField="dtCallIn_UTC" caption="Call Time" width={150} dataType="datetime"/>     
      <Column  dataField="dtDCT_UTC" caption="Due By"  width={150} dataType="datetime"/>   
        
      <Column dataField="sCompanyName" caption="From" width={200} />     
      <Column dataField="sAddress" caption="Sh. Address" width={120}/>    
      <Column dataField="sQuad" caption="sh. Quad" width={80}/>
      <Column dataField="sCity" caption="sh. City" width={80} /> 
      <Column dataField="sContact" caption="Sh. Contact" width={100}       
        visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sPhone" caption="sh. Phone" width={120}        
        visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sLat" caption="sh. Lat" width={100}       
       visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="sLong" caption="sh. Long" width={100}
       visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cCompanyName" caption="To" width={200}/>
      <Column dataField="cAddress" caption="Co. Address" width={120}/>
       
      <Column dataField="cQuad" caption="Co. Quad" width={80}/>
      <Column dataField="cCity" caption="Co. City" width={80}/>
       
      <Column dataField="cContact" caption="Co. Contact" width={100}  
        visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column  dataField="cPhone" caption="Co. Phone" width={120}   
        visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cLat" caption="Co. Lat" width={100}  
        visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="cLong" caption="Co. Long" width={100}   
      visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="creator" caption="Creator" width={100}    
      visible={!props.defaultChecked && !props.verficationChecked}/>
      <Column dataField="DESC" caption="PackageType" width={100}   
      visible={!props.defaultChecked && !props.verficationChecked}/>  
       
      <Column dataField="PIECES" caption="Pieces" width={75} 
        visible={props.defaultChecked}/>    
      <Column dataField="WEIGHT" caption="Weight" width={80} //alignment="left"        
        visible={props.defaultChecked}/>
      <Column dataField="DEL_INST" caption="Instructions" width={200}  
        cellRender ={renderInstructions} visible={props.defaultChecked}
      />   
      
      <ColumnChooser enabled={true} mode="select" />
      <StateStoring enabled={true} type="localStorage" storageKey="storage" /> 
      <Summary>
        <GroupItem
          column="jobID"
          summaryType="count"
          displayFormat="{0} orders"
        />
        <GroupItem
          column="RATE"
          summaryType="avg"
          valueFormat="decimal"
          displayFormat="Avg Rate:${0}"
          showInGroupFooter={true}
          alignByColumn={false}
        />
        <GroupItem
          column="RATE"
          summaryType="sum"
          //valueFormat="decimal"
          valueFormat="##0.00"
          displayFormat="Sum Rate:${0}"
          alignByColumn={true}
        />
      </Summary>
      <SortByGroupSummaryInfo summaryItem="jobID" />

      <MasterDetail enabled={true} component={MasterDetailView} />

      <Export
        enabled={true}
        formats={exportFormats}
        allowExportSelectedData={true}
      />
    </DataGrid>
  );
}

//https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/ExcelJSOverview/React/Light/
//https://js.devexpress.com/Documentation/Guide/UI_Components/DataGrid/Columns/Customize_Cells/#Customize_the_Appearance

//https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Methods/#deselectAll
//https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Methods/#selectAll

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CreateIcon from '@material-ui/icons/Create';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import ListIcon from '@material-ui/icons/List';
import DoneIcon from '@material-ui/icons/Done';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SaveIcon from '@material-ui/icons/Archive';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import 'font-awesome/css/font-awesome.min.css';

import { palette } from '../palette';


const CustomButton = withStyles({
    root: {
        // display: 'inline-block',
        border: '1px solid #ced4da',
        backgroundColor: palette.primary.light,
        color: palette.primary.main,
        minWidth: '40px',
        width: '40px',
        height:'100%',
        // height: '40px',
        '&:hover': {
            backgroundColor: palette.primary.main,
            color: palette.primary.light,
        },
    },
    textSecondary: {

        backgroundColor: palette.error.light,
        color: palette.error.dark,
        
        '&:hover': {
            backgroundColor: palette.error.main,
            color: palette.error.light,
        },
    },
    outlinedPrimary: {
        backgroundColor: 'transparent',
        color: (props) => props.selected ? 'white' : palette.primary.muted,
        
        '&:hover': {
            backgroundColor: (props) => props.selected ? 'white' : 'transparent',
            color: palette.primary.main,
        },
    }
    
})(Button);

  
function CustomIconButton(props) {

    function renderIcon(){
        
        if (props.icon === "VisibilityOffIcon") {
            return (
                <VisibilityOffIcon/>
            );
        }
        if (props.icon === "CreateIcon") {
            return (
                <CreateIcon/>
            );
        }
        if (props.icon === "CloseSharpIcon") {
            return (
                <CloseSharpIcon/>
            );
        }
        if (props.icon === "AddOutlinedIcon") {
            return (
                <AddOutlinedIcon/>
            );
        }
        if (props.icon === "ListIcon") {
            return (
                <ListIcon/>
            );
        }
        if (props.icon === "DoneIcon") {
            return (
                <DoneIcon/>
            );
        }
        if (props.icon === "AddPersonIcon") {
            return (
                <PersonAddIcon/>
            );
        }
        if (props.icon === "removePersonIcon") {
            return (
                <PersonRemoveIcon/>
            );
        }
        if (props.icon === "SaveIcon") {
            return (
                <SaveIcon/>
            );
        }
        if (props.icon === "EditIcon") {
            return (
                <EditIcon/>
            );
        }
        if (props.icon === "ClearIcon") {
            return (
                <i class="fa fa-eraser" aria-hidden="true" width="30"></i>
            );
        }

    }

    if(props.tooltip) {
        return (
            <Tooltip title={props.tooltip} placement="top" arrow>
                <CustomButton onClick={props.onClick} color={props.color} {...props}>
                    {renderIcon()}
                </CustomButton>
            </Tooltip>
        );
    } else{
        return (
                <CustomButton onClick={props.onClick} color={props.color} {...props}>
                    {renderIcon()}
                </CustomButton>
        );
    }
    
}

export default CustomIconButton;
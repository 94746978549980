import * as React from 'react';
import Popover from '@mui/material/Popover';

import pin_blue_d from './imgs/trip_pin/pin_blue_D.png';
import pin_blue_p from './imgs/trip_pin/pin_blue_P.png';
import pin_light_blue_d from './imgs/trip_pin/pin_lightblue_D.png';
import pin_light_blue_p from './imgs/trip_pin/pin_lightblue_P.png';
import pin_black_d from './imgs/trip_pin/pin_black_D.png';
import pin_black_p from './imgs/trip_pin/pin_black_P.png';
import pin_brown_d from './imgs/trip_pin/pin_brown_D.png';
import pin_brown_p from './imgs/trip_pin/pin_brown_P.png';

import pin_red_d from './imgs/trip_pin/pin_red_D.png';
import pin_red_p from './imgs/trip_pin/pin_red_P.png';

import pin_green_d from './imgs/trip_pin/pin_green_D.png';
import pin_green_p from './imgs/trip_pin/pin_green_P.png';
import pin_light_green_d from './imgs/trip_pin/pin_lightgreen_D.png';
import pin_light_green_p from './imgs/trip_pin/pin_lightgreen_P.png';


import pin_purple_d from './imgs/trip_pin/pin_purple_D.png';
import pin_purple_p from './imgs/trip_pin/pin_purple_P.png';
import pin_yellow_d from './imgs/trip_pin/pin_yellow_D.png';
import pin_yellow_p from './imgs/trip_pin/pin_yellow_P.png';
import { PropaneSharp } from '@mui/icons-material';

// import pin_camera from './imgs/trip_pin/CameraPin_M.png';
// import pin_sign from './imgs/trip_pin/SigPin_M.png';


function TripPin({onClick, pin, json, onMouseOver}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    

    //console.log("tripPIN-" + json);
    function processJson(json, pin){

        if(json === undefined)
           return '';

        json = JSON.parse(json);            

        return (
            <div style={{display:'flex', maxWidth:'400px', flexWrap:'wrap', fontSize:'13px'}}>
                <div style={{padding:'3px 6px'}}>           
                    {(pin.mapLabel.toUpperCase() === "D" || pin.mapLabel.toUpperCase() === "DD" || pin.mapLabel.toUpperCase() === "SD")? <b className='colorPrimary'>Cons </b> : <b className='colorPrimary'>Ships </b>}
                    {json[0].Suite}  {json[0].Address}
                </div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>JobID</b> {pin.jobID}</div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Svc</b> {json[0].service}</div> 
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Pcs/Wgt</b> {json[0].pieces}ps/{json[0].weight}lbs</div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>Call in</b> {json[0]["Called In"]}</div>
                <div style={{padding:'3px 6px'}}><b className='colorPrimary'>DCT</b> {json[0]["DCT Time"]} </div>
                <div style={{padding:'3px 6px'}}>
                    {/* {(json[0].del_inst !== undefined  && json[0].del_inst !== "" && json[0].del_inst !== null) && <br></br>}            */}
                    {(json[0].del_inst !== undefined  && json[0].del_inst !== "" && json[0].del_inst !== null) && <b className='colorPrimary'>Instructions </b>} 
                    {(json[0].del_inst !== undefined  && json[0].del_inst !== "" && json[0].del_inst !== null) && <span> {json[0].del_inst}</span>} 
                    {/* <br></br><b>Instructions:</b>{json[0].del_inst}  */}
                </div>
            </div>
        );
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        onMouseOver(pin);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };   


    var pin_src =pin_blue_d;
    if(pin!== undefined && pin.mapColor.toLowerCase()=== 'black' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_black_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'black' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_black_p;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'blue' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_blue_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'blue' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_blue_p;
    }    
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'light blue' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_light_blue_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'light blue' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_light_blue_p;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'brown' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_brown_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'brown' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_brown_p;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'red' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_red_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'red' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_red_p;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'yellow' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_yellow_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'yellow' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_yellow_p;
    } 
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'purple' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_purple_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'purple' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_purple_p;
    }  
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'dark green' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd'|| pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_green_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'dark green' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_green_p;
    }  
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'light green' && (pin.mapLabel.toLowerCase() === 'sd' ||  pin.mapLabel.toLowerCase() === 'd' || pin.mapLabel.toLowerCase() === 'dd' || pin.mapLabel.toLowerCase() === 'rd')){
        pin_src = pin_light_green_d;
    }
    else if(pin!== undefined && pin.mapColor.toLowerCase()=== 'light green' && (pin.mapLabel.toLowerCase() === 'p' || pin.mapLabel.toLowerCase() === 'pp')){
        pin_src = pin_light_green_p;
    } 

    const open = Boolean(anchorEl);

    return(
       <div style={{display: "flex", justifyContent: "center"}} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
           <img
             src={pin_src}
             onClick={onClick} 
             style={{width:'60px', height:'auto'}}           
           />          
        <Popover
            className='popoverDark'
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        //   style={{position: "absolute"}}
        >
              <div 
                style={{  
                  padding:'10px'
                }}
              >
                 {processJson(json,pin)}
              </div>
         
        </Popover>    

        </div>);
}

export default React.memo(TripPin);
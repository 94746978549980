import { palette } from "../../palette";
const styles = {
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
    },

    labelWrapper: {
        backgroundColor:  '#13acb126',
        borderRadius: '.25rem 0rem 0rem .25rem',
        border: '1px solid #13acb126',
        textAlign: 'center',
        padding: '.35rem',
    },
    labelWrapperSecondary: {
        backgroundColor:  palette.error.dark,
        borderRadius: '.25rem 0rem 0rem .25rem',
        border: '1px solid ' + palette.error.light,
        textAlign: 'center',
        padding: '.35rem',
    },

    label: {
        paddingTop: '.05rem',
        color: palette.greys.light,
    },

    labelSecondary: {
        paddingTop: '.05rem',
        color: palette.error.light,
    },

    /*** field Input ***/

    formControlTextfield: {
        display: 'flex',
        borderRadius: '0rem .25rem .25rem 0rem',
        backgroundColor: palette.dark.dark,
    },

    formControlSelect: {
        display: 'flex',
        borderRadius: '0rem .25rem .25rem 0rem',
        backgroundColor: palette.dark.dark,
    },

}

export default styles;


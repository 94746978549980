import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { palette } from "../../palette";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/*
export default function CustomDialog(props) {
    //const dialogTitle = props.dialogInfo.dialogTitle;
    //const dialogMessage = props.dialogInfo.dialogMessage;
    const dialogTitle = props.dialogTitle;
    const dialogMessage = props.dialogMessage;
    return (
        <div>
        <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent dividers>
            <Typography gutterBottom>
                {dialogMessage}
            </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose} style={{ color: palette.greys.medium }}>
                    Close
                </Button>
                {props.dialogAction && <Button autoFocus onClick={props.dialogAction} style={{ color: palette.primary.main }}>
                    Confirm
                </Button>}
            </DialogActions>
        </Dialog>
        </div>
    );
}
*/

export default function CustomDialog(props) {
  const dialogTitle = props.dialogTitle;
  const dialogMessage = props.dialogMessage;
  return (
    <div>
      <Dialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          {props.hasCloseBtn && (
            <Button
              autoFocus
              onClick={props.onClose}
              style={{ color: palette.greys.medium }}
            >
              Close
            </Button>
          )}
          <Button
            autoFocus
            onClick={props.dialogAction}
            style={{ color: palette.primary.dark }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import "date-fns";
import { Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';

import HttpRequest from "../../HttpService.js";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CustomDialog from "../components/dialog";
import NoticeEmitter from "../../NoticeUtil";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/zh-cn';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/sv';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DateTimePickerLab from '@mui/lab/DateTimePicker';
import { Snackbar, Alert } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';

export default function TripFunctionDialog(props) {
  const parameters = props.tripFunction.parameters;
  //console.log("TripFunctionDialog============" + JSON.stringify(parameters));
  const [inputs, setInputs] = React.useState({});
  const [overrideOpenDialog, setOverrideOpenDialog] = React.useState(false);
  const [overrideDialogMessage, setOverrideDialogMessage] = React.useState("");

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState("");

  if (parameters.length == 0) {
    return null;
  } 

  const overrideFunction = () => {
    let paramsList = parameters;
    paramsList.push({
      label: "override",
      prompt: "",
      type: "bit",
      length: 1,
      mo: "override",
      value: 1,
    });
    console.log(props.tripFunction.procedureName + "----overrideFunction --------" + JSON.stringify(paramsList));
    //console.log(props.tripFunction.accessToken + "----overrideFunction --------" + props.tripFunction.jobId);
    HttpRequest.doTripFunction(
      props.tripFunction.accessToken,
      props.tripFunction.jobId,
      paramsList,
      props.tripFunction.procedureName
    ).then(
      function successCallback(body) {
        //alert( props.tripFunction.procedureName + " procedure is done!");
        console.log("procedure is done " + JSON.stringify(body.data));
        setMessageInfo(
          props.tripFunction.procedureName + " procedure is done!"
        );
        setOpenSnack(true);
        if (props.tripFunction.refreshAfter)
          NoticeEmitter.emit("refreshJobDetail", "");
      },
      function errorCallback(err) {
        alert("Failed to finish procedure " + props.tripFunction.procedureName +". Error is : " + err);
      }
    );
  };

  const renderElements = (item, index) => {
    const params = item;
    //console.log("TripFunctionDialog renderElements --------" + JSON.stringify(item));

    if (params.type === "integer") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index == 0 ? true : false}
          variant="outlined"
          fullWidth
          margin="dense"
          key={params.label}
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "varchar") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index == 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "dropdown") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          select
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        >
          {params.list.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        </Tooltip>
      );
    } else if (params.type === "datetime") {
      return (      
        <Tooltip title={params.mo} placement="top-end" arrow>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"zh-cn"}> */}
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"sv"}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField autoFocus={index == 0 ? true : false} {...props} />
            )}
            label={params.prompt}
            ampm={false} 
            value={params.value}
            // disableOpenPicker
            onChange={(input) => {
              console.log(input)
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.label]: input,
                };
              });
              parameters.forEach((row) => {
                if (row.label === params.label) {
                  row.value = input;
                }
              });
            }}
          />
        </LocalizationProvider>
        </Tooltip>
      );
    } else if (params.type === "decimal") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index == 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            parameters.forEach((row) => {
              if (row.label === params.label) {
                row.value = input.target.value;
              }
            });
          }}
        ></TextField>
        </Tooltip>
      );
    } else if (params.type === "checkbox") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                label={params.prompt}
                defaultChecked={params.default}
                //defaultChecked ={true}
                color="primary"
                onChange={(event) => {
                  setInputs((prevState) => {
                    return {
                      ...prevState,
                      [params.label]: event.target.checked,
                    };
                  });
                  parameters.forEach((row) => {
                    if (row.label === params.label) {
                      row.value = event.target.checked;
                    }
                  });
                }}
              />
            }
            label={
              <Typography style={{ color: "black" }}>
                {params.prompt}
              </Typography>
            }
          />
        </FormControl>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        className="darkDialog"
        open={props.open}
        onClose={() => props.handleDialogClose()}
      >
        <DialogTitle>{props.tripFunction.functionText}</DialogTitle>
        <DialogContent>
          {parameters.map((item, index) => renderElements(item, index))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
            //  console.log(JSON.stringify(parameters));
              parameters.map((item) => { 
                console.log("TripFunctionDialog====params item===" + JSON.stringify(item));
                if(item.type ==="dropdown" && item.value === ""){    
                      item.value = item.default;     
                }
              });  
              console.log("TripFunctionDialog====parameters========" + JSON.stringify(parameters));


              HttpRequest.doTripFunction(
                props.tripFunction.accessToken,
                props.tripFunction.jobId,
                parameters,
                props.tripFunction.procedureName
              ).then(
                function successCallback(body) {
                  console.log("procedure is done " + JSON.stringify(body.data));
                  if (body.data !== "") {
                    var res = body.data;
                    if (res[0]) {
                      console.log(res[0].ResultCode + "---" + res[0].Result);
                      if (res[0].ResultCode === 10) {
                        setOverrideDialogMessage(res[0].Result);
                        setOverrideOpenDialog(true);
                        //props.handleDialogClose();
                      } else {
                        if (res[0].Result) {
                          setMessageInfo(
                            props.tripFunction.procedureName +
                              " result is: " +
                              res[0].Result
                          );
                          setOpenSnack(true);
                        }
                      }
                    }
                  } else {
                    setMessageInfo(
                      props.tripFunction.procedureName + " procedure is done!"
                    );
                    setOpenSnack(true);
                    if (props.tripFunction.refreshAfter)
                      NoticeEmitter.emit("refreshJobDetail", "");
                  }
                },
                function errorCallback(err) {
                  alert(
                    "Failed to finish procedure " +
                      props.tripFunction.procedureName +
                      " Error is : " +
                      err
                  );
                }
              );
              props.handleDialogClose();
            }}
          >
            <CheckIcon></CheckIcon> Confirm
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setInputs({});
              props.handleDialogClose();
            }}
          >
            <ClearIcon></ClearIcon> Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={overrideOpenDialog}
        onClose={() => setOverrideOpenDialog(false)}
        hasCloseBtn={true}
        dialogMessage={overrideDialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          overrideFunction();
          setOverrideOpenDialog(false);
        }}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}

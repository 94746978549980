import React from "react";
import TextInput from "../../components/inputFields/TextInput";

export default function InputField(props) {
  const handleOnBlur = (event) => {
    props.onBlur(event);
  };

  return (
    <TextInput
      hideLabel={true}
      variant="filled"
      style={{
        backgroundColor: "#13ACB1",
        borderRadius: "4px",
        color: "#fff",
        border: "1px solid #13ACB1",
      }}
      inputProps={{ maxLength: props.inputLength }}
      //onChange={handleChange}
      onBlur={handleOnBlur}
      {...props}
    />
  );
}

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import httpRequest from "../../HttpService";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputField from "./inputField";
import CustomIconButton from "../../components/CustomIconButtons";

const CallerField = (props) => {
  const [caller, setCaller] = useState(props.caller);
  const [IsNewCaller, setIsNewCaller] = useState(false);

  return (
    <Grid item sm={10}>
      <Grid container direction="row">
        <Grid item md={6}>
          {!IsNewCaller && (
            <TextField
              name="caller"
              variant="outlined"
              select
              size="small"
              style={{
                backgroundColor: "#13ACB1",
                borderRadius: "4px",
                color: "#fff",
                border: "1px solid #13ACB1",
                width: "100%",
              }}
              value={caller}
              onChange={(event) => {
                setCaller(event.target.value);
                props.handleValueChange(event);
              }}
            >
              {props.callers.map((item) => (
                <MenuItem key={item.username} value={item.username}>
                  {item.username}
                </MenuItem>
              ))}
            </TextField>
          )}
          {IsNewCaller && (
            <InputField
              inputType="text"
              name="caller"
              onBlur={(event) => {
                setCaller(event.target.value);
                props.handleValueChange(event);
              }}              
            />
          )}
        </Grid>
        <Grid item md={6}>
          {!IsNewCaller && (
            <CustomIconButton
              style={{ marginLeft: 5 }}
              icon="AddPersonIcon"
              tooltip="add new"
              onClick={() => {
                setIsNewCaller(true);
              }}
              color="primary"
            />
          )}
           {!IsNewCaller && (
            <CustomIconButton
              style={{ marginLeft: 5 }}
              icon="removePersonIcon"
              tooltip="remove"
              onClick={(event) => {
                httpRequest.removeCaller(props.account, caller).then(async (res) => {
                  console.log(JSON.stringify(res.data));                  
                });
                let ev = {
                  target:{
                    name: "caller",
                    value: ""
                }};
                props.handleValueChange(ev); 
                props.removeCallerFromList(caller);
              }}
              color="primary"
            />
          )}
          {IsNewCaller && (
            <CustomIconButton
              style={{ marginLeft: 5 }}
              icon="ListIcon"
              tooltip="exit user"
              onClick={() => {
                setIsNewCaller(false);
              }}
              color="primary"
            />
          )}
          {IsNewCaller && (
            <CustomIconButton
              style={{ marginLeft: 5 }}
              icon="DoneIcon"
              tooltip="save"
              onClick={() => {
                if(caller!==""){
                  httpRequest.postUserEmails(props.account, caller).then(async (res) => {
                    console.log(JSON.stringify(res.data));
                    props.refreshCallList();
                  });
                }
              }}
              color="primary"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CallerField;

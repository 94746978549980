import React, { useState, useEffect } from "react";
//import DataTable from './dataTable';
import DataTable from "./deDataGrid";
import "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup } from "@material-ui/core";
import Copyright from "../Copyright";
import DateFnsUtils from "@date-io/date-fns";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Moment from "moment";
import SideButtons from "../search/sideButtons";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@material-ui/core/Box";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/ClearAll";
import MapIcon from "@mui/icons-material/Map";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import HttpRequest from "../HttpService";
import { TramRounded } from "@material-ui/icons";

import "./service.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  Text: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 10,
    },
    fontSize: 14,
  },
  dropDownFormControl: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  invisible: {
    display: "none",
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function Service(props) {
  const classes = useStyles();  
  const [localSearchParams, setLocalSearchParams] = useState({
    account: "",
    order: "",
    billing: "",
    caller: "",
    addressID: "",
    serviceArea: "",
    serviceTime: "",
    //number: "",
    drNum: "",
    shipperToFrom: "",
    phone: "",
    name: "",
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    searchByRange: 2,
    driverSearchType: "any",
    location: -1,
    status: -1,
    activeTrip: true,
    exception: false,
    company: 1,
    daysBack: 9
  });


  const [trips, setTrips] = useState([]);
  const [selection, setSelection] = useState([]);
  const [buttons, setButtons] = useState([]);

  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";

    localStorage.setItem("seviceSelectedTripNum", "0");

    const buttonList = [];
    var id = 0;
    HttpRequest.getTripListFunctions(token).then(async function (body) {
      await body.data.forEach((button) => {
        buttonList.push({
          ...button,
          id: id,
        });
        id++;
      });
    });
    setButtons(buttonList);

    var retrievedSearchParams = localStorage.getItem("seviceSearchParameter");
    if (retrievedSearchParams !== "") {
      var searchParams = JSON.parse(retrievedSearchParams);
      // console.log("retrieved search Parameter is " + JSON.stringify(searchParams));
      if (searchParams) {
        setLocalSearchParams(searchParams);
        queryTrips(searchParams);
      } else queryTrips(localSearchParams);
    } else queryTrips(localSearchParams);

    //queryTrips(localSearchParams);

    const interval = setInterval(() => {
      //queryTrips(localSearchParams);
      let SelectedTripNum = parseInt(localStorage.getItem("seviceSelectedTripNum"));
      console.log("service interval- " + SelectedTripNum);
      var retrievedSearchParams = localStorage.getItem("seviceSearchParameter");
      if (retrievedSearchParams !== "" && SelectedTripNum ===0) {
        var searchParams = JSON.parse(retrievedSearchParams);
        // console.log("retrieved search Parameter is " + JSON.stringify(searchParams));
        if (searchParams) {
          setLocalSearchParams(searchParams);
          queryTrips(searchParams);
        }
      }
    }, 90000);
    return () => clearInterval(interval);
  }, []);

  const row1 = [
    ["Account", "account"],
    ["Order ID", "order"],
    ["Billing Reference", "billing"],
    ["Caller", "caller"],
    ["AddressID", "addressID"],
    ["Service Area", "serviceArea"],
    ["Service Time", "serviceTime"],
    ["Driver #", "drNum"],
  ];

  const row2 = [
    ["Shipper to From", "shipperToFrom"],
    ["Contact Phone", "phone"],
    ["Contact Name", "name"],
  ];

  var dropDowns = [
    [
      "Driver Search Type",
      "driverSearchType",
      [
        ["first", "First Name"],
        ["last", "Last Name"],
        ["any", "Any"],
      ],
    ],
    [
      "Location",
      "location",
      [
        [-1, "All"],
        [1, "At Client"],
        [2, "On Driver"],
        [4, "In Sort"],
        [5, "Rotation Sort"],
        [8, "Delivered"],
      ],
    ],
    [
      "Status",
      "status",
      [
        [-1, "All"],
        [1, "New/Review"],
        [2, "Avail for Dispatch"],
        [3, "Driver Pending"],
        [4, "Driver Responsbility"],
        [8, "Delivered"],
        [16, "Verified"],
        [32, "Invoiced"],
        [255, "Deleted"],
      ],
    ],

    // [
    //   "Company",
    //   "company",
    //   [
    //     ...new Map(
    //       props.companyList.map((company) => [
    //         company.CompanyName,
    //         [company.companyID, company.CompanyName],
    //       ])
    //     ).values(),
    //   ],
    // ],
  ];

  const showMap = () => {
    console.log("showMap --" + JSON.stringify(selection));
    localStorage.setItem("orderListInMap", JSON.stringify(selection));
    // window.location.href='/orderListMap';
    let inxURL = "/orderListMap";
    window.open(inxURL, "_blank");
  };

  const handleSearch = () => {
    //props.updateSearch(localSearchParams);
    queryTrips(localSearchParams);
  };

  const clearSearch = () => {
    setLocalSearchParams({
      account: "",
      order: "",
      billing: "",
      caller: "",
      addressID: "",
      serviceArea: "",
      serviceTime: "",
      drNum: "",
      shipperToFrom: "",
      phone: "",
      name: "",
      date: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      searchByRange: 2,
      driverSearchType: "any",
      location: -1,
      status: -1,
      activeTrip: true,
      exception: false,
      company: 0,
      daysBack: 9,
    });
  };

  const renderSearchButton = (field) => {
    return (
      <FormControl className="fieldWrapper">
        <TextField
          variant="outlined"
          key={field[1]}
          InputProps={{ classes: { input: classes.Text } }}
          margin="dense"
          label={<Typography className={classes.Text}>{field[0]}</Typography>}
          value={localSearchParams[field[1]]}
          className={classes.textField}
          onChange={(searchedVal) =>
            setLocalSearchParams((prevState) => {
              return {
                ...prevState,
                [field[1]]: searchedVal.target.value,
              };
            })
          }
        />
      </FormControl>
    );
  };

  const queryTrips = (params) => {   
    //console.log("queryTrips search Parameter is " + JSON.stringify(params));
    const locationToString = new Map([
      [-1, "All"],
      [1, "At Client"],
      [2, "On Driver"],
      [4, "In Sort"],
      [5, "Rotation Sort"],
      [8, "Delivered"],
    ]);
    const statusToString = new Map([
      [-1, "All"],
      [1, "New/Review"],
      [2, "Avail for Dispatch"],
      [3, "Driver Pending"],
      [4, "Driver Responsbility"],
      [8, "Delivered"],
      [16, "Verified"],
      [32, "Invoiced"],
      [255, "Deleted"],
    ]);
    const setNewSearchParams = (params) => {
      let temp = new Date(params.startDate);
      let start = new Date(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        0,
        0
      );
      start = new Date(Moment(start).unix() * 1000);
      temp = new Date(params.endDate);
      //let end= new  Date(temp.getFullYear(),temp.getMonth(),temp.getDate(),23,59);
      let end = new Date(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        23,
        59
      );
      end = new Date(Moment(end).unix() * 1000);

      if (params.searchByRange === 2 || params.searchByRange === 3) {
        return {
          account: params.account,
          jobID: params.order,
          cust_ref: params.billing,
          caller: params.caller,
          addressID: params.addressID,
          ser_area: params.serviceArea,
          ser_time: params.serviceTime,
          companyName: params.shipperToFrom,
          drNum: params.drNum,
          contactName: params.name,
          contactPhone: params.phone,
          drSearchType: params.driverSearchType,
          companyID: params.company,
          exception: params.exception,
          activeTrip: params.activeTrip,
          exceptionFlag: params.exception.toString(),
          activeTripFlag: params.activeTrip.toString(),
          locationCode: params.location.toString(),
          statusCode: params.status.toString(),
          location: {
            locationCode: params.location,
            descr: locationToString.get(params.location),
          },
          status: {
            statusCode: params.status,
            descr: statusToString.get(params.status),
          },
          daysBack: params.searchByRange === 3 ? params.daysBack : null,
          startDate: start,
          endDate: end,
          //startDate: params.startDate.toString(),
          //endDate: params.endDate.toString(),
        };
      }
      if (params.searchByRange === 1) {
        return {
          account: params.account,
          jobID: params.order,
          cust_ref: params.billing,
          caller: params.caller,
          addressID: params.addressID,
          ser_area: params.serviceArea,
          ser_time: params.serviceTime,
          companyName: params.shipperToFrom,
          drNum: params.drNum,
          contactName: params.name,
          contactPhone: params.phone,
          drSearchType: params.driverSearchType,
          companyID: params.company,
          exception: params.exception,
          activeTrip: params.activeTrip,
          exceptionFlag: params.exception.toString(),
          activeTripFlag: params.activeTrip.toString(),
          locationCode: params.location.toString(),
          statusCode: params.status.toString(),
          location: {
            locationCode: params.location,
            descr: locationToString.get(params.location),
          },
          status: {
            statusCode: params.status,
            descr: statusToString.get(params.status),
          },
          monthDate: params.date.toString(),
        };
      }
    };

    const trips = [];
    console.log("tripSearch  is " + JSON.stringify(setNewSearchParams(params)));
    setShowIndicator(true);
    HttpRequest.tripSearch(
      localStorage.getItem("access_token"),
      setNewSearchParams(params)
    ).then(async function (body) {
      //console.log("tripSearch is " + JSON.stringify(body.data));
      console.log("tripSearch  size is " + body.data.length);
      //var filteredData = body.data.filter(function (el) {
      //  return el.Status < 8;
      //});
      //await filteredData.forEach((trip) => {
      await body.data.forEach((trip) => {  
        trip.dtCallIn = Moment(trip.dtCallIn_UTC).format("YY-MM-DD HH:mm");
        trip.diff = Moment(trip.dtDCT_UTC).diff(Moment.utc());
        trip.blueStar = trip.diff < 30 * 60 * 1000 && trip.location === 1;
        //console.log("tripSearch trip " + trip.jobID + " diff is " + trip.diff);
        trips.push(trip);
      });
      //trips.sort((a, b) => a.dtDCT_UTC > b.dtDCT_UTC ? -1 : 1);
      trips.sort((a, b) => (a.dtDCT_UTC < b.dtDCT_UTC ? -1 : 1));
      setTrips(trips);
      localStorage.setItem("seviceSearchParameter", JSON.stringify(params));
      setShowIndicator(false);
    });
  };

  const renderDropDowns = (field) => {
    return (
      <FormControl className="fieldWrapper">
        <TextField
          variant="outlined"
          key={field[1]}
          label={<Typography className={classes.Text}>{field[0]}</Typography>}
          className={classes.textField}
          value={localSearchParams[field[1]]}
          margin="dense"
          select
          onChange={(searchedVal) =>
            setLocalSearchParams((prevState) => {
              return {
                ...prevState,
                [field[1]]: searchedVal.target.value,
              };
            })
          }
        >
          {field[2].map((choice) => (
            <MenuItem key={choice[0]} value={choice[0]}>
              <Typography className={classes.Text}>{choice[1]}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  };
  return (
    <div style={{ width: "100%" }} className='container_search-list container_service-list'>


    <div id="searchAppBar">
      <FormControl className="search-toggle_form">
        <Typography className="search_heading">Service Search</Typography>
      </FormControl>
      <div className="accordion_buttons">
        <Tooltip title="Map">
          <IconButton onClick={showMap} disabled={selection.length == 0} aria-label="Map" className="iconButton-purple"><MapIcon /></IconButton>
        </Tooltip>
        <Tooltip title="Clear">
          <IconButton onClick={clearSearch} aria-label="Clear" className="iconButton-red"><ClearIcon /></IconButton>
        </Tooltip>
        <Tooltip title="Search">
          <IconButton onClick={handleSearch} aria-label="Search" className="iconButton-orange"><SearchIcon /></IconButton>
        </Tooltip>
      </div>
      </div>
      
      <Accordion defaultExpanded='true'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="colorPrimary" />}
        >
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexWrap="nowrap" flexDirection="column" m={0}>
          <Box display="flex" justifyContent="stretch" border={0} className={"darkForm"} sx={{ height: "auto" }}>
            
              {row1.map((field) => renderSearchButton(field))}
              {dropDowns.map((field) => renderDropDowns(field))}
            
              {row2.map((field) => renderSearchButton(field))}
              <TextField
                style={{ width: 150 }}
                variant="outlined"
                label={
                  <Typography className={classes.Text}>Search Type</Typography>
                }
                className={classes.textField}
                margin="dense"
                select
                value={localSearchParams.searchByRange}
                onChange={(searchedVal) =>
                  setLocalSearchParams((prevState) => {
                    return {
                      ...prevState,
                      searchByRange: searchedVal.target.value,
                    };
                  })
                }
              >
                <MenuItem value={1}>
                  <Typography className={classes.Text}>Month</Typography>
                </MenuItem>
                <MenuItem value={2}>
                  <Typography className={classes.Text}>Range</Typography>
                </MenuItem>
                <MenuItem value={3}>
                  <Typography className={classes.Text}>Days Back</Typography>
                </MenuItem>
              </TextField>
              <TextField
                variant="outlined"
                margin="dense"
                label="Days Back"
                type="number"
                InputProps={{ classes: { input: classes.Text } }}
                value={localSearchParams["daysBack"]}
                className={
                  localSearchParams.searchByRange === 3
                    ? classes.textField
                    : classes.invisible
                }
                onChange={(searchedVal) => {
                  setLocalSearchParams((prevState) => {
                    return {
                      ...prevState,
                      daysBack: searchedVal.target.value,
                      startDate: new Date(
                        new Date().getTime() -
                          searchedVal.target.value * 24 * 60 * 60 * 1000
                      ),
                      endDate: new Date(),
                    };
                  });
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  variant="inline"
                  size="small"
                  margin="dense"
                  label={
                    <Typography className={classes.Text}>Start Date</Typography>
                  }
                  value={localSearchParams.startDate}
                  // onChange={this.handleStartDateChange}
                  onError={console.log}
                  format="yyyy-MM-dd"
                  // style={styles.dateTimePicker}
                  style={{ width: 180 }}
                  className={
                    localSearchParams.searchByRange === 2
                      ? classes.textField
                      : classes.invisible
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(searchedVal) =>
                    setLocalSearchParams((prevState) => {
                      return {
                        ...prevState,
                        startDate: searchedVal,
                      };
                    })
                  }
                />
                <KeyboardDatePicker
                  inputVariant="outlined"
                  variant="inline"
                  size="small"
                  margin="dense"
                  label={
                    <Typography className={classes.Text}>End Date</Typography>
                  }
                  value={localSearchParams.endDate}
                  // onChange={this.handleStartDateChange}
                  onError={console.log}
                  format="yyyy-MM-dd"
                  // style={styles.dateTimePicker}
                  style={{ width: 180 }}
                  className={
                    localSearchParams.searchByRange === 2
                      ? classes.textField
                      : classes.invisible
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  onChange={(searchedVal) =>
                    setLocalSearchParams((prevState) => {
                      return {
                        ...prevState,
                        endDate: searchedVal,
                      };
                    })
                  }
                />
                <KeyboardDatePicker
                  inputVariant="outlined"
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  style={{ width: 150 }}
                  format="MM/yyyy"
                  margin="dense"
                  value={localSearchParams.date}
                  label={<Typography className={classes.Text}>Month</Typography>}
                  className={
                    localSearchParams.searchByRange === 1
                      ? classes.textField
                      : classes.invisible
                  }
                  onChange={(searchedVal) =>
                    setLocalSearchParams((prevState) => {
                      return {
                        ...prevState,
                        date: searchedVal,
                      };
                    })
                  }
                />
              </MuiPickersUtilsProvider>
              <div className="wrapperCheckboxes" style={{ marginLeft: "10px" }}>
                <FormGroup row>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          label="Active Trip"
                          checked={localSearchParams.activeTrip}
                          color="primary"
                          onChange={(searchedVal) => {
                            // setLocalSearchParams(prevState => {
                            // return{
                            //     ...prevState,
                            //     activeTrip: searchedVal.target.checked
                            //   }
                            // })
                            let searchParams = {
                              ...localSearchParams,
                              activeTrip: searchedVal.target.checked,
                            };
                            setLocalSearchParams(searchParams);
                            queryTrips(searchParams);
                          }}
                        />
                      }
                      label={
                        <Typography>
                          Active
                        </Typography>
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          label="Exception"
                          checked={localSearchParams.exception}
                          onChange={(searchedVal) => {
                            // setLocalSearchParams(prevState => {
                            // return{
                            //     ...prevState,
                            //     exception: searchedVal.target.checked
                            // }
                            //})
                            let searchParams = {
                              ...localSearchParams,
                              exception: searchedVal.target.checked,
                            };
                            setLocalSearchParams(searchParams);
                            queryTrips(searchParams);
                          }}
                        />
                      }
                      label={
                        <Typography>
                          Exception
                        </Typography>
                      }
                    />
                  </FormControl>
                </FormGroup>
              </div>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      

      {showIndicator && (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          direction="row"
        >
          <CircularProgress />
        </Stack>
      )}
      {trips.length == 0 && !showIndicator && (
        <div
          style={{
            width: "100%",
            display: "inline_block",
            backgroundColor: "#fff3cd",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" style={{ color: "#a9a9a9" }}>
            {"No results found"}
          </Typography>
        </div>
      )}
      <div style={{ display: "block", position: "relative", flex: "1 1 auto", minHeight: "400px" }}>
        <div id="leftPanel">
          {trips.length > 0 && (
            <SideButtons
              accessToken={props.accessToken}
              buttonList={buttons}
              selection={selection}
            />
          )}
        </div>
        <div id="mainPanel">
          {trips.length > 0 && (
            <DataTable
              trips={trips}
              selection={selection}
              setSelection={setSelection}
            />
          )}
        </div>
      </div>
      <footer>
        <Copyright />
      </footer>
    </div>
  );
}

import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import { TabPanel, Item } from 'devextreme-react/tab-panel';

import AddressTab from './addressTab.js';


class MasterDetailView extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.data.data);
    this.renderAddressTab = this.renderAddressTab.bind(this);  
  }

renderFromAddress=() =>{
    let suite = "";
    if(this.props.data.data.sSuite){
        suite = this.props.data.data.sSuite+", ";
    }
    return suite + this.props.data.data.sAddress + " " + this.props.data.data.sQuad
            + ", " + this.props.data.data.sCity+", " + this.props.data.data.sProv + ", " + this.props.data.data.sPostal
            +", "+ this.props.data.data.sCountry        
}

renderToAddress =() =>{
    let suite = "";
    if(this.props.data.data.cSuite){
        suite = this.props.data.data.cSuite+", ";
    }
    return suite+ this.props.data.data.cAddress + " " + this.props.data.data.cQuad
        + ", " + this.props.data.data.cCity + ", " + this.props.data.data.cProv+ ", " + this.props.data.data.cPostal
        + ", " + this.props.data.data.cCountry;
}

  render() {
    return (
      // <TabPanel>
      //   <Item title="Shipper/Consignee Address" render={this.renderAddressTab} />     
      // </TabPanel>
      <Grid container sm={12}>
            <Grid item sm={4}>
                <Typography style={{ fontWeight: 500, fontSize: 15 }}> Shipper </Typography> 
                <Typography style={{ fontWeight: 450 }}>{this.props.data.data.sCompanyName}</Typography>  
                <Typography>{this.renderFromAddress()}</Typography>     
                {(this.props.data.data.sContact !== null && this.props.data.data.sContact !=='') &&  <Typography> Contact: {this.props.data.data.sContact}</Typography> }   
                {(this.props.data.data.sPhone !== null && this.props.data.data.sPhone!=='') && <Typography> Phone: {this.props.data.data.sPhone}</Typography> }                                                                 
            </Grid>
            <Grid item sm={8}>
                <Typography style={{ fontWeight: 500, fontSize: 15 }}> Consignee </Typography> 
                <Typography style={{ fontWeight: 450 }}>{this.props.data.data.cCompanyName}</Typography> 
                <Typography style={{ fontWeight: 400}}>{this.renderToAddress()}</Typography>
                { (this.props.data.data.cContact !== null && this.props.data.data.cContact!=='') &&  <Typography> Contact: {this.props.data.data.cContact}</Typography> }   
                {(this.props.data.data.cPhone !== null && this.props.data.data.cPhone!=='') &&  <Typography> Phone: {this.props.data.data.cPhone}</Typography> }  
            </Grid>
        </Grid>
    );
  }

  renderAddressTab() {
    return <AddressTab data={this.props.data} />;
  }

 
}

export default MasterDetailView;

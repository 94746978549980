import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Copyright from "../Copyright";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import httpRequest from "../HttpService";
import { AppBar, Toolbar } from "@material-ui/core";
import AddressCard from "./components/addressCard";
import CameraButton from "./components/cameraButton";
import TextInput from "../components/inputFields/TextInput";
import Gallery from "./components/gallery";
import moment from "moment";
import InfoField from "./components/infoField";
import logo from "./images/logo_west-direct.jpg";
import fedexLogo from "./images/fedex_logo.png";
import CustomIconButton from "../components/inputFields/CustomIconButton";
import { InputAdornment } from "@material-ui/core";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { Button, ButtonGroup } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "../components/sideIcon/sideIcon.css";
import "../jobDetail/jobDetail.css";
import MapboxPanel from "../components/MapboxPanel";
import StreetViewMap from "../components/streetViewSideBySideMap";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TripFunctionDialog from "./components/tripFunctionDialog";
import TripDriverList from "./components/tripDriverList";
import TripHistoryList from "./components/tripHistoryList";
import TripRatingList from "./components/tripRatingList";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputField from "./components/inputField";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomDialog from "./components/dialog";
import CallerField from "./components/callerField";
import ReferenceField from "./components/referenceFields";
import moment_tz from "moment-timezone";
import NoticeEmitter from "../NoticeUtil";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";
import { Snackbar, Alert } from "@mui/material";

const classes = {
  root: {
    backgroundColor: "transparent",
  },
  drawerList: {
    width: 360,
    padding: 0,
  },
  drawerWrapper: {
    backgroundColor: "transparent",
    top: "64px",
    left: "38px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: 1,
    justifyContent: "space-between",
  },
  toolbarGridItem: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "15px",
  },
  jobDetailLabel: {
    fontSize: 12,
    fontWeight: 500,
  },
};

class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: localStorage.getItem("access_token"),
      jobId: this.props.match.params.jobId,
      galleryVisible: false,
      photoArray: [],
      gpsData: {},
      showContent: 0, // 0-map 1-pic 2-history 3-driver&rating
      view: "map", // street
      addr: "delivery",
      GPSUpdateButtonText: "Update Delivery GPS",
      isMenuOpen: false, //
      viewAddress: {
        companyName: "",
        lat: "",
        long: "",
        address: "",
      },
      category: "View",
      viewFunctions: [],
      dispatchFunctions: [],
      serviceFunctions: [],
      ratingFunctions: [],
      dialogIsOpen: true,
      tripFunction: {
        parameters: [],
      },
      companyLogo: logo,
      TripServiceNoteList: [],
      TripServiceAccountNotes: "",
      accountList: [],
      currentAccount: {},
      isEdit: false,
      rateChoices: [],
      currentRate: {},
      callerList: [],
      updatingItem: {},
      updatingItmeStr: "",
      openUpdatingDialog: false,
      packageList: [],
      referenceList: [],
      updatingReferences: {},
      latestUpdatedPostion: null,
      openSnack: false,
      messageInfo: "",
      interval: null,
    };

    const { jobId } = this.props.match.params;
    this.loadTripDetails(jobId);
    this.getTripFunctions(jobId);

    NoticeEmitter.emit("changeTitle", this.props.match.params.jobId);
  }

  componentDidMount = () => {
    let token = localStorage.getItem("access_token");
    if (token === undefined || token === null) window.location.href = "/";

    NoticeEmitter.addListener("refreshJobDetail", (data) => {
      console.log("==NoticeEmitter refreshJobDetail==" + data);
      this.loadTripDetails(this.state.jobId);
      //window.location.href='/jobDetail/' + this.state.jobId;
    });
  };


 componentWillUnmount() {
    NoticeEmitter.removeListener('refreshJobDetail', () => {});    
  }

  handleView = (event) => {   
    this.setState({ view: this.state.view === "street"? "map":"street" });
  };

  handleAddr = (event) => {
    if (this.state.addr === "pickup") {
      //changing to pickup view
      this.setState({
        viewAddress: {
          companyName: this.state.order?.cCompanyName,
          lat: this.state.order?.cLat,
          long: this.state.order?.cLong,
          address: this.state.order?.cAddress,
        },
      });
      this.setState({ addr: "delivery" });
      this.setState({ view: "map" }, () => this.setState({ view: "street" }));
      this.setState({ GPSUpdateButtonText: "Update Delivery GPS" });
    } else {
      //changing to delivery
      this.setState({
        viewAddress: {
          companyName: this.state.order?.ShipperName,
          lat: this.state.order?.sLat,
          long: this.state.order?.sLong,
          address: this.state.order?.sAddress,
        },
      });

      this.setState({ addr: "pickup" });
      this.setState({ view: "map" }, () => this.setState({ view: "street" }));
      this.setState({ GPSUpdateButtonText: "Update Shipper GPS" });
    }
  };

  handlerEditClick = () => {
    this.setState({ isEdit: true });
    this.getUserEmails();
    this.getPackageList();
    this.getReferenceList();
    this.setState({ updatingItem: {} });
    this.setState({ updatingReferences: {} });
    //this.getRateChoices();
  };

  getRateChoices = () => {
    httpRequest.getRateChoice(this.state.accessToken, this.state.jobId).then((response) => {
        console.log("getRateChoices  " + JSON.stringify(response.data));
        try {
          const newRows = [];
          response.data.map((item) => {
            newRows.push({
              ...item,
              id: newRows.length,
              service: item.ser_area + item.ser_time,
            });
          });
          this.setState({ rateChoices: newRows });
          var currentRate = newRows.find((obj) => {
            return (
              obj.ser_area === this.state.order.SER_AREA && obj.ser_time === this.state.order.SER_TIME
            );
          });
          console.log("getRateChoices currentRate is " + JSON.stringify(currentRate));
          if (currentRate !== undefined)
            this.setState({ currentRate: currentRate });
        } catch (e) {
          console.log("getRateChoices exception-" + e);
        }
      });
  };

  getUserEmails = () => {
    httpRequest.getUserEmails(this.state.order.ACCOUNT).then((response) => {
      this.setState({ callerList: response.data });
    });
  };

  removeCallerFromList = (caller) => {
    let nameList = this.state.callerList.filter(function (obj) {
      return obj.username !== caller;
    });
    this.setState({ callerList: nameList });
  };


  getPackageList = () => {
    httpRequest.getTripPackageDescriptions(this.state.accessToken, this.state.jobId).then((response) => {
        this.setState({ packageList: response.data });
      });
  };

  getGPSTracking = () => {
    httpRequest.getJobGPS(this.state.jobId).then((response) => {
      this.setState({ gpsData: response.data });
      if (response.data.TripLocation.includes("Delivered")) {
        if (this.state.interval !== null) {
          clearInterval(this.state.interval);
          this.state({ interval: null });
        }
      }
    });
  };

  getReferenceList = () => {
    httpRequest.getReferenceFields(this.state.accessToken, this.state.jobId).then((body) => {
        console.log("getReferenceFields:" + JSON.stringify(body.data));
        this.setState({ referenceList: body.data });
        body.data.forEach((item) => {
          if (item.typeDesc === "DropDown")
            this.getReferenceDropDown(item.refID);
        });
      });
  };

  getReferenceDropDown = (id) => {
    httpRequest.getReferenceDropDown(this.state.accessToken, id).then((response) => {
        console.log("getReferenceDropDown:" + JSON.stringify(response.data));
        let refList = this.state.referenceList;
        var objIndex = refList.findIndex((obj) => obj.refID == id);
        refList[objIndex].ddValues = response.data;
        this.setState({ referenceList: refList });
      });
  };

  loadTripDetails = (jobId) => {
    this.setState({ jobId: jobId });
    this.getTripServiceNotes(jobId);

    httpRequest.getTrip(this.state.accessToken, jobId).then((response) => {
      console.log(JSON.stringify(response.data));
      var data = response.data;
      try {        
        data.dtCallIn_display = data.dtCallIn.toString().substring(0, 19).replace("T", " ");
        data.dtDCT_display = data.dtDCT.toString().substring(0, 19).replace("T", " ");

        if (data.dtDelivered) 
          data.dtDelivered_display = data.dtDelivered.toString().substring(0, 19).replace("T", " ");    

        if (data.eta) {
          // var etaUTC = moment.utc(data.eta).toDate();
          // data.eta = moment(etaUTC).format('YYYY-MM-DD HH:mm');
          data.eta_display = data.eta.toString().substring(0, 19).replace("T", " ");
        }
        if (data.URL)
          data.FedexTrackNum = data.URL.substring(data.URL.length - 12);

        var images = JSON.parse(data.ImageURLs);
        if (images) {
          var imageList = [];
          images.forEach((value) => {
            //value.savedTimeUTC = moment(value.savedTimeUTC).format('YY-MM-DD HH:mm');
            value.savedTimeUTC = new Date(moment(value.savedTimeUTC).toDate() - new Date().getTimezoneOffset() * 60000); //UTC -- Locatime display
            value.savedTime = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
            value.id = imageList.length;
           
            if (value.url.includes(".doc") || value.url.includes(".docx") || value.url.includes(".xls") || value.url.includes(".xlsx") ||value.url.includes(".csv"))
              value.IsImageOrPDF = false;
            else
              value.IsImageOrPDF = true;

            if (value.url.includes(".doc") || value.url.includes(".docx") ||value.url.includes(".pdf") || value.url.includes(".xls") || value.url.includes(".xlsx") || value.url.includes(".csv"))
              value.IsImage = false;
            else 
              value.IsImage = true;

            if (value.url.includes(".doc") || value.url.includes(".docx"))
              value.fileType = "doc";
            else if (value.url.includes(".xls") || value.url.includes(".xlsx"))
              value.fileType = "xls";
            else if (value.url.includes(".pdf")) value.fileType = "pdf";
            else if (value.url.includes(".csv")) value.fileType = "csv";
            else value.fileType = "image";

            let fileExt = value.url.split(".").pop();
            value.fileExt = fileExt.includes("net/") ? "png" : fileExt;
            imageList.push(value);
          });
          this.setState({ photoArray: imageList });
        }

        this.setState({order: data, jobId: data.jobID});

        this.setState({
          viewAddress: {
            companyName: data.cCompanyName,
            lat: data.cLat,
            long: data.cLong,
            address: data.cAddress,
          },
        });
        this.setState({ addr: "delivery" });
        this.setState({ GPSUpdateButtonText: "Update Delivery GPS" });

        var a = moment_tz.tz(data.dtCallIn_display, "Canada/Mountain").toDate();
        
        this.setState({ orderDTCallIn: moment(a).format() });
        var dct = moment_tz.tz(data.dtDCT_display, "Canada/Mountain").toDate();
        this.setState({ orderDTDCT: moment(dct).format() });
        if (data.eta) {
          // var eta = moment_tz.tz(data.eta_display, "Canada/Mountain").toDate() - 3*60*60000;
          var eta = moment_tz.tz(data.eta_display, "Canada/Mountain").toDate();
          this.setState({ orderSCT: eta });
        }

        if (data.logo.data) {
          var binary = "";
          var bytes = new Uint8Array(data.logo.data);
          var len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          const base64String = btoa(binary);
          const imageSrc = "data:image/png;base64," + base64String;
          this.setState({ companyLogo: imageSrc });
        }

        this.getGPSTracking();

        this.getTripAccountList(data.jobID, data.ACCOUNT);
        httpRequest.getOrderTrack(jobId).then((response) => {
          try {
            //this.setState({steps: response.data});
            const tempTrackList = [];
            response.data.forEach((option) => {
              tempTrackList.push({
                ...option,
                id: tempTrackList.length,
              });
            });
            this.setState({ steps: tempTrackList });
          } catch (e) {
            console.log("getOrderTrack exception:" + e);
          }
        });
        this.setState({ isUploadFile: false });

        if (data.Status < 8) {
          if (this.state.interval === null) {
            let interval = setInterval(() => {this.getGPSTracking();}, 30000);
            this.setState({ interval: interval });
          }
        } else {
          if (this.state.interval !== null) {
            clearInterval(this.state.interval);
            this.setState({ interval: null });
          }
        }

        setTimeout(() => {this.getRateChoices();}, 2000);
      } catch (err) {
        alert("Order not found. Please try again");
      }
    });
  };

  rendAccountServiceNotes = () => {
    return this.state.TripServiceNoteList.map((item) => (
      <Alert
        variant="filled"
        severity="info"
        className={"fieldJobNote"}
        icon={false}
      >
        <div className={"speechBubble"}>{item.source}</div>
        <div className={"notes"}>{item.notes}</div>
      </Alert>
    ));
  };

  updateOrderFields = (jobId) => {
    try {
      let updateObj = this.state.updatingItem;
      updateObj.jobID = jobId;
      if (updateObj.dtCallIn)
        updateObj.dtCallIn = moment(updateObj.dtCallIn).toDate() - new Date().getTimezoneOffset() * 60000;
      if (updateObj.dtDCT)
        updateObj.dtDCT = moment(updateObj.dtDCT).toDate() - new Date().getTimezoneOffset() * 60000;
      if (updateObj.sct)
        updateObj.sct = moment(updateObj.sct).toDate() - new Date().getTimezoneOffset() * 60000;

      if (updateObj.RATE) {
        updateObj.ser_area = updateObj.RATE.ser_area;
        updateObj.ser_time = updateObj.RATE.ser_time;

        let _order = this.state.order;
        _order.SER_AREA = this.state.updatingItem.RATE.ser_area;
        _order.SER_TIME = this.state.updatingItem.RATE.ser_time;
        _order.RATE = this.state.updatingItem.RATE.Rate;
        this.setState({ order: _order });
      }
      /*
        if(this.state.updatingItem.sct) {
            this.setState(updatingItem => {
                updatingItem.sct = moment(updatingItem.sct).toDate() - new Date().getTimezoneOffset() * 60000
                return updatingItem
           })
        }
        */
      if (updateObj.hasOwnProperty("del_inst")) {
        if (updateObj.del_inst.length === 0) updateObj.del_inst = "  ";
      }

      console.log("updateJobFields- " + JSON.stringify(updateObj));
      httpRequest.updateJobFields(this.state.accessToken, updateObj).then((body) => {
          console.log("updateJobFields- " + JSON.stringify(body.data));
          if(updateObj.hasOwnProperty("caller")){
            var callers = this.state.callerList.find(function (obj) {
              return obj.username === updateObj.caller;
            });
            console.log("updateObj has caller ? -" +JSON.stringify(callers));
            if(callers === undefined){
              httpRequest.postUserEmails(this.state.order.ACCOUNT, updateObj.caller).then(async (res) => {
                console.log("postUserEmails-" +JSON.stringify(res.data));
                //this.getUserEmails();
              });
            }
          }

          this.postUpdatedReferences(jobId);
          httpRequest.updateJobRateFields(this.state.accessToken, updateObj).then((body) => {
                console.log("updateJobRateFields- " + JSON.stringify(body.data));
                this.setState({ openUpdatingDialog: true });
              },
              function errorCallback(err) {
                console.log("Failed to update Job rate field: " + err);
              }
            );
        },
        function errorCallback(err) {
          console.log("Failed to update job field: " + err);
        }
      );
    } catch (e) {
      console.log("updateOrderFields exception" + e);
    }
  };

  postUpdatedReferences = (jobID) => {
    try {
      if (JSON.stringify(this.state.updatingReferences) !== "{}") {
        for (let key in this.state.updatingReferences) {
          if (this.state.updatingReferences.hasOwnProperty(key)) {
            var reference = {
              refID: key,
              value: this.state.updatingReferences[key],
            };
            console.log("postUpdatedReferences- " + JSON.stringify(reference));
            httpRequest.postReferenceFields(this.state.accessToken, jobID, reference).then((body) => {
                  console.log("postReferenceFields- " + JSON.stringify(body.data));
                },
                function errorCallback(err) {
                  alert("Failed to update reference editTripService field.");
                }
              );
          }
        }
      }
    } catch (e) {
      console.log("postUpdatedReferences exception" + e);
    }
  };

  /* [{"account":"9905","AccountName":"DIRECTIT SERVICE FAIL."},{"account":"9910","AccountName":"DirectIT Group"},{"account":"M05102","AccountName":"C.R. Wall & Co. Inc."}] */
  getTripAccountList = (jobId, account) => {
    httpRequest.getTripAccountList(this.state.accessToken, jobId).then((body) => {
        try {
          console.log("getTripAccountList account is " + account);
          console.log("getTripAccountList:" + JSON.stringify(body.data));
          this.setState({ accountList: body.data });          
          var currentAcct = {};
          currentAcct = body.data.find(function (obj) {return obj.account === account.trim();}); 
          console.log("getTripAccountList currentAccount is " + JSON.stringify(currentAcct))
          if(currentAcct !== undefined && currentAcct !== null)
             this.setState({ currentAccount: currentAcct });
        } catch (e) {
          console.log("getTripAccountList exception:" + e);
        }
      });
  };

  getTripServiceNotes = (jobId) => {
    httpRequest.getTripServiceNotes(this.state.accessToken, jobId).then((body) => {
        try {
          //console.log("getTripServiceNotes:" + JSON.stringify(body.data))
          let tripServiceNoteList = [];
          if (body.data.length > 0) {
            let tripServiceAccountNotes = "";
            body.data.forEach((row) => {
              if (row.source?.toLowerCase().includes("account") && row.notes)
                tripServiceAccountNotes += "AccountInfo:" + row.notes + ";";
              else if (row.notes !== null && row.notes !== "")
                tripServiceNoteList.push(row);
            });
            this.setState({ TripServiceNoteList: tripServiceNoteList });
            this.setState({ TripServiceAccountNotes: tripServiceAccountNotes });
          }
        } catch (e) {
          console.log("getTripServiceNotes exception:" + e);
        }
      });
  };

  getTripFunctions = (jobId) => {
    httpRequest.getTripFunctions(this.state.accessToken, jobId).then((response) => {
        try {
          //console.log("getTripFunctions:" + JSON.stringify(response.data))
          let viewFunctions = [];
          let dispatchFunctions = [];
          let serviceFunctions = [];
          let ratingFunctions = [];

          //console.log(JSON.stringify(response.data));
          response = response.data;
          for (const x in response[1][0]) {
            let row = { functionText: x };
            if (x !== "rating") {
              if (x === "Drivers") {
                row.functionText = "Drivers&rating";
              }
              viewFunctions.push(row);
            }
          }
          console.log(viewFunctions);

          response[2].forEach((row) => {
            // console.log(row);
            if (row.section.includes("Dispatch")) {
              dispatchFunctions.push(row);
            }
            if (row.section.includes("Service")) {
              serviceFunctions.push(row);
            }
            if (row.section.includes("Rating")) {
              ratingFunctions.push(row);
            }
          });

          this.setState({
            viewFunctions: viewFunctions,
            dispatchFunctions: dispatchFunctions,
            serviceFunctions: serviceFunctions,
            ratingFunctions: ratingFunctions,
          });
        } catch (e) {
          console.log("getTripFunctions exception:" + e);
        }
      });
  };

  toggleGallery = (visible) => {
    this.setState({ galleryVisible: visible });
    if (!visible && this.state.isUploadFile)
      this.loadTripDetails(this.state.jobId);
  };

  setUploadFileFlag = () => {
    this.setState({ isUploadFile: true });
  };

  renderStepContentText(label) {
    const str = " Click Here to Track >>> ";
    return (
      <div>
        <Typography>{label.bigText}</Typography>
        <Typography variant="caption">
          {label.smallText + moment(label.timestamp_utc).format("MM-DD") + " @ " + moment(label.timestamp_utc).format("LT")};
        </Typography>
        {label.img_url && (
          <span><a onClick={() => {window.open(label.img_http_link, "_blank");}}>{" "}{label.img_text}{" "}</a>
            <div>
              {" "}
              <img style={{ width: "80px", height: "60px" }} src={label.img_url} onClick={() => {window.open(label.img_http_link, "_blank");}}/>
              {" "}
            </div>
          </span>
        )}
      </div>
    );
  }

  viewFedexTrack = () => {
    window.open(this.state.order.URL, "_blank");
  };

  updateAccountInfo = (event) => {
    console.log(event.target.value);
  };

  updateNotes = (event) => {
    console.log(event.target.value);
  };

  updateGallery = (event) => {
    console.log(event.target.value);
  };

  currentDateTime = () => {
    var current = new Date();
    return new Date(
      current.getFullYear(),
      current.getMonth(),
      current.getDate(),
      current.getHours(),
      current.getMinutes()
    );
  };

  handleMenu = (category) => {
    //console.log(category);
    this.setState({isMenuOpen: true, category: category,});
  };

  performTripFunction = (action, category) => {
    //console.log("performTripFunction action-" + JSON.stringify(action));
    //console.log("performTripFunction category-" + category);
    try {
      let menuOptions = [];
      if (category === "View") {
        menuOptions = this.state.viewFunctions;
      }
      if (category === "Dispatch") {
        menuOptions = this.state.dispatchFunctions;
      }
      if (category === "Service") {
        menuOptions = this.state.serviceFunctions;
      }
      if (category === "Rating") {
        menuOptions = this.state.ratingFunctions;
      }
      if (category !== "View") {
        menuOptions.forEach((row) => {
          if (row.functionText === action.functionText) {
            this.processFunction(row);
          }
        });
      } else {
        if (action.functionText === "map") {
          this.setState({ showContent: 0 });
        } else if (action.functionText === "pictures") {
          this.setState({ showContent: 1 });
        } else if (action.functionText === "History") {
          this.setState({ showContent: 2 });
        } else {
          this.setState({ showContent: 3 });
        }
        this.setState({ isMenuOpen: false });
      }
    } catch (e) {
      console.log("performTripFunction exception:" + e);
    }
  };

  processFunction = (row) => {
    //console.log( " processFunction row= " + JSON.stringify(row));
    let functionParamsList = [];
    if (row.paramaters !== null && row.paramaters.length !== 0) {
      row.paramaters = row.paramaters.split("[").join("{");
      row.paramaters = row.paramaters.split("]").join("}");
      row.paramaters = row.paramaters.replace('"values":{', '"values":[');
      row.paramaters = row.paramaters.replace('},"default"', '],"default"');
      var obj = JSON.parse(row.paramaters);
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          //console.log(key + " = " + JSON.stringify(obj[key]));
          var funcObj = obj[key];
          if (funcObj.type === "dropdown") {
            //console.log(funcObj.values);
            functionParamsList.push({
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              default: funcObj.default,
              length: funcObj.length,
              mo: funcObj.mo,
              list: funcObj.values,
              value: "",
            });
          } else if (funcObj.type === "datetime") {
            functionParamsList.push({
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              length: funcObj.length,
              mo: funcObj.mo,
              date_val: this.currentDateTime(),
              time_val: this.currentDateTime(),
              value: this.currentDateTime(),
            });
          } else if (funcObj.type === "decimal") {
            functionParamsList.push({
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              length: funcObj.length,
              mo: funcObj.mo,
              value: "",
              precision: funcObj.precision,
            });
          } else if (funcObj.type === "checkbox") {
            functionParamsList.push({
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              length: funcObj.length,
              default: funcObj.default ? funcObj.default : true,
              mo: funcObj.mo,
              value: funcObj.default ? funcObj.default : true,
            });
          } else {
            functionParamsList.push({
              label: key,
              prompt: funcObj.prompt,
              type: funcObj.type,
              length: funcObj.length,
              mo: funcObj.mo,
              value: "",
            });
          }
        }
      }
    }
    if (functionParamsList.length > 0) {
      this.postFunctionParameters(
        functionParamsList,
        row.procedureName,
        row.hasOverride,
        row.RefreshAfter,
        row.functionText
      );
    } else {
      if (row.procedureName === null) alert("No procedure for this function!");
      else {
        httpRequest.doTripFunction(this.state.accessToken, this.state.jobId, [], row.procedureName).then(async (body) => {
              //alert("procedure is done!");
              this.setState({ messageInfo: row.procedureName + " procedure is done!",});
              this.setState({ openSnack: true });
              if (row.RefreshAfter) {
                this.loadTripDetails(this.state.jobId);
                if (this.state.category === "Rating")
                  NoticeEmitter.emit("refreshRating", "");
              } else {
                this.getTripFunctions(this.state.jobId);
              }            
            },
            function errorCallback(err) {
              alert("Failed to finish procedure"+ row.procedureName +". Error is : " + err);
            }
          );
      }
    }
  };

  postFunctionParameters = (paramsList,procedureName, isOverride, refreshAfter, functionText) => {
    this.setState({
      tripFunction: {
        parameters: paramsList,
        procedureName: procedureName,
        isOverride: isOverride,
        refreshAfter: refreshAfter,
        jobId: this.state.jobId,
        accessToken: this.state.accessToken,
        functionText:functionText
      },
    });
    this.setState({ dialogIsOpen: true });
  };

  renderSideMenu = () => {
    return (
      <div class="side-icons">
        <button  class="btn btn-warning slide-icon" onClick={() => this.handleMenu("View")} onMouseOver={() => this.handleMenu("View")}>
          <i class="fa fa-eye" aria-hidden="true"></i>
          <span>View</span>
        </button>
        <button  class="btn btn-danger slide-icon" onClick={() => this.handleMenu("Dispatch")} onMouseOver={() => this.handleMenu("Dispatch")}>
          <i class="fa fa-truck" aria-hidden="true"></i>
          <span>Dispatch</span>
        </button>
        <button class="btn btn-success slide-icon" onClick={() => this.handleMenu("Service")} onMouseOver={() => this.handleMenu("Service")}>
          <i class="fa fa-wrench" aria-hidden="true"></i>
          <span>Service</span>
        </button>
        <button class="btn btn-primary slide-icon"  onClick={() => this.handleMenu("Rating")} onMouseOver={() => this.handleMenu("Rating")}>
          <i class="fa fa-sliders" aria-hidden="true"></i>
          <span>Rating</span>
        </button>
      </div>
    );
  };

  renderMenu = (category) => {
    //use category input to dynamically change the list items
    let menuOptions = [];
    if (category === "View") {menuOptions = this.state.viewFunctions;}
    if (category === "Dispatch") {menuOptions = this.state.dispatchFunctions;}
    if (category === "Service") {menuOptions = this.state.serviceFunctions;}
    if (category === "Rating") {menuOptions = this.state.ratingFunctions;}
    return (
      <Drawer
        style={classes.drawerWrapper}  className={"transparentDrawer"}
        //onMouseLeave = {() => this.setState({isMenuOpen: false})}
        elevation={0}  anchor="left"  hideBackdrop="true" variant="persistent"  open={this.state.isMenuOpen}  onClose={() => this.setState({ isMenuOpen: false })}>
        <div  style={classes.drawerList} className={`${category} drawerList`} onMouseLeave={() => this.setState({ isMenuOpen: false })}>
          {/*<div style={classes.drawerList} className={`${category} drawerList`}>*/}
          <div style={classes.drawerHeader} className={"drawerHeader"}>
            <Typography variant="h5" width="70%">{category}</Typography>
            <IconButton aria-label="delete"> <CloseIcon onClick={() => this.setState({ isMenuOpen: false })}  onMouseOver={() => this.setState({ isMenuOpen: false })}/></IconButton>
          </div>
          <Card elevation={0}>
            <List dense={true} disablePadding={true}>
              {menuOptions.map((action) => (
                <div className={"listItemWrapper"}>
                  <ListItem button key={action.functionText} divider={true} onClick={() => this.performTripFunction(action, category)}>
                    <ListItemText  primary={action.functionText} style={{ textTransform: "capitalize" }}/>
                  </ListItem>
                </div>
              ))}
            </List>
          </Card>
        </div>
      </Drawer>
    );
  };

  setUpdatedRefValue = (name, value) => {
    console.log(name + " ******setUpdatedRefValue *****" + value);
    this.setState({
      updatingReferences: {
        ...this.state.updatingReferences,
        [name]: value,
      },
    });
  };


  handleValueChange = (event) => {
    console.log(event.target.name + "-handleValueChange-" + event.target.value);
    //var obj = this.state.updatingItem;  obj[event.target.name]= event.target.value;   this.setState({updatingItem: obj});
    
    if (event.target.value === "") {
      this.setState({
        updatingItem: {
          ...this.state.updatingItem,
          [event.target.name]: " ",
        },
      });
    } else {
      this.setState({
        updatingItem: {
          ...this.state.updatingItem,
          [event.target.name]: event.target.value,
        },
      });
    }     
  };
  

  handleGPSChange = (lat, lng, source) => {
    console.log("handleGPSChange-" + lat + "----" + lng);
    this.setState({
      latestUpdatedPostion: {lat: lat, lng: lng,},
    });
    this.setState({
      updateGPSSource: source,
    });
    //console.log("handleGPSChange-" + JSON.stringify(this.state.latestUpdatedPostion));
  };

  updateGPS = () => {
    //console.log( this.state.addr +  "====updateGPS======" + JSON.stringify(this.state.latestUpdatedPostion));
    var paramsList = [];
    paramsList.push({
      label: "lat",
      prompt: "Latitude",
      type: "decimal",
      mo: "Latitude",
      precision: "6",
      value: this.state.latestUpdatedPostion.lat.toFixed(6),
    });
    paramsList.push({
      label: "long",
      prompt: "Longitude",
      type: "decimal",
      mo: "Longitude",
      precision: "6",
      value: this.state.latestUpdatedPostion.lng.toFixed(6),
    });
    var spName = this.state.addr.includes("delivery")? "in5.trip_updateConGPS" : "in5.trip_updateShipGPS";
    httpRequest.doTripFunction(this.state.accessToken, this.state.jobId,paramsList,spName).then(async (body) => {
          console.log("doTripFunction-" + body.data);
        },
        function errorCallback(err) {
          alert("Failed to finish trip_assign procedure. Error is : " + err);
        }
    );
    if (this.state.addr.includes("delivery")) {
      this.state.order.cLat = this.state.latestUpdatedPostion.lat.toFixed(6);
      this.state.order.cLong = this.state.latestUpdatedPostion.lng.toFixed(6);
    } else {
      this.state.order.sLat = this.state.latestUpdatedPostion.lat.toFixed(6);
      this.state.order.sLong = this.state.latestUpdatedPostion.lng.toFixed(6);
    }
  };

  updateOrder = () => {
    try {
      console.log("======updateOrder- " + JSON.stringify(this.state.updatingItem));
      if(this.state.updatingItem.hasOwnProperty("caller") && this.state.updatingItem.caller === ''){
        this.setState({ updatingItmeStr: "Please input caller!"});
        this.setState({ openUpdatingDialog: true });
        return;
      }
      this.setState({ isEdit: false });
      let keyArry = Object.keys(this.state.updatingItem);
      let info = "";
      if (keyArry.length > 0) {
        keyArry.map((item) => {
          if (item === "del_inst") info += "Instruction,";
          else if (item === "pieces") info += "Piece number,";
          else if (item === "emailConf") info += "Email,";
          else if (item === "sigReq") info += "No Signature Required,";
          else if (item === "dtCallIn") info += "Call In,";
          else if (item === "dtDCT") info += "DCT,";
          else if (item === "Desc") info += "Package Type,";
          else info += item + ",";
        });
      }
      if (JSON.stringify(this.state.updatingReferences) !== "{}")
        info += "Reference";
      //console.log("----------------------updateOrder- " + info);
      this.setState({ updatingItmeStr: "updated items:" + info });
      //this.setState({openUpdateingDialog: true});
      this.updateOrderFields(this.state.jobId);
    } catch (e) {
      console.log("updateOrder exception" + e);
    }
  };

  handlePiecesChange = (event) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    if (event.target.value.toString().length > 3) {
      let temp = event.target.value.toString().substring(0, 3);
      event.target.value = temp;
    }
  };

  handleMaxLengthFiveChange = (event) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    if (event.target.value.toString().length > 5) {
      let temp = event.target.value.toString().substring(0, 5);
      event.target.value = temp;
    }
  };

  handleMaxLength12Change = (event) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    if (event.target.value.toString().length > 12) {
      let temp = event.target.value.toString().substring(0, 12);
      event.target.value = temp;
    }
  };

  renderToolbarGridItem = (title, label, lgSize) => {
    return(
       <Grid item sm={6} lg={lgSize} style={classes.toolbarGridItem}>
        <Typography variant="body2" color="textSecondary"> {title} </Typography>
        <Typography variant="body1" color="textPrimary"> {label} </Typography>   
      </Grid>
    )
  };

  render() {
    return (
      <>
        <div className="root">
          <TripFunctionDialog  tripFunction={this.state.tripFunction}  open={this.state.dialogIsOpen} handleDialogClose={() => this.setState({ dialogIsOpen: false })}/>
          <AppBar  position="static"  elevation={0}  style={{backgroundClor: "#0D191E", overflow: "auto", paddingLeft: "38px",marginTop: "1px"}}>
            <Toolbar  className={"subheaderJobDetail"} disableGutters={true} style={{ alignItems: "stretch", padding: "10px" }}>
              {this.state.order && (
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0} style={{ width: "100%", backgroundColor: "#0D191E" }}>
                  <Grid  container item  xs={12} sm={2} md={2} lg={1}  style={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                    <div style={{ padding: "10px" }}>
                      <img src={this.state.companyLogo}  style={{maxWidth: "180px", width: "100%", height: "auto", display: "block", borderRadius: "8px",}}/>
                    </div>
                  </Grid>
                  <Grid  container spacing={0} item xs={12} sm={10} md={10} lg={11}
                    style={{
                      // padding:'15px',
                      borderRadius: "6px",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      boxShadow: "0px 0px 10px #000000",
                      borderColor:
                        this.state.order.exception !== null && this.state.order.exception && //red
                        this.state.order.Status ? "#DE3562" : //purple
                          this.state.order.Status < 8 ? "#4331A4" : // green
                          this.state.order.Status >= 8 && this.state.order.Status < 32 ? "#007E82" : // orange
                          this.state.order.Status == 32 ? "#F78133" : // gray
                            "#485054",
                      // backgroundColor: "transparent",
                      backgroundColor:
                        this.state.order.exception !== null && this.state.order.exception && //red
                        this.state.order.Status ? "#DE3562" : //purple
                          this.state.order.Status < 8 ? "#4331A4" : // green
                          this.state.order.Status >= 8 && this.state.order.Status < 32 ? "#007E82" : // orange
                          this.state.order.Status == 32 ? "#F78133" : // gray
                          "#485054",
                    }}
                  >
                    { this.renderToolbarGridItem("Job Id", this.state.jobId, 1)}
                    { this.renderToolbarGridItem("Driver #", this.state.order?.last_driv,1)}
                    { this.renderToolbarGridItem("Ready At", this.state.order?.dtCallInDisplayString,3)}
                    { this.renderToolbarGridItem("DCT", this.state.order?.dtDCTDisplayString,3)}
                    { this.renderToolbarGridItem("Account", this.state.order?.ACCOUNT + " - " + this.state.order?.ShipperName,3)}                  
                    <Grid item sm={6} lg={1} style={classes.toolbarGridItem}>
                      <div   style={{display: "flex",justifyContent: "end", alignItems: "center",flexWrap: "wrap",}}>
                        {!this.state.isEdit && (<IconButton  size="medium"  tooltip="Edit order"   onClick={() => {this.handlerEditClick();}}>
                            <EditIcon></EditIcon>
                          </IconButton>
                        )}
                        {this.state.isEdit && (<IconButton size="medium" tooltip="Save"  onClick={() => this.updateOrder()}>
                            <DoneAllIcon></DoneAllIcon>
                          </IconButton>
                        )}
                        {this.state.isEdit && (<IconButton size="medium"  tooltip="Cancel" onClick={() => this.setState({ isEdit: false })}>
                            <ClearIcon></ClearIcon>
                          </IconButton>
                        )}
                      </div>
                      {/* button */}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Toolbar>
          </AppBar>       
          <Grid  container  direction="row"  justifyContent="flex-end"  alignItems="flex-start"  style={{ paddingLeft: "38px", margin: "0", width: "100%" }}  spacing={1}>
            <Grid  item  container direction="row" justifyContent="center" alignItems="flex-start"  spacing={2} style={{ margin: "0", width: "100%" }} sm={12} lg={9}>
              <>
                <Grid item xs={12} sm={12} md={12}>
                  {this.state.TripServiceNoteList.length > 0 && this.rendAccountServiceNotes()}
                </Grid>
                <Grid  item xs={12} sm={12} md={6} className="shipperCardBlock darkCardBlock">
                  {this.state.order && (
                    <AddressCard
                      jobId={this.state.jobId}
                      type="shipper"
                      accessToken={this.state.accessToken}
                      accountNumber={this.state.order?.ACCOUNT}
                      coord={{
                        lat: this.state.order?.sLat,
                        long: this.state.order?.sLong,
                      }}
                      data={{
                        companyName: this.state.order?.sCompanyName,
                        address: this.state.order?.sAddress,
                        suite: this.state.order?.sSuite,
                        quad: this.state.order?.sQuad,
                        city: this.state.order?.sCity,
                        prov: this.state.order?.sProv,
                        country: this.state.order?.sCountry,
                        postal: this.state.order?.sPostal,
                        contactName: this.state.order?.sContact,
                        contactPhone: this.state.order?.sPhone,
                        contactID: this.state.order?.shipContactID,
                        addressID: this.state.order?.shipAddressID,
                        lat: this.state.order?.sLat,
                        long: this.state.order?.sLong,
                      }}
                    />
                  )}
                </Grid>
                <Grid  item xs={12} sm={12} md={6} className="consigneeCardBlock darkCardBlock">
                  {this.state.order && (
                    <AddressCard
                      jobId={this.state.jobId}
                      type="consignee"
                      accessToken={this.state.accessToken}
                      accountNumber={this.state.order?.ACCOUNT}
                      coord={{
                        lat: this.state.order?.cLat,
                        long: this.state.order?.cLong,
                      }}
                      data={{
                        companyName: this.state.order?.cCompanyName,
                        address: this.state.order?.cAddress,
                        suite: this.state.order?.cSuite,
                        quad: this.state.order?.cQuad,
                        city: this.state.order?.cCity,
                        prov: this.state.order?.cProv,
                        country: this.state.order?.cCountry,
                        postal: this.state.order?.cPostal,
                        contactName: this.state.order?.cContact,
                        contactPhone: this.state.order?.cPhone,
                        contactID: this.state.order?.conContactID,
                        addressID: this.state.order?.conAddressID,
                        lat: this.state.order?.cLat,
                        long: this.state.order?.cLong,
                      }}
                    />
                  )}
                </Grid>              
              </>

              {this.state.view === "street" && this.state.showContent < 2 && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.viewAddress.lat !== "" &&
                      this.state.viewAddress.long !== "" && (
                        <StreetViewMap
                          address={this.state.viewAddress.companyName}
                          lat={this.state.viewAddress.lat}
                          lng={this.state.viewAddress.long}
                          type={this.state.addr}
                          sLat={this.state.order.sLat}
                          sLong={this.state.order.sLong}
                          cLat={this.state.order.cLat}
                          cLong={this.state.order.cLong}
                          images={this.state.photoArray}
                          orderStatus={this.state.order.Status}
                          dtDelivered={this.state.order.dtDelivered}
                          delLat={this.state.order.delLat}
                          delLong={this.state.order.delLong}
                          handleGPSChange={this.handleGPSChange}
                        />
                      )}
                  </Grid>
                </>
              )}

              {this.state.view === "map" && this.state.showContent < 2 && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.order && (
                      <MapboxPanel
                        orderID={this.state.jobId}
                        orderStatus={this.state.order.Status}
                        dtDelivered={this.state.order.dtDelivered}
                        delLat={this.state.order.delLat}
                        delLong={this.state.order.delLong}
                        gpsData={this.state.gpsData}
                        images={this.state.photoArray}
                      />
                    )}
                  </Grid>
                </>
              )}
              {this.state.showContent < 2 && (
                <Grid item md={12} container direction="row" justifyContent="flex-end" alignItems="center">
                  <Typography noWrap variant="button" color="primary" style={classes.jobDetailLabel}>
                    View
                  </Typography>
                  &ensp;
                  <ButtonGroup size="small" variant="outlined" aria-label="outlined button group">
                    <Button onClick={this.handleView} color="primary" disabled={this.state.view === "map"}
                      style={this.state.view === "map"? { color: "#3BA264", borderColor: "#3BA264" }: {}
                      }
                    >
                      {this.state.view === "map" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Map
                    </Button>
                    <Button
                      onClick={this.handleView}
                      color="primary"
                      disabled={this.state.view === "street"}
                      style={this.state.view === "street"? { color: "#3BA264", borderColor: "#3BA264" } : {}}
                    >
                      {this.state.view === "street" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Street
                    </Button>
                  </ButtonGroup>
                  &emsp;
                  <Typography noWrap variant="button" color="primary"   style={classes.jobDetailLabel}>Type</Typography>
                  &ensp;
                  <ButtonGroup  size="small" variant="outlined" aria-label="outlined button group">
                    <Button onClick={this.handleAddr} color="primary" disabled={this.state.addr === "delivery" ||this.state.view === "map"}
                      style={this.state.addr === "delivery" && this.state.view !== "map"? { color: "#3BA264", borderColor: "#3BA264" }: {}}
                    >
                      {this.state.addr === "delivery" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Delivery
                    </Button>
                    <Button
                      onClick={this.handleAddr}
                      color="primary"
                      disabled={ this.state.addr === "pickup" || this.state.view === "map"}
                      style={this.state.addr === "pickup" && this.state.view !== "map" ? { color: "#3BA264", borderColor: "#3BA264" } : {} }
                    >
                      {this.state.addr === "pickup" && (<i class="fa fa-check" aria-hidden="true"></i>)}
                      Pickup
                    </Button>
                  </ButtonGroup>
                  &emsp;
                  {this.state.view === "street" && (
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.setState({ locationUpdateDialog: true });
                      }}
                    >
                      {this.state.GPSUpdateButtonText}
                    </Button>
                  )}
                </Grid>
              )}
              <>
                <Grid item md={12}>
                  <Stepper activeStep={this.state.activeStep} alternativeLabel>
                    {this.state.steps !== undefined &&
                      this.state.steps?.map((label) => (
                        <Step key={label.bigText} completed={label.filled}>
                          <StepLabel>
                            {this.renderStepContentText(label)}
                          </StepLabel>
                        </Step>
                      ))}
                  </Stepper>
                </Grid>
                {this.state.showContent === 2 && (
                  <Grid item md={12}>
                    <TripHistoryList
                      jobId={this.state.jobId}
                      accessToken={this.state.accessToken}
                    ></TripHistoryList>
                  </Grid>
                )}
                {this.state.showContent === 3 && (
                  <Grid item md={12}>
                    <TripDriverList
                      jobId={this.state.jobId}
                      accessToken={this.state.accessToken}
                    ></TripDriverList>
                  </Grid>
                )}
                {this.state.showContent === 3 && (
                  <Grid item md={12}>
                    <TripRatingList
                      jobId={this.state.jobId}
                      accessToken={this.state.accessToken}
                    ></TripRatingList>
                  </Grid>
                )}
              </>
            </Grid>

            <Grid  item container direction="row" justifyContent="flex-end" alignItems="center" sm={12} lg={3} style={{ margin: "0", width: "100%" }} spacing={1} className="darkForm editJobForm">
              <Grid item sm={12} className="imagesCardBlock darkCardBlock">
                <CameraButton images={this.state.photoArray} onChange={this.toggleGallery}/>
              </Grid>
              <Grid item sm={12}>
                {this.state.galleryVisible && this.state.photoArray && (
                  <div className="containerGallery" style={classes.gallery}>
                    <Gallery
                      accessToken={this.state.accessToken}
                      jobId={this.state.jobId}
                      photoArray={this.state.photoArray}
                      setUploadFileFlag={this.setUploadFileFlag}
                    />
                  </div>
                )}
              </Grid>
              {/* Switch account */}
              <Grid item sm={4}>
                <Typography  noWrap variant="button" color="primary" style={classes.jobDetailLabel}>
                  Switch Account
                </Typography>
              </Grid>
              <Grid item sm={8}>
                {!this.state.isEdit && this.state.currentAccount.account && (
                  <InfoField value={this.state.currentAccount.AccountName} />
                )}
                {this.state.currentAccount.account && this.state.isEdit && (
                  <TextField
                    variant="outlined"
                    select
                    size="small"
                    className="editableJobField"
                    defaultValue={this.state.currentAccount.account}
                    onChange={(event) => {
                      console.log("account is " + event.target.value);
                      this.setState({
                        updatingItem: {
                          ...this.state.updatingItem,
                          ["account"]: event.target.value,
                        },
                      });
                    }}
                  >
                    {this.state.accountList.map((item) => (
                      <MenuItem key={item.account} value={item.account}>
                        {item.AccountName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              {/* account info */}
              <Grid item sm={12}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}>
                  Account Info
                </Typography>
                <TextInput  
                  inputType="text"
                  name="account info"
                  multiline
                  minRows={1}
                  hideLabel={true}
                  value={this.state.TripServiceAccountNotes}
                  disabled
                  style={{
                    backgroundColor: "#F78133",
                    borderRadius: "4px",
                    color: "#fff",
                    border: "1px solid #F78133",
                  }}
                />
              </Grid>
              {/* sct callin */}
              <Grid item sm={6}>
                <Typography  variant="button"  color="primary"  style={classes.jobDetailLabel}>
                  SCT
                </Typography>
                {!this.state.isEdit && (
                  <InfoField value={this.state.order?.eta_display} />
                )}
                {this.state.isEdit && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      name="sct"
                      className="editableJobField"
                      fullWidth
                      format="yyyy-MM-dd HH:mm"
                      value={this.state.orderSCT}
                      onChange={(input) => {
                        this.setState({
                          updatingItem: {
                            ...this.state.updatingItem,
                            ["sct"]: input,
                          },
                        });
                        this.setState({ orderSCT: input });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>
              <Grid item sm={6}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}> Callin</Typography>
                {!this.state.isEdit && (
                  <InfoField value={this.state.order?.dtCallIn_display} />
                )}
                {this.state.isEdit && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      name="dtCallIn"
                      className="editableJobField"
                      fullWidth
                      format="yyyy-MM-dd HH:mm"
                      value={this.state.orderDTCallIn}
                      onChange={(input) => {
                        this.setState({
                          updatingItem: {
                            ...this.state.updatingItem,
                            ["dtCallIn"]: input,
                          },
                        });
                        this.setState({ orderDTCallIn: input });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>
              {/* dct delivery service */}
              <Grid item sm={6}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}>
                  DCT
                </Typography>
                {!this.state.isEdit && (
                  <InfoField value={this.state.order?.dtDCT_display} />
                )}
                {this.state.isEdit && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      name="dtDCT"
                      className="editableJobField"
                      fullWidth
                      format="yyyy-MM-dd HH:mm"
                      value={this.state.orderDTDCT}
                      onChange={(input) => {
                        this.setState({
                          updatingItem: {
                            ...this.state.updatingItem,
                            ["dtDCT"]: input,
                          },
                        });
                        this.setState({ orderDTDCT: input });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </Grid>
              <Grid item sm={6}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}>
                  Delivery Service
                </Typography>
                {!this.state.isEdit && (
                  <InfoField
                    value={ this.state.order?.SER_AREA + "- " + this.state.order?.SER_TIME + " [" + this.state.order?.RATE + "]" }
                  />
                )}                
                {this.state.isEdit && (
                  <TextField  name="service"  variant="outlined" select size="small" className="editableJobField"  value={this.state.currentRate.service}
                    onChange={(event) => {
                      let item = this.state.rateChoices.find((o) => o.service === event.target.value);
                      this.setState({ currentRate: item });
                      this.setState({
                        updatingItem: {
                          ...this.state.updatingItem,
                          ["RATE"]: item,
                        },
                      });
                    }}
                  >
                    {this.state.rateChoices.map((item) => (
                      <MenuItem key={item.id} value={item.service}>
                        {item.descr}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              {/* dct delivery service */}
              <Grid item sm={2}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Caller
                </Typography>
              </Grid>
              <Grid item sm={10}>
                {!this.state.isEdit && (
                  <InfoField value={this.state.order?.CALLER} />
                )}
                {this.state.isEdit && (
                  <CallerField
                    caller={this.state.order?.CALLER}
                    callers={this.state.callerList}
                    account = {this.state.order?.ACCOUNT}
                    removeCallerFromList = {this.removeCallerFromList}
                    refreshCallList = {this.getUserEmails}
                    handleValueChange={this.handleValueChange}
                  />
                )}
              </Grid>
              {/* Notes */}
              <Grid item sm={12}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Instructions
                </Typography>
                {!this.state.isEdit && (
                  <TextInput
                    inputType="text"
                    name="notes"
                    multiline
                    minRows={1}
                    hideLabel={true}
                    disabled
                    style={{
                      backgroundColor: "#DE3562",
                      borderRadius: "4px",
                      color: "#fff",
                      border: "1px solid #DE3562",
                    }}
                    value={this.state.order?.DEL_INST}
                  />
                )}
                {this.state.isEdit && (
                  <InputField
                    inputType="text"
                    name="del_inst"
                    inputLength={200}
                    defaultValue={this.state.order?.DEL_INST}
                    onChange={(event) => {
                      const value = event.target.value;
                      event.target.value = value.replace(/[^a-zA-Z0-9-?+$%@&<>|#?,.!<>()/*'\\;: ]/g, "");                
                    }}
                    onBlur={this.handleValueChange}
                  />
                )}
              </Grid>
              {/* status location */}
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Status
                </Typography>
                <InfoField
                  name="status"
                  value={this.state.order?.StatusString}
                />
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Location
                </Typography>
                <InfoField
                  name="location"
                  value={this.state.order?.locationString}
                />
              </Grid>
              {/* distance billing reference */}
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Distance
                </Typography>
                <InfoField
                  name="distance"
                  value={this.state.order?.drivDistance}
                />
              </Grid>
              {!this.state.isEdit && (
                <Grid item sm={6}>
                  <Typography
                    variant="button"
                    color="primary"
                    style={classes.jobDetailLabel}
                  >
                    Billing Reference
                  </Typography>
                  <InfoField
                    name="service"
                    value={this.state.order?.CUST_REF}
                  />
                </Grid>
              )}
              {this.state.isEdit && (
                <ReferenceField
                  referenceList={this.state.referenceList}
                  setUpdatedRefValue={this.setUpdatedRefValue}
                />
              )}
              {/* notification email */}
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Notification Email
                </Typography>
                {!this.state.isEdit && (
                  <InfoField value={this.state.order?.emailConf} />
                )}
                {this.state.isEdit && (
                  <InputField
                    inputType="text"
                    name="emailConf"
                    defaultValue={this.state.order?.emailConf}
                    onBlur={this.handleValueChange}
                  />
                )}
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Package Type
                </Typography>
                {!this.state.isEdit && (
                  <InfoField
                    name="package type"
                    value={this.state.order?.DESC}
                  />
                )}
                {this.state.isEdit && (
                  <TextField
                    name="Desc"
                    variant="outlined"
                    select
                    size="small"
                    className="editableJobField"
                    defaultValue={this.state.order.DESC}
                    onChange={(event) => {
                      this.setState({
                        updatingItem: {
                          ...this.state.updatingItem,
                          ["Desc"]: event.target.value,
                        },
                      });
                    }}
                  >
                    {this.state.packageList.map((item) => (
                      <MenuItem key={item.description} value={item.description}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              {/* pieces weight */}
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Pieces
                </Typography>
                {!this.state.isEdit && (
                  <InfoField
                    value={this.state.order?.Pieces}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ margin: "auto", marginRight: 10 }}
                        >
                          #
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {this.state.isEdit && (
                  <InputField
                    name="pieces"
                    type="number"
                    defaultValue={this.state.order?.Pieces}
                    onBlur={this.handleValueChange}
                    onChange={this.handlePiecesChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ margin: "auto", marginRight: 10 }}
                        >
                          #
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Weight
                </Typography>
                {!this.state.isEdit && (
                  <InfoField
                    value={this.state.order?.weight}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ margin: "auto", marginRight: 5 }}
                        >
                          lbs
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {this.state.isEdit && (
                  <InputField
                    name="weight"
                    type="number"
                    defaultValue={this.state.order?.weight}
                    onBlur={this.handleValueChange}
                    onChange={this.handleMaxLengthFiveChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ margin: "auto", marginRight: 5 }}
                        >
                          lbs
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
              {/* signature required value */}
              <Grid item sm={6}>
                {/* <Typography variant="button" color="primary" style={{fontSize:12, fontWeight: 500}}>Signature Required</Typography> */}
                {!this.state.isEdit && this.state.order?.sigReq!== undefined && (
                  <FormControlLabel
                    control={
                      <Checkbox  color="primary" checked={!this.state.order?.sigReq} />
                    }
                    label={
                      <Typography
                        style={classes.jobDetailLabel}
                        variant="button"
                        color="primary"
                        className={classes.Text}
                      >
                        No Signature Required
                      </Typography>
                    }
                  />
                )}
                {this.state.isEdit && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="sigReq"
                        color="primary" 
                        defaultChecked={!this.state.order?.sigReq}
                        onChange={(val) => {
                          let obj = this.state.updatingItem;
                          obj[val.target.name] = !val.target.checked;
                          this.setState({ updatingItem: obj });
                        }}
                      />
                    }
                    label={
                      <Typography
                        style={classes.jobDetailLabel}
                        variant="button"
                        color="primary"
                        className={classes.Text}
                      >
                        No Signature Required
                      </Typography>
                    }
                  />
                )}
              </Grid>
              <Grid item sm={6}>
                <Typography
                  variant="button"
                  color="primary"
                  style={classes.jobDetailLabel}
                >
                  Value
                </Typography>
                {!this.state.isEdit && (
                  <InfoField
                    value={this.state.order?.DECLARED || 0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ margin: "auto", marginRight: 10 }}
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {this.state.isEdit && (
                  <InputField
                    name="declared"
                    type="number"
                    defaultValue={this.state.order?.DECLARED || 0}
                    onChange={this.handleMaxLengthFiveChange}
                    onBlur={this.handleValueChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ margin: "auto", marginRight: 10 }}
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
              {/* tracking number */}
              <Grid item sm={4}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}>
                  Tracking Number
                </Typography>
              </Grid>
              <Grid item sm={8} style={{ display: "flex", flexShrink: 0 }}>
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0}>
                  <Grid item md={1}>
                    <CustomIconButton
                      icon={"FileCopyOutlinedIcon"}
                      label="Copy"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          "https://webx.directx.ca/TrackNo/" +
                            this.state.order?.TrackNumber
                        )
                      }
                      variant="contained"
                      color="primary"
                      selected={false}
                      rippleDisabled
                    />
                  </Grid>
                  <Grid item md={11}>
                    <InfoField name="tracking number" style={{ marginLeft: 10 }} value={this.state.order?.TrackNumber}/>
                  </Grid>
                </Grid>
              </Grid>
              {/* third party tracking */}
              <Grid item sm={4}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}>
                  Additional Routing
                </Typography>
              </Grid>
              <Grid item sm={8}>
                {!this.state.isEdit && (
                  <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0}>
                    <Grid item md={1}>
                      {this.state.order?.URL && (
                        <CustomIconButton
                          icon={"FileCopyOutlinedIcon"}
                          label="Copy"
                          style={{ marginRight: 10 }}
                          onClick={() =>
                            navigator.clipboard.writeText(this.state.order?.URL)
                          }
                          variant="contained"
                          color="primary"
                          selected={false}
                          rippleDisabled
                        />
                      )}
                    </Grid>
                    <Grid item md={11}>
                      <InfoField value={this.state.order?.URL} style={{ marginLeft: 10 }}/>
                    </Grid>
                  </Grid>
                )}
                {this.state.isEdit && (
                  <InputField
                    inputType="text"
                    name="url"
                    defaultValue={this.state.order?.URL}
                    onBlur={this.handleValueChange}
                  />
                )}              
              </Grid>
              {/* delivered time */}
              <Grid item sm={4}>
                <Typography  variant="button"   color="primary"  style={classes.jobDetailLabel}>
                  Delivered Time
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <InfoField  name="delivered time"  value={this.state.order?.dtDelivered_display}/>
              </Grid>
              {/* proof of delivery */}
              <Grid item sm={4}>
                <Typography variant="button" color="primary" style={classes.jobDetailLabel}>
                  Proof of Delivery
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <InfoField name="proof of delivery" value={this.state.order?.PROOF_DEL}/>
              </Grid>
              <Grid item sm={4}>
                {this.state.order?.priorJobID && (
                  <Button
                    style={{ minHeight: 35, marginLeft: 5 }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.href =
                        "/jobDetail/" + this.state.order.priorJobID;
                    }}
                  >
                    Prev
                  </Button>
                )}
                {this.state.order?.NextJobID && (
                  <Button
                    style={{ minHeight: 35, marginLeft: 5 }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.href =
                        "/jobDetail/" + this.state.order.NextJobID;
                    }}
                  >
                    Next
                  </Button>
                )}
              </Grid>
              <Grid item sm={8}></Grid>
              {/* Signature */}
              <Grid item sm={4}></Grid>
              <Grid item sm={8}>
                <img  src={this.state.order?.signatureURL}  style={{ width: "190px" }}/>
              </Grid>
            </Grid>
          </Grid>
          {this.renderSideMenu()}
          {this.renderMenu(this.state.category)}
          {/* Custom Dialogs */}
          <CustomDialog
            open={this.state.openUpdatingDialog}
            onClose={() => this.setState({ openUpdatingDialog: false })}
            hasCloseBtn={false}
            dialogMessage={this.state.updatingItmeStr}
            dialogTitle="Warning"
            dialogAction={() => {
              this.setState({ openUpdatingDialog: false });
              setTimeout(() => {
                this.loadTripDetails(this.state.jobId);
              }, 1500);
            }}
          />
          <CustomDialog
            open={this.state.locationUpdateDialog}
            onClose={() => this.setState({ locationUpdateDialog: false })}
            hasCloseBtn={true}
            dialogTitle="Warning"
            dialogMessage={
              this.state.updateGPSSource === "dropman"
                ? "Are you sure to update GPS Coordinates?"
                : this.state.updateGPSSource === "photo"
                ? "Are you sure to update GPS Coordinates to the same as photo location?"
                : "Are you sure to update GPS Coordinates to the same as signature location?"
            }
            dialogAction={() => {
              this.setState({ locationUpdateDialog: false });
              if (this.state.latestUpdatedPostion === null) return;
              this.updateGPS();
            }}
          />
          <Snackbar
            open={this.state.openSnack}
            autoHideDuration={1500}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            onClose={() => {
              this.setState({ openSnack: false });
            }}
          >
            <Alert
              onClose={() => {this.setState({ openSnack: false });}}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.messageInfo}
            </Alert>
          </Snackbar>
          <footer>
            <Copyright textColor='white'/>
          </footer>
        </div>
      </>
    );
  }
}

export default withRouter(JobDetail);

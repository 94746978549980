import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { palette } from "../../palette";
import CustomIconButton from "../../components/inputFields/CustomIconButton";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: palette.primary.light,
    textAlign: "center",
  },
  button: {
    margin: 0,
  },
  icon: {
    color: "#715DDC",
    opacity: 1,
    padding: 4,
    width: "30px",
    height: "auto",
  },
}));

export default function CameraButton(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [galleryOpen, setGallery] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openGallery = (event) => {
    props.onChange(!galleryOpen);
    setGallery(!galleryOpen);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <CameraAltIcon className={classes.icon} />
        <Typography
          variant="button"
          color="#D4E0E6"
          style={{
            margin: 4,
            fontSize: 12,
            padding: "0 10px",
            whiteSpace: "nowrap",
          }}
        >
          {props.images ? props.images.length : 0} Images
        </Typography>
        <div>
          <CustomIconButton
            icon={galleryOpen ? "CloseSharpIcon" : "AddOutlinedIcon"}
            tooltip="Browse/Add Photos"
            label="Browse/Add Photos"
            onClick={openGallery}
            variant="contained"
            color="primary"
            selected={galleryOpen}
            rippleDisabled
          />
        </div>
      </CardContent>
    </Card>
  );
}

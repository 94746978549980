import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = (props) => (
    <ReactHelmet>
        <title>{props.title}</title>
    <header>
     <script>
      {(function(w, d, s, l, i) {
       w[l] = w[l] || [];
       w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
       });
       var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
       j.async = true;
       j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
       f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-NQ8TH9D')}
     </script>
    </header>
    {/* <body>
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQ8TH9D" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
    </body> */}
    <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQ8TH9D" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
    </ReactHelmet>
);

export default Helmet;
import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MinIcon from "@material-ui/icons/Remove";
import NoticeEmitter from "../../NoticeUtil";
import httpRequest from "../../HttpService";
// import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Moment from "moment";

const TripDriverList = (props) => {
  const [tripDriverList, setTripDriverList] = useState([]);
  const [tableHeight, setTableHieght] = useState(200);
  const [selectedDriver, setSelectedDriver] = useState();

  useEffect(() => {
    getTripDrivers();
  }, []);

  NoticeEmitter.addListener("refreshJobDetail", (data) => {
    console.log("TripDriverList====NoticeEmitter refreshJobDetail========" +   data);
    setTimeout(function () {
      getTripDrivers();
    }, 500);
  });

  NoticeEmitter.addListener("refreshRating", (data) => {
    console.log("TripDriverList====NoticeEmitter refreshRating==========" +   data);
    setTimeout(function () {
      getTripDrivers();
    }, 500);
  });

  const getTripDrivers = () => {
    var driverList = [];
    httpRequest.getTripDrivers(props.accessToken, props.jobId).then((body) => {
      console.log("getTripDrivers-" + JSON.stringify(body.data));
      if (body.data.length !== 0) {
        body.data.forEach((value) => {
          value.datestampUTC = Moment(value.datestampUTC).format(
            "YY-MM-DD HH:mm"
          );
          value.id = driverList.length;
          value.specialPaid = value.specialPaid?.toFixed(2);
          value.paidOn = value.paidOn?.toFixed(2);
          value.commPaid = value.commPaid?.toFixed(2);
          value.commRate = value.commRate?.toFixed(2);
          value.fuelPaid = value.fuelPaid?.toFixed(2);
          value.TotalPaid = value.TotalPaid?.toFixed(2);
          driverList.push(value);
        });
        setTripDriverList(driverList);
        setTableHieght(180 + 40 * driverList.length);
        setSelectedDriver(driverList[0]);
      }
    });
  };

  const renderSharedCount = (params) => {
    return (
      <Typography variant="body2">
        {" "}
        {params.value} &emsp;
        {/* <AddIcon onClick={handleAddClick}/>
               <MinIcon onClick={handleMinClick}/> */}
        <AddIcon
          onClick={(event) => {
            var content = {
              jobID: parseInt(props.jobId, 10),
              drNum: params.row.drNum,
              shareCount: params.row.shareCount + 1,
            };
            //console.log(" addShareCount: " + JSON.stringify(content));
            httpRequest
              .updateDriverShareCount(props.accessToken, content)
              .then((body) => {
                if (body.data) {
                  console.log("updateDriverShareCount- " + JSON.stringify(body.data));
                }
                setTimeout(function () {
                  getTripDrivers();
                }, 1000);
              });
          }}
        />
        <MinIcon
          onClick={(event) => {
            if (params.row.shareCount === 0) return;
            var content = {
              jobID: parseInt(props.jobId, 10),
              drNum: params.row.drNum,
              shareCount: params.row.shareCount - 1,
            };

            //console.log(" handleMinClick: " + JSON.stringify(content));
            httpRequest
              .updateDriverShareCount(props.accessToken, content)
              .then((body) => {
                if (body.data) {
                  console.log("updateDriverShareCount- " + JSON.stringify(body.data));
                }
                setTimeout(function () {
                  getTripDrivers();
                }, 1000);
              });
          }}
        />
      </Typography>
    );
  };

  const renderDrNum = (params) => {
    return (
      <a style={{ color: "#13ACB1", fontWeight: "600" }}>{params.value} </a>
    );
  };

  // const handleAddClick = () => {
  //     var content = {
  //         jobID: parseInt(props.jobId, 10),
  //         drNum: selectedDriver.drNum,
  //         shareCount: selectedDriver.shareCount +1
  //     };

  //     httpRequest.updateDriverShareCount(props.accessToken, content).then(body =>{
  //         if(body.data) {
  //             console.log("updateDriverShareCount- " + JSON.stringify(body.data));
  //         }
  //         getTripDrivers();
  //     });
  // }

  const columns = [
    {
      field: "drNum",
      headerName: "Driver#",
      width: 120,
      renderCell: renderDrNum,
      headerAlign: 'center',
      align:'center'
    },
    { field: "specialPaid", headerName: "specialPaidOn", width: 180, headerAlign: 'center', align:'center' },
    { field: "paidOn", headerName: "paidOn", width: 150, headerAlign: 'center', align:'center' },
    { field: "commRate", headerName: "commRate", width: 150, headerAlign: 'center', align:'center'},
    { field: "commPaid", headerName: "commPaid", width: 150, headerAlign: 'center', align:'center' },
    { field: "fuelPaid", headerName: "fuelPaid", width: 150, headerAlign: 'center', align:'center' },
    { field: "TotalPaid", headerName: "Total Paid", width: 150, headerAlign: 'center', align:'center'},
    {
      field: "shareCount",
      headerName: "shareCount",
      width: 200,
      renderCell: renderSharedCount,
      headerAlign: 'center', align:'center'
    },
    { field: "datestampUTC", headerName: "date/time", width: 200,headerAlign: 'center', align:'center' },
  ];

  return (
    <div
      className="darkDataGrid"
      style={{ height: tableHeight, width: "100%" }}
    >
      <DataGridPro 
        rows={tripDriverList}
        columns={columns}
        checkboxSelection={false}
        disableColumnSelector={true}
        disableColumnMenu={true}
        hideFooterPagination={true}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = tripDriverList.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedDriver(selectedRowData[0]);
        }}
      />
    </div>
  );
};

export default TripDriverList;


import * as React from 'react';
import pin_red from './imgs/red-circle.png';



function ExceptionPin() {
    return (
        <div style={{display: "flex", justifyContent: "center"}}>
        <img style={{position: "absolute", width:'10px', height:'10px', bottom: 20}}
          src={pin_red}
        />
      </div>
    );
  }


export default React.memo(ExceptionPin);
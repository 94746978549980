import React,{useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AdjustIcon from '@material-ui/icons/Adjust';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import Link from '@material-ui/core/Link';
import { palette } from './palette';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import Select from '@mui/material/Select';
import { useLocation } from 'react-router-dom';
import 'animate.css/animate.css';
import HttpRequest from './HttpService';
import NoticeEmitter from "./NoticeUtil";

import SelectSearch from 'react-select-search';
import "./index.css";
import LoginIcon from "@material-ui/icons/AccountCircle";
//import SearchableSelect from '@dccs/react-searchable-select-mui';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const NavLink = withStyles({
    root: {
        color: (props) => props.selected ? 'white' : 'rgba(255,255,255,.75)',
        backgroundColor: (props) => props.selected ? 'rgba(0,0,0,0.05)' : 'transparent',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '0 10px',
        fontWeight: '600',
        fontSize: '14px',
        textTransform: 'uppercase',
        transition: 'all 0.5s',
        '&:hover': {
            color: 'white',
            backgroundColor:'rgba(0,0,0,0.05)',
            textDecoration: 'none',
        }
    }
  })(Link);

  const MNavLink = withStyles({
    root: {
        color: (props) => props.selected ? 'white' : 'white',
        textDecoration: 'none',
        display: 'block',
        width: '100%',
        '&:hover': {
            color: 'black',
            textDecoration: 'none',
        }
    }
  })(Link);


// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    logoButton: {
        marginRight: '15px',
        fontWeight: 900,
        fontSize:'20px',
        textShadow: '0 0 10px rgba(0,0,0,0.4)',
    },
    title: {
        display: 'block',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems:'center',
        }
    },
    sectionDesktopLG: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems:'center',
        }
    },
    sectionDesktopXL: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            alignItems:'center',
        }
    },
    menuDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems:'stretch',
            alignSelf:'stretch',
        }
    },
    sectionTablet: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        }
    },
    sectionTabletXL: {
        display: 'flex',
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
        display: 'none',
        },
    },
    sectionHiddenXL: {
        display: 'flex',
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
    },
    menuPaperWhite: {
        backgroundColor: "White"
    },
    drawerList: {
        width: 400,
    },
}));

export default function NavigationBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [messagesAnchorEl, setMessagesAnchorEl] = React.useState(false);
    const [jobSearchAnchorEl, setJobSearchAnchorEl] = React.useState(false);
    const [accountAnchorEl, setAccountAnchorEl] = React.useState(false);
    const [jobSearchInput, setJobSearchInput] = React.useState("");
    const [accountInput, setAccountInput] = React.useState("");
    const [companyChoice, setCompanyChoice] = React.useState(props.companyID);
    const [accountChoice, setAccountChoice] = React.useState('');
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const hasMessages = props.messages.length != 0
    const isMessagesOpen = Boolean(messagesAnchorEl) && hasMessages;
    const isJobSearchOpen = Boolean(jobSearchAnchorEl);
    const isAccountOpen = Boolean(accountAnchorEl);

    const companyList = props.companyList;
    const accountList = props.accountList;

    const searchInput = useRef();

    //https://stackoverflow.com/questions/59647940/how-can-i-use-ref-in-textfield
    const valueRef = useRef();

    /*
    // const uniqueNames = [...new Map(props.accountList.map(company => (
    //      [company.companyName, [company.companyID, company.companyName]]
    // ))). values()];

    const uniqueNames = [...new Map(props.accountList.map(company => (
        [company.Shipper, [company.account, company.Shipper]]
    ))). values()];
    */


    //still need to implement job search and account prop logic
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
        console.log(isMobileMenuOpen);
    };

    const handleMessagesOpen = (event) => {
        setMessagesAnchorEl(event.currentTarget);
    }

    const handleMessagesClose = () => {
        setMessagesAnchorEl(false);
    }

    const handleJobSearchOpen = (event) => {
        setJobSearchAnchorEl(event.currentTarget);
    }


    const handleJobSearchSubmit = e => {  //////???????????????
        e.preventDefault();
        console.log(jobSearchInput);
        console.log(companyChoice);
        //props.setJobSearch(jobSearchInput);
        //props.setJobSearchChoice(companyChoice);
        setJobSearchInput("");
        handleJobSearchClose();
        if(jobSearchInput.length === 8){
            HttpRequest.getSearchJobID(props.accessToken, jobSearchInput).then(async function(body){
                console.log("getSearchJobID-" + JSON.stringify(body));
                try{
                    if(body.data && body.data[0].ResultCode && body.data[0].ResultCode === -1){
                         alert("can't find the job.");
                    }
                    else if(body.data && body.data[0].jobID){
                        props.setJobSearch(body.data[0].jobID);
                        window.location.href='/jobDetail/'+ body.data[0].jobID;
                    }
                }
                catch(e){
                    console.log("getSearchJobID exception:" + e);
                }
            });
        }

    }

    const handleJobSearchClose = () => {
        setJobSearchAnchorEl(false);
    }

    const changeJobSearch = (e) => {
        if(e.target.value.length>=4){
            setJobSearchInput(e.target.value);
            getJobDetail(e.target.value);
        }
    }

    const getJobDetail = (jobID) =>{
        HttpRequest.getSearchJobID(props.accessToken, jobID).then(async function(body){
            console.log("getSearchJobID-" + JSON.stringify(body));
            try{
                if(body.data === null || body.data.length ===0){
                    alert("can't find the job.");
                }
                else{
                    if(body.data && body.data[0].ResultCode && body.data[0].ResultCode === -1){
                        alert("can't find the job.");
                    }
                    else if(body.data && body.data[0].jobID){
                        props.setJobSearch(body.data[0].jobID);
                        window.location.href='/jobDetail/'+ body.data[0].jobID;
                    }
                }
            }
            catch(e){
                console.log("getSearchJobID exception:" + e);
            }
        });
    }

    const handleAccountOpen = (event) => {
        setAccountAnchorEl(event.currentTarget);
        console.log(props.accountList);
    }

    const handleAccountSubmit = e => {       
        if(accountChoice !== ""){
            HttpRequest.getAccountWebToken(props.accessToken, accountChoice).then(async function(body){
                console.log("getAccountWebToken-" + JSON.stringify(body.data));
                try{
                    let webURL =  body.data[0].webAddress;
                    webURL = webURL.replace('ship','webx');
                    //webURL = webURL.replace('/CreateOrder','');

                    //console.log("getAccountWebToken webURL-" + webURL);
                    localStorage.setItem('currentAccount',accountChoice);
                    localStorage.setItem('web5URL',webURL);
                    //setTimeout(function(){ window.location.href='/web5'; }, 3000);
                    window.location.href='/web5';
                }
                catch(e){
                    console.log("getAccountWebToken exception:" + e);
                }
             });
        }
        setAccountChoice("");
        valueRef.current.value="";
        handleAccountClose();
    }

    const changeAccount = e => {
        console.log("changeAccount- " + e.target.value);
        if(e.target.value.length >= 4 ){
           setAccountInput(e.target.value);
           setAccountChoice(e.target.value);
       }
    }

    
    const changeAccountSelect_mobile = e => {
        console.log("changeAccountSelect-" + e.target.value);
        if(e.target.value){
           setAccountChoice(e.target.value);
           setAccountInput("");
           valueRef.current.value="";
        }
    }
    

    const changeAccountSelect = (...args) => {
        // searchInput.current.querySelector("input").value = "";
        searchInput.current.value ="";
       // console.log("ARGS:", args);
        setAccountChoice(args[0]);
        setAccountInput("");
        valueRef.current.value="";
    };

    const handleAccountClose = () => {
        setAccountAnchorEl(false);
    }

    const handleFilter = (items) => {
        return (searchValue) => {
          if (searchValue.length === 0) {
            return accountList;
          }
          var updatedItems = items.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
          });
          return updatedItems;
        };
    };


    const pathname = useLocation().pathname;

    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/accountList" selected={pathname == '/accountList'}>
                Account List
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/web5" selected={pathname == '/web5'}>
                Web5
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/driver" selected={pathname == '/driver'}>
                Driver
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/search" selected={pathname == '/search'}>
                Search
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/messaging" selected={pathname == '/messaging'}>
                Messaging
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/service" selected={pathname == '/service'}>
                Service
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/dashBoard" selected={pathname == '/dashBoard'}>
                DashBoard
            </MNavLink>
        </MenuItem>}
        {!props.isLoggedIn && <MenuItem>
            <MNavLink href="/" selected={pathname === '/'}>
                Login
            </MNavLink>
        </MenuItem>}
    </Menu>
  );

  const renderMessages = (
    <Drawer
    anchor = 'right'
    open = {isMessagesOpen}
    onClose = {handleMessagesClose}>
        <div className = {classes.drawerList}>
        <List>
            {props.messages.map(message => (
                <div>
                <ListItem button key = {message}>
                    <ListItemIcon>
                        <AdjustIcon fontSize='small' color='primary'/>
                    </ListItemIcon>
                    <ListItemText primary = {message}/>
                </ListItem>
                <Divider/>
                </div>
            ))}
        </List>
        </div>
    </Drawer>



    /*<Menu dense
    anchorEl={messagesAnchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={isMessagesOpen}
    onClose={handleMessagesClose}
    classes = {{paper: classes.menuPaperWhite}}
    >
        {props.messages.map(message => (
            <MenuItem disablePadding>
                <ListItemIcon>
                    <AdjustIcon fontSize='small' style = {{fill: "red"}}/>
                </ListItemIcon>
                <ListItemText
                disableTypography
                primary={message}/>
            </MenuItem>
        ))}
    </Menu>*/
  );

  const renderJobSearch = (
    <Menu
        anchorEl={jobSearchAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isJobSearchOpen}
        onClose={handleJobSearchClose}
        classes = {{paper: classes.menuPaperWhite}}
    >
        <MenuItem className={'whiteDropDownItem'}>
            <Grid>
                <Grid item xs = {12}>
                    <FormControl fullWidth>
                        <TextField variant = "outlined"
                        size = "small"
                        label = "Job ID"
                        type="number"
                        // onChange = {changeJobSearch}
                        onBlur={changeJobSearch}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <FormControl fullWidth margin = 'normal'>
                        <TextField variant = "outlined"
                        select
                        size = "small"
                        label = "Company"
                        value = {companyChoice}
                        onChange = {event => {
                            console.log('company is ' + event.target.value);
                            setCompanyChoice(event.target.value);
                            props.setCompanyID(event.target.value);
                        }}>
                            {companyList.map(company => (
                                <MenuItem key = {company.companyID} value = {company.companyID}>{company.CompanyName}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <Button variant = "contained"
                            color="primary"
                            style={{width:"100%", display:'flex', justifyContent:'space-between'}}
                            endIcon={<SearchIcon style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>}
                            onClick = {handleJobSearchSubmit}>
                                Search
                    </Button>
                </Grid>
            </Grid>
        </MenuItem>
    </Menu>
  );

  const renderAccount = (
    <Menu dense
    anchorEl={accountAnchorEl}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    keepMounted
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={isAccountOpen}
    onClose={handleAccountClose}
    classes = {{paper: classes.menuPaperWhite}}
    >
        <MenuItem className={'whiteDropDownItem'}>
            <Grid>
                {/*<Grid item xs = {12}>*/}
                {/*    Current Account:*/}
                {/*</Grid>*/}
                <Grid item xs = {12}>
                    <FormControl fullWidth>
                        <TextField variant = "outlined"
                        size = "small"
                        label = "Account #"
                        inputRef={valueRef}
                        onChange = {changeAccount}
                        inputProps={{ maxLength: 6 }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <FormControl fullWidth margin = 'normal'>
                        <TextField                          
                            value={accountChoice}
                            onChange= {changeAccountSelect_mobile}
                            variant="outlined"
                            label="Choose Account"
                            select
                        >
                             {accountList.map(account => (
                                <MenuItem key = {account.account} value = {account.account}>{account.Shipper} - {account.account}</MenuItem>
                            ))}
                        </TextField>   
                        {/* <SelectSearch
                            ref={searchInput}
                            options={accountList}
                            filterOptions={handleFilter}
                            value=  {accountChoice}
                            name="account" placeholder="Choose Account"
                            search
                            onChange={changeAccountSelect}
                            /> */}
                    </FormControl>
                </Grid>
                <Grid item xs = {12}>
                    <Button variant = "contained"
                            color="primary"
                            style={{width:"100%", display:'flex', justifyContent:'space-between'}}
                            endIcon={<AccountCircle style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>}
                            onClick = {handleAccountSubmit}>
                            Switch Account
                    </Button>
                </Grid>
            </Grid>
        </MenuItem>
    </Menu>
  );
    //https://material-ui.com/components/drawers/#swipeable
    const renderMenuDrawer = ( <div/>
    // <Drawer anchor='top' open={drawerOpen} onClose={setDrawerOpen(false)}>
    //     <div
    //     style={{
    //         anchor: 'top',
    //         width: 'auto',
    //     }}
    //     role="presentation"
    //     onClick={setDrawerOpen(false)}
    //     onKeyDown={setDrawerOpen(false)}
    //     >
    //         <List>
    //             <ListItem button key="test">
    //                 <Typography variant="body1">Test</Typography>
    //             </ListItem>
    //         </List>
    //     </div>
    // </Drawer>
    );

    const userUpdate =(companyID) =>{
        HttpRequest.userUpdate(props.accessToken, localStorage.getItem('currentAccount'), companyID, null).then(async function(body){
            console.log(companyID + "-----------userUpdate-" + JSON.stringify(body));
            localStorage.setItem('companyID',companyID.toString());
            NoticeEmitter.emit('currentCompanyID', companyID);
        });
    }

  return (
    <div id="mainHeader" className={classes.grow}>
        <AppBar position="sticky" elevation={0} style={{}}>
            <Toolbar style={{padding: '0 15px'}}>
                <Typography className={classes.logoButton} color="white" align="left">InX</Typography>
                {props.isLoggedIn && <div className={classes.sectionDesktopLG} style={{marginRight:'5px', marginLeft:'0', paddingLeft:'10px', width:'120px', borderLeft:'1px solid rgba(255,255,255,0.1)', alignSelf:'stretch'}}>
                       <TextField  variant = "outlined"   type = "number"  size = "small"   label = "Job ID"  color = "white"  id="outlined-size-normal"                       
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              console.log(" Input jobID is-" + e.target.value);
                              getJobDetail(e.target.value);
                            }
                        }}
                       />
                    </div>}
                {props.isLoggedIn &&<FormControl className={classes.sectionDesktopLG} marginLeft = '10px'>
                     <TextField variant = "outlined"  select  size = "small"   color = "white"  label = "Company" value = {companyChoice}
                        onChange = {event => {
                            console.log('company is ' + event.target.value);
                            setCompanyChoice(event.target.value);
                            props.setCompanyID(event.target.value);
                            userUpdate(event.target.value);

                        }}>
                        {companyList.map(company => (<MenuItem key = {company.companyID} value = {company.companyID}>{company.CompanyName}</MenuItem>))}
                    </TextField>
                </FormControl>}
                {props.isLoggedIn && <div className={classes.sectionDesktopXL} style={{marginLeft:'10px', marginRight:'5px', paddingLeft:'10px', width:'120px', borderLeft:'1px solid rgba(255,255,255,0.1)', alignSelf:'stretch'}}>
                    <TextField variant = "outlined" size = "small"  color = "white"  label = "Account #"  inputRef={valueRef}  onChange = {changeAccount} inputProps={{ maxLength: 6 }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              console.log(" Input account is-" + e.target.value);                             
                              handleAccountSubmit();
                            }
                        }}
                    />
                </div>}
                {props.isLoggedIn && <div className={classes.sectionDesktopXL} style={{width:'240px'}}>
                    <FormControl className={'fieldDropDownIcon'} fullWidth margin = 'none'>                       
                        <SelectSearch
                            ref={searchInput}
                            options={accountList}
                            filterOptions={handleFilter}
                            value=  {accountChoice}
                            name="account" placeholder="Choose Account"
                            search
                            onChange={changeAccountSelect}
                            //onChange={(selected) => console.log(selected)}
                            />
                        <ArrowDropDownIcon style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>
                    </FormControl>
                </div>}
                {props.isLoggedIn && <div className={classes.sectionDesktopXL} style={{marginLeft:'10px', marginRight:'5px', paddingRight:'10px', borderRight:'1px solid rgba(255,255,255,0.1)', alignSelf:'stretch'}}>
                    <IconButton   onClick = {handleAccountSubmit}>   <AccountCircle/>  </IconButton>
                </div>}
              
                <div className={classes.grow} />
                {props.isLoggedIn &&
                <div className={classes.menuDesktop} >
                    <NavLink href="/accountList" selected={pathname == '/accountList'}>
                        Account List
                    </NavLink>
                    {/* <p>&emsp;</p> */}
                    <NavLink href="/web5" selected={pathname == '/web5'}>
                        WebX
                    </NavLink>
                    {/* <p>&emsp;</p> */}
                    <NavLink href="/driver" selected={pathname == '/driver'}>
                        Driver
                    </NavLink>
                    {/* <p>&emsp;</p> */}
                    <NavLink href="/search" selected={pathname == '/search'}>
                        Search
                    </NavLink>
                    {/* <p>&emsp;</p> */}
                    <NavLink href="/dashBoard"  selected={pathname == '/dashBoard'}>
                        Dashboard
                    </NavLink>
                    {/* <p>&emsp;</p> */}
                    <NavLink href="/service"  selected={pathname == '/service'}>
                        Service
                    </NavLink>
                    {/* <p>&emsp;</p> */}
                    <NavLink href="/messageHistory"  selected={pathname == '/messageHistory'}>
                        Message History
                    </NavLink>
                    <NavLink href="/" onClick={props.handlerLogout}>
                        <LogoutIcon fontSize='small' color='white'/>
                    </NavLink>
                </div>}
                {props.isLoggedIn &&
                <div className={classes.sectionTablet}>
                    <IconButton
                    onClick = {handleJobSearchOpen}>
                        <SearchIcon/>
                    </IconButton>
                </div>}
                {props.isLoggedIn &&
                        <div className={classes.sectionTabletXL}>
                    <IconButton
                    onClick = {handleAccountOpen}>
                        <AccountCircle/>
                    </IconButton>
                </div>}
                {props.isLoggedIn &&
                <div className={classes.sectionMobile}>
                    <IconButton
                    aria-controls={mobileMenuId}
                    onClick={handleMobileMenuOpen}
                    >
                        <MoreIcon />
                    </IconButton>
                </div>}               
                {props.isLoggedIn &&
                <IconButton onClick = {handleMessagesOpen}>
                    <Badge badgeContent={props.messages.length} color="secondary">
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
                }
            </Toolbar>
        </AppBar>
        {renderMessages}
        {renderMobileMenu}
        {renderJobSearch}
        {renderAccount}
    </div>
  );
}


//https://stackoverflow.com/questions/59647940/how-can-i-use-ref-in-textfield

//for account list
//https://mui.com/material-ui/react-autocomplete/

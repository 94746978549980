import React, { useState, useEffect } from 'react';
import Copyright from '../Copyright';
import './accountList.css';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { Container} from '@material-ui/core';
import httpRequest from '../HttpService';
import TextField from '@material-ui/core/TextField';
import { DataGrid } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HowToRegIcon from '@mui/icons-material/HowToReg';
//import 'devextreme/dist/css/dx.light.css';
//import DataGrid, {Column,Selection, FilterRow, Paging,Export,ColumnChooser,Pager,SearchPanel} from 'devextreme-react/data-grid';

export default function AccountList(props){  

    let listWithID = props.accountList.map((account, index) => {
        account.id = index;
        return account;
    });           

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [rows, setRows] = useState([]);
    useEffect(() => {

        let token =  localStorage.getItem('access_token');
        if(token === undefined && token === null)
           window.location.href='/';  
           
        if(rows.length === 0){    
            let newRows = props.accountList.map((account, index) => {
                account.id = index;
                return account;
            });           
            setRows(newRows);
        }
    });

    const switchAccount = (account) => {
        console.log("switchAccount-" + JSON.stringify(account));  
        if(account === null ) return;
        httpRequest.getAccountWebToken(props.accessToken, account).then(async function(body){      
            //console.log("getAccountWebToken-" + JSON.stringify(body.data));  
            try{    
                let webURL =  body.data[0].webAddress;
                webURL = webURL.replace('ship','webx');  
                //webURL = webURL.replace('/CreateOrder','');            
                //console.log("getAccountWebToken webURL-" + webURL);  
                localStorage.setItem('currentAccount',account); 
                localStorage.setItem('web5URL',webURL); 
                window.location.href='/web5';  
            }
            catch(e){
                console.log("getAccountWebToken exception:" + e);
            }
        });        

    }


    const requestSearch = (searchedVal, column) => {
        var filteredRows = listWithID;
        if(searchedVal == ''){
            setRows(listWithID);
            return;
        }
        if(column == 'account'){
            filteredRows = listWithID.filter((row) => {
                return row.account.toLowerCase().includes(searchedVal.toLowerCase());
            });
        }else if(column == 'companyName'){
            filteredRows = listWithID.filter((row) => {
                return row.Shipper.toLowerCase().includes(searchedVal.toLowerCase());
            });
        }else{
            filteredRows = listWithID.filter((row) => {
                if(row.phone == null){
                    return false;
                }
                return row.phone.toLowerCase().includes(searchedVal.toLowerCase());
            });
        }
        setRows(filteredRows);
    }
    const columns = [
        { 
            field: 'account', 
            headerName: 'Account', 
            width: 120, 
            renderCell: (params) => (
                <span className='dataVal_account'>
                  {params.value}
                </span>
              )
        },
        {
            field: 'Brand', 
            headerName: 'Brand', 
            width: 110, 
            renderCell: (params) => (
                <span className='dataVal_brand'>
                  {params.value}
                </span>
              )
        },
        {
            field: 'Shipper', 
            headerName: 'Shipper', 
            width: 300, 
            renderCell: (params) => (
                <span className='dataVal_shipper'>
                  {params.value}
                </span>
              )
        },
        {field: 'address', headerName: 'Address', width: 300},
        {field: 'phone', headerName: 'Phone', width: 150},
        {field: 'Hours', headerName: 'Hours', width: 110},
        {field: 'companyID', headerName: 'ID', width: 80},
        {field: 'companyName', headerName: 'Name', width: 300},
    ];

    // const onSelectionChanged = ({ selectedRowsData }) => {
    //     //const data = selectedRowsData[0];
    //     console.log(JSON.stringify(selectedRowsData[0]));
    //     setSelectedAccount(selectedRowsData[0].account);     
    // }

    return (
        <div className='container_account-list'>
            <AppBar className='app_bar' position='static'>

                   <Box className={'app_bar_items'}>               
                        <TextField
                            variant = "outlined"
                            label = "Account"
                            margin = 'dense'
                            style={{marginRight:'10px'}}
                            onChange={(searchedVal) => requestSearch(searchedVal.target.value, 'account')}/>                      
                        <TextField
                            variant = "outlined"
                            label = "Name"
                            margin = 'dense'
                            style={{marginRight:'10px'}}
                            onChange={(searchedVal) => requestSearch(searchedVal.target.value, 'companyName')}/>
                        <TextField
                            variant = "outlined"
                            label = "Phone"
                            margin = 'dense'
                            style={{marginRight:'10px'}}
                            onChange={(searchedVal) => requestSearch(searchedVal.target.value, 'phone')}/>                         
                        <Button
                            color = "primary"
                            variant = "contained"  
                            className='inx_btn_red'  
                            style={{marginLeft:'auto', marginTop:'3px'}}                        
                            disabled = {selectedAccount=== null}
                            endIcon={<HowToRegIcon style={{verticalAlign: 'middle', transform: 'scale(1.25)'}}/>}
                            onClick = {() => switchAccount(selectedAccount)}
                        >
                            <span className='hide-mobile'>Use Selected Account</span>
                        </Button>
                    </Box>

            </AppBar>
            <div className='darkDataGrid datagrid_account-list'>
                <DataGrid
                rows = {rows}
                columns = {columns}
                isLoading={rows.length === 0}  
                disableMultipleSelection = {true}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = listWithID.filter((row) =>
                        selectedIDs.has(row.id)
                    );
                    setSelectedAccount(selectedRowData[0].account);
                    //switchAccount(selectedRowData[0].account);
                }}
                onRowDoubleClick={() => switchAccount(selectedAccount)}
                onCellKeyDown={(params, event) => {
                    if(event.key == "Enter"){
                        switchAccount(selectedAccount)
                    }
                }}
                />
                
                 {/* <DataGrid
                    dataSource={rows}
                    showBorders={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    keyExpr="id"
                    rowAlternationEnabled={true}   
                    onSelectionChanged={onSelectionChanged}
                >       
                    <Selection mode="single" />             
                     <Paging 
                       defaultPageSize={26}       
                     />
                    <SearchPanel visible={true}
                      width={240}
                      placeholder="Search..." />  
                    <Pager
                       visible={true}
                       showInfo={true}
                       infoText="Page #{0}. Total: {1} ({2} items)"  
                       displayMode="full"
                     />
                    
                    <Column dataField="account" caption="Account"  />
                    <Column dataField="Brand" caption="Brand" />
                    <Column dataField="Shipper" caption="Shipper"  width={300}/>  
                    <Column dataField="address" caption="Address"  width={300}/>  
                    <Column dataField="companyID" caption="ID"  />
                    <Column dataField="phone" caption="Phone" />  
                    <Column dataField="Hours" caption="Hours" />  
                </DataGrid> */}
            </div>
            <Container>  
                <footer>
                    <Copyright/>
                </footer>
            </Container>
        </div>
    );
}
import React from "react";
import TextInput from "../../components/inputFields/TextInput";

export default function InfoField(props) {
  return (
    <TextInput
      inputType="text"
      hideLabel={true}
      disabled
      variant="filled"
      style={{
        backgroundColor: "transparent",
        borderRadius: "4px",
        color: "#fff",
        border: "1px solid #007E82",
      }}
      {...props}
    />
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
//import endIcon from './images/end48.png';
//import startIcon from './images/start48.png';
import endIcon from "./images/pin_purple_end_sm.png";
import startIcon from "./images/pin_start_sm.png";
// import driverIcon from './images/vand49.png';
//import signIcon from './images/SigPin.png';
//import cameraIcon from './images/CameraPin_M.png';
import signIcon from "./images/pin_signature_lean-left_sm.png";
import cameraIcon from "./images/pin_photo_lean-right_sm.png";

const StreetViewMap = (props) => {
  //Init google maps
  const [googleAPILoaded, setGoogleAPILoaded] = useState(false);

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const [coord, setCoord] = React.useState({
    lat: 51.052224,
    lng: -114.068237,
  });
  const [gMap, setGMap] = React.useState(null);
  const [sMap, setSMap] = React.useState(null);
  const [latestDropmanLoc, setLatestDropmanLoc] = React.useState(null);
  const [panoramaHandler, setPanoramaHandler] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);

  useEffect(() => {
    const loadGoogleMapScript = (callback) => {
      if (
        typeof window.google === "object" &&
        typeof window.google.maps === "object"
      ) {
        console.log("window google or window google maps");
        callback();
      } else {
        const googleMapScript = document.createElement("script");
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        window.document.body.appendChild(googleMapScript);
        console.log("creating event listening google map");
        googleMapScript.addEventListener("load", callback);
      }
    };

    loadGoogleMapScript(() => {
      console.log("google api script loaded");
      setGoogleAPILoaded(true);
    });
  }, []);

  useEffect(() => {
    if (googleAPILoaded) {
      initMap();
    }
  }, [googleAPILoaded]);

  useEffect(() => {
    setCoord({ lat: props.lat, lng: props.lng });
    //addMarker(null,"");
  }, [props.address, props.lat, props.lng]);

  const google = (window.google = window.google ? window.google : {});

  const initMap = () => {
    //console.log("============init map=============" + props.type);
    var mapOptions = {
      zoom: 14,
      styles: [
        {
          featureType: "poi",
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          stylers: [{ visibility: "off" }],
        },
      ],
    };
    let google_map = new google.maps.Map(
      document.getElementById("google_map"),
      mapOptions
    );
    google_map.setCenter(new google.maps.LatLng(props.lat, props.lng));

    let panorama = new google.maps.StreetViewPanorama(
      document.getElementById("map_panorama"),
      {
        pov: {
          heading: 34,
          pitch: 10,
        },
      }
    );
    panorama.setPosition(new google.maps.LatLng(props.lat, props.lng));

    if (panoramaHandler) panoramaHandler.remove();

    let panoHandler = panorama.addListener("position_changed", () => {
      //console.log("panorama.getPosition()="+panorama.getPosition());  //.lat() /lng()
      setLatestDropmanLoc(panorama.getPosition());
      props.handleGPSChange(
        panorama.getPosition().lat(),
        panorama.getPosition().lng(),
        "dropman"
      );
    });
    setPanoramaHandler(panoHandler);
    setSMap(panorama);

    google_map.setStreetView(panorama);

    //let markerList =[];
    

    if (props.orderStatus > 8 && props.delLat && props.delLong) {
      var marker = new google.maps.Marker({
        position: { lat: props.delLat, lng: props.delLong },
        map: google_map,
        icon: signIcon,
      });
      var signTimeStr = props.dtDelivered
        ? props.dtDelivered.substring(0, 19).replace("T", " ")
        : "";
      var displayMessage =
        "<div><strong>Signature Lat:</strong> <span style='color:#B9F1F2;'>" +
        props.delLat +
        "</span></div><div><strong>Lon:</strong> <span style='color:#B9F1F2;'>" +
        props.delLong +
        "</span></div><div><strong>DateTime:</strong>  <span style='color:#B9F1F2;'>" +
        signTimeStr +
        "</span></div>";
      var infowindow = new google.maps.InfoWindow({
        content: displayMessage,
      });
      marker.addListener("click", () => {
        console.log(
          "sinature marker click-" + props.delLong + "----" + props.delLong
        );
        infowindow.open(google_map, marker);
        props.handleGPSChange(props.delLat, props.delLong, "signature");
      });
    }

    props.images.forEach((img) => {
      if (img.imgType === 2 && img.Long && img.Lat) {
        var marker = new google.maps.Marker({
          position: { lat: parseFloat(img.Lat), lng: parseFloat(img.Long) },
          map: google_map,
          icon: cameraIcon,
        });
        // var photoTime = new Date(moment(img.savedTimeUTC).toDate() - new Date().getTimezoneOffset() * 60000); //UTC -- Locatime display
        // var photoTimeStr = photoTime.getFullYear() +"-" + (photoTime.getMonth() + 1) + "-" + photoTime.getDate() + " " + photoTime.getHours() + ":" + photoTime.getMinutes();
        var photoTimeStr = moment(img.savedTimeUTC).format("YY-MM-DD HH:mm");
        var displayItems = "Photo ";
        if(props.orderStatus > 8 && img.Lat === props.delLat.toString() && img.Long=== props.delLong.toString())
           displayItems = "Signature and Photo ";
        var displayMessage =
          "<div><strong>" + displayItems + " Lat:</strong> <span style='color:#B9F1F2;'>" +
          img.Lat +
          "</span></div><div><strong>Lon:</strong> <span style='color:#B9F1F2;'>" +
          img.Long +
          "</span></div><div><strong>DateTime:</strong>  <span style='color:#B9F1F2;'>" +
          photoTimeStr +
          "</span></div>" +
          img.comment;
        var infowindow = new google.maps.InfoWindow({
          content: displayMessage,
        });
        marker.addListener("click", () => {
          console.log("camera marker click-" + img.Lat + "----" + img.Long);
          infowindow.open(google_map, marker);
          props.handleGPSChange(
            parseFloat(img.Lat),
            parseFloat(img.Long),
            "photo"
          );
        });
      }
    });

    var markerS = new google.maps.Marker({
      position: { lat: props.sLat, lng: props.sLong },
      map: google_map,
      icon: startIcon,
    });
    //markerList.push(markerS);

    var markerC = new google.maps.Marker({
      position: { lat: props.cLat, lng: props.cLong },
      map: google_map,
      icon: endIcon,
    });
    //markerList.push(markerC);

    setGMap(google_map);
  };

  return (
    <Grid item container direction="row" justifyContent="center">
      <Grid item md={6}>
        <div id="google_map" style={containerStyle}></div>
      </Grid>
      <Grid item md={6}>
        <div id="map_panorama" style={containerStyle}></div>
      </Grid>
    </Grid>
  );
};

export default StreetViewMap;

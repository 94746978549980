import React from "react";

import Checkbox from "@mui/material/Checkbox";
//import httpRequest from '../../HttpService';
//import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";

const DriverList = (props) => {
  const renderAvaliable = (params) => {
    return <Checkbox disabled checked={params.row.avail} />;
  };

  const renderDuty = (params) => {
    return <Checkbox disabled checked={params.row.duty} />;
  };

  const renderDrNum = (params) => {
    return <a className="priorityMedium">{params.value} </a>;
  };

  const columns = [
    {
      field: "drNum",
      headerName: "Driver#",
      width: 120,
      renderCell: renderDrNum,
    },
    // { field: 'drNum', headerName: 'Test', width: 150, editable:true, renderCell: renderTest},
    { field: "CapScore", headerName: "CapScore", width: 150 },
    { field: "GeoMinutes", headerName: "GeoMins", width: 150 },
    { field: "drZone", headerName: "CurrentZone", width: 160 },
    { field: "tripCount", headerName: "TripCount", width: 150 },
    {
      field: "avail",
      headerName: "Available",
      width: 150,
      renderCell: renderAvaliable,
    },
    { field: "RushRefused", headerName: "RushRefused", width: 150 },
    { field: "RegRefused", headerName: "RegRefused", width: 150 },
    { field: "burns", headerName: "Burns", width: 120 },
    { field: "rushBurns", headerName: "RushBurns", width: 150 },
    { field: "badlyBurnt", headerName: "BadlyBurnt", width: 150 },
    { field: "duty", headerName: "Duty", width: 110, renderCell: renderDuty },
    { field: "timeScore", headerName: "TimeScore", width: 150 },
    { field: "ValueList", headerName: "ValueList", width: 150 },
    { field: "trips", headerName: "Trips", width: 110 },
    { field: "firstName", headerName: "Name", width: 150 },
    { field: "contactPhone", headerName: "Phone#", width: 150 },
    { field: "appVersion", headerName: "App Version", width: 150 },
    { field: "contactEmail", headerName: "Email", width: 150 },
    { field: "URL", headerName: "URL", width: 150 },
  ];

  const listWithID = [];
  var currentID = 0;
  props.driverList.map((driver) => {
    listWithID.push({
      ...driver,
      id: currentID,
    });
    currentID = currentID + 1;
  });

  const handleOnCellClick = (params) => {
    console.log(params.field);
    if (params.field === "drNum") {
      props.updateDriver(params.value);
    }
  };

  /*
    useEffect(() => {    
        const driverList=[];

        httpRequest.getDriverStats("3A908632-21A0-437C-99DA-735AC86500D0", 1).then(async function(body){
            console.log(body.data);
            await body.data.forEach((value) => {               
                driverList.push(value);
            });
            setDrivers(driverList);
        });
    },[])
*/
  return (
    <div className="darkDataGrid" style={{ height: 400, width: "100%" }}>
      <DataGridPro
        rows={listWithID}
        columns={columns}
        checkboxSelection={false}
        disableColumnSelector={true}
        disableColumnMenu={true}
        onCellClick={handleOnCellClick}
      />
    </div>
  );
};

export default DriverList;

import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@material-ui/core/Typography';



function TripPin({onClick, order,onMouseOver,className,variant}) {
    const [anchorEl, setAnchorEl] = React.useState(null);    

    //console.log("tripPIN-" + json);
    function processJson(){        
        return (
            variant === 'Cons'?
            <div>
              <b>Cons:</b> {order.cSuite} {order.cAddress} <br></br>   
              <b>jobID:</b>{order.jobID}  <b>Svc:</b>{order.SER_AREA}-{order.SER_TIME} <b>Pcs/Wgt:</b>{order.PIECES}ps/{order.WEIGHT}lbs
              <br></br>
              <b>Call in:</b>{order.dtCallIn_UTC}   <b>DCT:</b>{order.dtDCT_UTC}   
              {(order.DEL_INST !== undefined  && order.DEL_INST !== "" && order.DEL_INST !== null) && <br></br>}           
              {(order.DEL_INST !== undefined  && order.DEL_INST !== "" && order.DEL_INST !== null) && <b>Instructions:</b>} 
              {(order.DEL_INST !== undefined  && order.DEL_INST !== "" && order.DEL_INST !== null) && <span>{order.DEL_INST}</span>} 
            </div>
            :
            <div>
              <b>Shipper:</b> {order.sSuite} {order.sAddress}<br></br>
              <b>jobID:</b>{order.jobID}  <b>Svc:</b>{order.SER_AREA}-{order.SER_TIME} <b>Pcs/Wgt:</b>{order.PIECES}ps/{order.WEIGHT}lbs
              <br></br>
              <b>Call in:</b>{order.dtCallIn_UTC}   <b>DCT:</b>{order.dtDCT_UTC}   
              {(order.DEL_INST !== undefined  && order.DEL_INST !== "" && order.DEL_INST !== null) && <br></br>}           
              {(order.DEL_INST !== undefined  && order.DEL_INST !== "" && order.DEL_INST !== null) && <b>Instructions:</b>} 
              {(order.DEL_INST !== undefined  && order.DEL_INST !== "" && order.DEL_INST !== null) && <span>{order.DEL_INST}</span>} 
            </div>
        );
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        onMouseOver(order);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };   

    const open = Boolean(anchorEl);

    return(       
        <div className={className} onMouseEnter={handlePopoverOpen} onMouseOut={handlePopoverClose} onClick={onClick}>
            <span> <Typography style={{ fontWeight: 500, fontSize: 10 }}>{order.jobID}</Typography></span>
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          style={{position: "absolute"}}
        >
              <div 
                style={{ 
                  width: '600px', 
                  height:'90px',
                  background: '#E8FDC5'
                }}
              >
                 {processJson(order)}
              </div>
         
        </Popover>   
        </div>       
    
   );
}

export default React.memo(TripPin);